import { ElementType, ReactElement, forwardRef, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { createSplitSpecificChildren } from '../../helpers';
import { contextFactory, usePreviousValue } from '../../hooks';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../type-utils';
import { useStyles } from '../../use-styles';
import { ContextMenuActions } from '../context-menu';
import { Actions } from './actions';
import { Bubble, Tag } from './atoms';
import { slideInVariants } from './chat-theme';
import { GRID_AREAS } from './chat.styles';
import { MetaInfo } from './meta-info';
import { Direction, BubbleProps, PrimaryAction } from './types';

type ChatItemProps = {
  direction: Direction;
  avatar: ReactElement;
  timestamp: string | Date;
  senderName?: string;
  error?: string;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  isSending?: boolean;
  moreActions?: ContextMenuActions;
  moreActionsTrackingId?: string;
  primaryAction?: PrimaryAction;
  StatusBar?: ReactElement;
  errorTooltip?: string;
  retrySend?: () => void;
} & Omit<Partial<BubbleProps>, 'direction'>;

type PolymorphicChatItemProps<C extends ElementType = 'ul'> = PolymorphicComponentPropWithRef<C, ChatItemProps>;

type ChatBubbleContextType = {
  direction: Direction;
  isSending?: boolean;
  isHovering: boolean;
  hoverProps: {
    onMouseOver: () => void;
    onMouseOut: () => void;
  };
};

export const [ChatBubbleContext, useChatBubbleContext] = contextFactory<ChatBubbleContextType>(
  'useChatBubbleContext must be used inside a ChatBubbleContext Provider.'
);

const ChatWrapperComponent = <C extends ElementType = 'ul'>(
  {
    as,
    direction,
    avatar,
    timestamp,
    senderName,
    error,
    onMouseOver,
    onMouseOut,
    isSending,
    moreActions,
    moreActionsTrackingId,
    primaryAction,
    StatusBar,
    children,
    errorTooltip,
    retrySend,
    ...rest
  }: PolymorphicChatItemProps<C>,
  ref: PolymorphicRef<C>
) => {
  const Component = as || 'ul';

  const formatTimestamp = useTimestampFormatter();
  const timestampText = typeof timestamp === 'string' ? timestamp : timestamp && formatTimestamp(timestamp);
  const bubblesContainerRef = useRef<HTMLDivElement>(null);
  const [isHovering, setIsHovering] = useState(false);

  const containerStyles = useStyles('Chat', 'container', { direction });
  const contentStyles = useStyles('Chat', 'content', { direction });
  const metaStyles = useStyles('Chat', 'meta', { direction });

  const splitTagChildren = createSplitSpecificChildren(children, true);
  const [bubble] = splitTagChildren('Bubble');
  const [tags] = splitTagChildren('ChatTag');

  const handleMouseOver = () => {
    setIsHovering(true);
    onMouseOver?.();
  };
  const handleMouseOut = () => {
    setIsHovering(false);
    onMouseOut?.();
  };

  const [previousTagCount, currentTagCount] = usePreviousValue(tags.length);

  return (
    <ChatBubbleContext.Provider
      value={{
        direction,
        isSending,
        isHovering,
        hoverProps: {
          onMouseOver: handleMouseOver,
          onMouseOut: handleMouseOut,
        },
      }}
    >
      <Component
        ref={ref}
        css={containerStyles}
        aria-label={`Message from ${senderName}, sent ${timestampText}`}
        {...rest}
      >
        <div className='avatar-container' css={{ gridArea: GRID_AREAS.AVATAR }}>
          {avatar}
        </div>
        <div className='content-container' css={contentStyles}>
          {(primaryAction || moreActions?.length) && isHovering && (
            <Actions
              primaryAction={primaryAction}
              trackingId={moreActionsTrackingId}
              actions={moreActions}
              hasError={!!error}
              onRetry={retrySend}
            />
          )}
          <div
            ref={bubblesContainerRef}
            className='bubbles-container'
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            {bubble}
          </div>
        </div>
        <div css={{ gridArea: GRID_AREAS.EMPTY }}></div>
        <div css={metaStyles}>
          <AnimatePresence>
            {!!currentTagCount && (
              <motion.div
                className='tags-container'
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                css={{
                  maxWidth: `${bubblesContainerRef.current?.clientWidth}px`,
                }}
                initial={previousTagCount === 0 ? 'hidden' : 'visible'}
                animate='visible'
                exit='exit'
                variants={slideInVariants}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              >
                {tags}
              </motion.div>
            )}
          </AnimatePresence>
          <MetaInfo
            senderName={senderName}
            timestampText={timestampText}
            error={!isSending ? error : undefined}
            StatusBar={StatusBar}
            isHovering={isHovering}
            errorTooltip={errorTooltip}
          />
        </div>
      </Component>
    </ChatBubbleContext.Provider>
  );
};

export const ChatWrapper = forwardRef(ChatWrapperComponent);

ChatWrapper.displayName = 'ChatWrapper';
const ChatWrapperNamespace = Object.assign(ChatWrapper, {
  Bubble: Bubble,
  Tag: Tag,
});

export { ChatWrapperNamespace as Chat };
