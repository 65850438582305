import { useCallback } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OUTDENT_CONTENT_COMMAND } from 'lexical';
import { IndentDecreaseAction } from '../../molecules/indent-decrease-action';

export const Outdent = () => {
  const [editor] = useLexicalComposerContext();

  const onOutdentSelect = useCallback(() => {
    editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
  }, [editor]);

  return <IndentDecreaseAction onClick={onOutdentSelect} aria-label='Format Outdent' />;
};

Outdent.displayName = 'Outdent';
