import { memo, useLayoutEffect, useMemo, useRef, useState, KeyboardEvent, useEffect, type ComponentProps } from 'react';
import { css } from '@emotion/react';
import {
  FloatingPortal,
  Placement,
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useClick,
} from '@floating-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { KeyNames } from '../../../../constants';
import { genUID } from '../../../../helpers';
import { useThemeValues } from '../../../../hooks';
import { ClockIcon } from '../../../../icon';
import { NakedUl } from '../../../../naked';
import { useStyles } from '../../../../use-styles';
import { getLabelId, Input } from '../../atoms';
import { FieldLayout } from '../../layouts';
import { activeDropdownStyle } from '../../list-fields/select-list/old-styles';
import { DropdownListItem } from './dropdown-list-item';
import type { TimeFieldProps } from './types';
import { getTimeIntervals, inputBlurHandler } from './utils';

type Props = TimeFieldProps & {
  allowEscape?: boolean;
  initialPlacement?: Placement | '';
  loop?: boolean;
  openOnArrowKeyDown?: boolean;
  type?: string;
};
export const TimeFieldBase = ({
  allowEscape = true,
  displayValue,
  initialPlacement = '',
  interval,
  loop = true,
  maxTime,
  minTime,
  options,
  onBlur,
  onChange,
  openOnArrowKeyDown = true,
  readOnly = false,
  type,
  value,
  showAdornment = false,
  ...rest
}: Props) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [floatingElementOpen, setFloatingElementOpen] = useState(false);
  const [placement, setPlacement] = useState<Placement | ''>(initialPlacement);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const listItemsRef = useRef<Array<HTMLLIElement | null>>([]);
  const menuStyles = useStyles('DropdownField', 'dropdownMenu', { maxHeight: null });
  const labelId = getLabelId(rest.id);
  const idRef = useRef(genUID());
  const listboxId = idRef.current;
  const triggerId = `${listboxId}-trigger`;
  const theme = useThemeValues();

  // generate time intervals for flyout
  const intervals = useMemo(
    () =>
      options
        ? options
        : getTimeIntervals({
            interval,
            maxTime,
            minTime,
          }),
    [maxTime, interval, minTime, getTimeIntervals, options]
  );

  useEffect(() => {
    intervals.map((interval, index) => {
      if (interval.value === value) {
        setSelectedIndex(index);
        return;
      }
    });
  }, [value]);

  const { refs, context, floatingStyles } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open: floatingElementOpen,
    onOpenChange: setFloatingElementOpen,
    middleware: [
      offset(placement === 'top' ? 12 : 2),
      flip(),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight - 8}px`,
          });
        },
      }),
    ],
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'listbox' }),
    useDismiss(context),
    useListNavigation(context, {
      activeIndex,
      allowEscape,
      listRef: listItemsRef,
      loop,
      onNavigate: setActiveIndex,
      openOnArrowKeyDown,
      selectedIndex,
      virtual: true,
    }),
  ]);

  useLayoutEffect(() => {
    // IMPORTANT: When the floating element first opens, this runs when the
    // styles have **not yet** been applied to the element. This can cause an
    // infinite loop as `size` has not yet limited the maxHeight, so the whole
    // page tries to scroll. We must wrap it in rAF.
    requestAnimationFrame(() => {
      if (activeIndex != null) {
        listItemsRef.current[activeIndex]?.scrollIntoView({ block: 'nearest' });
      }
    });
  }, [activeIndex]);

  useEffect(() => {
    // need to hold placement in state to reference it in the `offset` property of `useFloating`
    setPlacement(context.placement);
  }, [context.placement]);

  return (
    <>
      <FieldLayout
        ref={refs.setReference}
        css={floatingElementOpen && activeDropdownStyle('bottom')}
        //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
        field={Input as ComponentProps<typeof FieldLayout>['field']}
        fieldComponentProps={{
          'aria-labelledby': `${labelId} ${triggerId}`,
          autoComplete: 'off',
          css: { padding: showAdornment ? theme.spacing(0, 0, 0, 2) : theme.spacing(0, 2), height: '100%' },
          id: triggerId,
          readOnly: readOnly,
        }}
        hasPadding={false}
        onChange={onChange}
        onBlur={inputBlurHandler(onBlur, onChange)}
        value={displayValue}
        endAdornment={
          showAdornment && (
            <span
              css={css`
                display: flex;
                align-items: center;
                padding-right: ${theme.spacing(2)};
              `}
            >
              <ClockIcon size={16} color='light' />
            </span>
          )
        }
        outerProps={getReferenceProps({
          value: displayValue,
          onKeyDown(e: KeyboardEvent<HTMLDivElement>) {
            if (e.key === KeyNames.Tab) {
              // close flyout if it is open and the user hits tab
              setFloatingElementOpen(false);
            }
            if (e.key === KeyNames.Enter) {
              e.preventDefault();
              setFloatingElementOpen((open) => !open);
            }
            if (e.key === KeyNames.Enter && activeIndex != null) {
              e.preventDefault();
              onChange({ name: rest.name, value: intervals[activeIndex] });
              setSelectedIndex(activeIndex);
              setFloatingElementOpen(false);
            }
          },
        })}
        {...rest}
      />
      <AnimatePresence>
        {floatingElementOpen && (
          <FloatingPortal>
            <motion.div
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  ...floatingStyles,
                  minHeight: 40,
                  overflowY: 'auto',
                  zIndex: 100001, // must render above modals
                },
              })}
              css={menuStyles}
            >
              <NakedUl css={{ margin: 0 }} aria-labelledby={labelId}>
                {intervals.map(({ displayValue, value }, index) => (
                  <DropdownListItem
                    active={activeIndex === index}
                    data-value={intervals[index]?.value}
                    disabled={false}
                    displayValue={displayValue}
                    getItemProps={getItemProps}
                    id={rest.id}
                    index={index}
                    intervals={intervals}
                    key={value}
                    listItemsRef={listItemsRef}
                    name={rest.name}
                    onChange={onChange}
                    selected={selectedIndex === index}
                    setFloatingElementOpen={setFloatingElementOpen}
                    setSelectedIndex={setSelectedIndex}
                    value={intervals[index]!}
                  />
                ))}
              </NakedUl>
            </motion.div>
          </FloatingPortal>
        )}
      </AnimatePresence>
    </>
  );
};

TimeFieldBase.displayName = 'TimeField';
export const TimeField = memo(TimeFieldBase);
