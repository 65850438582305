import { forwardRef, useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $createTextNode,
  DRAGSTART_COMMAND,
  COMMAND_PRIORITY_HIGH,
  DROP_COMMAND,
  $createRangeSelection,
  $setSelection,
  FORMAT_TEXT_COMMAND,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
} from 'lexical';
import {
  BeautifulMentionsPlugin,
  BeautifulMentionsMenuProps,
  BeautifulMentionsMenuItemProps,
  ZeroWidthPlugin,
  useBeautifulMentions,
  BeautifulMentionsItemData,
} from 'lexical-beautiful-mentions';
import { theme } from '@frontend/theme';
import { DYNAMIC_FIELD_TRIGGER } from '../constants';
import { DynamicFieldAttributes } from '../molecules/dynamic-field-action';
import { $isDynamicFieldNode, DynamicFieldNode } from '../nodes';
import { $patchDynamicFieldLabelStyle } from '../utils';

const ZERO_WIDTH_CHARACTER = '​';
const MAX_MENU_ITEMS = 8;

export interface InsertMention {
  trigger: string;
  value: string;
  focus?: boolean;
  data?: {
    [key: string]: BeautifulMentionsItemData;
  };
}

function $onDrop(event: DragEvent, insertMention: (options: InsertMention) => void): boolean {
  const node = $getDynamicFieldNode();
  if (!node) {
    return false;
  }
  const dynamicLabelData = getDragDynamicFieldData(event);
  if (!dynamicLabelData) {
    return false;
  }

  event.preventDefault();
  const range = getDragSelection(event);
  node.remove();
  const rangeSelection = $createRangeSelection();
  if (range !== null && range !== undefined) {
    rangeSelection.applyDOMRange(range);
  }
  $setSelection(rangeSelection);
  insertMention({ trigger: DYNAMIC_FIELD_TRIGGER, value: dynamicLabelData.value, data: dynamicLabelData.data });
  return true;
}

const DOCUMENT_NODE = 9;

function getDragSelection(event: DragEvent): Range | null {
  const target = event.target as Element | Document | null;
  const targetWindow =
    target == null
      ? null
      : target.nodeType === DOCUMENT_NODE
      ? (target as Document).defaultView
      : (target as Element).ownerDocument.defaultView;

  if (!targetWindow) {
    throw Error('Cannot get the target window');
  }

  const domSelection = targetWindow.getSelection();

  if (!domSelection) {
    throw Error('Cannot get the DOM selection');
  }

  const range = document.caretRangeFromPoint(event.clientX, event.clientY);

  if (range) {
    domSelection.removeAllRanges();
    domSelection.addRange(range);
  } else {
    throw Error('Cannot get the selection when dragging');
  }

  return range;
}

function getDragDynamicFieldData(event: DragEvent): any {
  const dragData = event?.dataTransfer?.getData('application/x-lexical-drag');
  if (!dragData) {
    return null;
  }
  try {
    const { type, data } = JSON.parse(dragData);
    if (type !== 'dynamic-field') {
      return null;
    }
    return data;
  } catch (e) {
    return null;
  }
}

function $onDragStart(event: DragEvent): boolean {
  const node = $getDynamicFieldNode();
  if (!node) {
    return false;
  }
  const dataTransfer = event.dataTransfer;
  if (!dataTransfer) {
    return false;
  }

  dataTransfer.setData('text/plain', '_');
  dataTransfer.setData(
    'application/x-lexical-drag',
    JSON.stringify({
      data: {
        trigger: node.__trigger,
        value: node.__value,
        data: { ...node.__data, styles: node.__styles },
        key: node.__key,
      },
      type: 'dynamic-field',
    })
  );

  return true;
}

const $getDynamicFieldNode = (): DynamicFieldNode | null => {
  const selection = $getSelection();
  if (!selection) {
    return null;
  }
  const nodes = selection.getNodes();
  const node = nodes[0];
  return $isDynamicFieldNode(node) ? node : null;
};

const CustomMenu = ({ loading, children, ...props }: BeautifulMentionsMenuProps) => {
  return (
    <ul
      className='custom-menu-style'
      css={css`
        background: ${theme.colors.white};
        border-radius: ${theme.borderRadius.small};
        box-shadow: ${theme.shadows.floating};
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        min-width: 100px;
        width: 150px;
        max-height: 300px;
        overflow: auto;
        padding: ${theme.spacing(1, 0)};
        z-index: ${theme.zIndex.popover};

        :focus {
          outline: none;
        }
      `}
      {...props}
    >
      {children}
    </ul>
  );
};

const CustomMenuItem = forwardRef<HTMLLIElement, BeautifulMentionsMenuItemProps>(
  ({ selected, item, itemValue: __, noTransform: __ignore, ...props }, ref) => {
    return (
      <li
        css={[
          css`
            align-items: center;
            background: none;
            border: none;
            cursor: default;
            display: flex;
            height: 40px;
            justify-content: flex-start;
            margin: 0;
            outline: none;
            padding: ${theme.spacing(0, 2)};
            min-height: 30px;
            gap: ${theme.spacing(1)};
            position: relative;
            text-decoration: none;
            transition: background-color 250ms ease-out;

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex: 1;
              display: inline;
              text-align: start;
            }
            align-items: center;
            > :first-letter {
              text-transform: uppercase;
            }

            :hover {
              background-color: ${theme.colors.neutral10};
            }
            :focus {
              background-color: ${theme.colors.neutral10};
              outline: none;
            }
          `,
          selected &&
            css`
              background: ${theme.colors.neutral10};
              outline: none;
              ::before {
                content: '';
                background: ${theme.colors.primary50};
                height: 100%;
                width: 3px;
                left: 0;
                position: absolute;
              }
            `,
        ]}
        {...props}
        ref={ref}
      >
        <span>{item?.data?.label || ''}</span>
      </li>
    );
  }
);

type Props = {
  fields: DynamicFieldAttributes[];
  noTransform?: boolean;
  styles?: React.CSSProperties;
};

export const DynamicFieldPlugin = ({ fields, noTransform, styles }: Props) => {
  const mentionItems = {
    [DYNAMIC_FIELD_TRIGGER]: fields.map((field) => ({
      value: field.symbol,
      label: field.label,
      val: field.val ?? null,
      noTransform: !!noTransform,
      styles: styles as unknown as BeautifulMentionsItemData,
    })),
  };

  const { insertMention } = useBeautifulMentions();
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand<DragEvent>(
        DRAGSTART_COMMAND,
        (event) => {
          return $onDragStart(event);
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand<DragEvent>(
        DROP_COMMAND,
        (event) => {
          return $onDrop(event, insertMention);
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        FORMAT_TEXT_COMMAND,
        (formatType: string | React.CSSProperties) => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $patchDynamicFieldLabelStyle(selection, formatType);
          }

          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, insertMention]);

  const insertEmptySpace = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();

      if (selection !== null) {
        const space = $createTextNode(' ');
        selection.insertNodes([space]);
      }
    });
  }, [editor]);

  return (
    <>
      <ZeroWidthPlugin textContent={ZERO_WIDTH_CHARACTER} />
      <BeautifulMentionsPlugin
        onMenuItemSelect={insertEmptySpace}
        allowSpaces
        items={mentionItems}
        menuComponent={CustomMenu}
        menuItemLimit={MAX_MENU_ITEMS}
        menuItemComponent={CustomMenuItem}
      />
    </>
  );
};
