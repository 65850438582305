import { useState, KeyboardEvent } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

interface MFAInputProps {
  setCode: (value: string) => void;
  setDisableAction: (value: boolean) => void;
  onSubmit: () => void;
  input: string;
  onInputChange: (value: string) => void;
}

export const MFAInput = ({ setCode, setDisableAction, onSubmit, input, onInputChange }: MFAInputProps) => {
  const [isFocused, setIsFocused] = useState(false); // to change border color when input is focused

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Validate input length and format
    if (value.length <= 6 && /^\d*$/.test(value)) {
      onInputChange(value);

      if (value.length === 6) {
        setCode(value);
        setDisableAction(false);
      } else {
        setDisableAction(true);
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <div
      css={css`
        position: relative;
        max-width: 350px;
        min-width: 280px;
      `}
    >
      <input
        type='text'
        value={input}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        autoFocus
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          caret-color: transparent;
          z-index: 1;
        `}
      />
      <div
        css={css`
          font-size: ${theme.font.size.h2};
          font-weight: ${theme.font.weight.bold};
          display: flex;
          justify-content: space-between;
          border-radius: ${theme.borderRadius.medium};
          border: 1px solid ${isFocused ? theme.colors.primary50 : theme.colors.neutral20};
          padding: ${theme.spacing(3)};
          &:focus-within {
            border-color: ${theme.colors.primary}; /* Blue border when input is focused */
          }
        `}
      >
        {Array.from({ length: 6 }).map((_, i) => (
          <span
            css={css`
              width: 20px;
              text-align: center;
              color: ${theme.colors.neutral90};
              border-bottom: 2px solid ${i === input.length ? theme.colors.primary : 'transparent'};
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative; /* Relative positioning to manage the pseudo-element */

              /* Hide the dot when cursor is active */
              & > p {
                visibility: ${i === input.length && isFocused ? 'hidden' : 'visible'};
              }

              &:before {
                content: '|';
                position: absolute;
                left: calc(50% - 0.1em); /* Center the cursor */
                visibility: ${i === input.length && isFocused ? 'visible' : 'hidden'};
                animation: ${i === input.length && isFocused ? 'blink 1s step-end infinite' : 'none'};
                font-size: 2rem;
              }

              @keyframes blink {
                0% {
                  visibility: visible; /* Visible at start */
                }
                50% {
                  visibility: hidden; /* Disappear mid-way */
                }
                100% {
                  visibility: visible; /* Visible again at the end */
                }
              }
            `}
            key={i}
          >
            <p>{input[i] || '●'}</p>
          </span>
        ))}
      </div>
    </div>
  );
};
