import { create } from 'zustand';
import { breakpoints, NavSizes } from './breakpoints';
import { useMatchMedia } from './use-match-media';
import { useWindowDimensions } from './use-window-dimensions';

/**
 * Used to find out which media range the window matches.
 * Be careful with this return value, as each match fn is a hook,
 * so don't ever conditionally invoke any of these, or you'll get a mismatched hooks error.
 */
export const useMediaMatches = create(() => ({
  matches: {
    xsmallMax: () => useMatchMedia({ maxWidth: breakpoints.xsmall.max }),
    smallMin: () => useMatchMedia({ minWidth: breakpoints.small.min }),
    smallMax: () => useMatchMedia({ minWidth: breakpoints.small.max }),
    smallMinMax: () =>
      useMatchMedia({
        minWidth: breakpoints.small.min,
        maxWidth: breakpoints.small.max,
      }),
    mediumMin: () => useMatchMedia({ minWidth: breakpoints.medium.min }),
    mediumMax: () => useMatchMedia({ minWidth: breakpoints.medium.max }),
    mediumMinMax: () =>
      useMatchMedia({
        minWidth: breakpoints.medium.min,
        maxWidth: breakpoints.medium.max,
      }),
    mobileMin: () => useMatchMedia({ minWidth: breakpoints.mobile.min }),
    mobileMax: () => useMatchMedia({ maxWidth: breakpoints.mobile.max }),
    largeMin: () => useMatchMedia({ minWidth: breakpoints.large.min }),
    largeMax: () => useMatchMedia({ maxWidth: breakpoints.large.max }),
    largeMinMax: () =>
      useMatchMedia({
        minWidth: breakpoints.large.min,
        maxWidth: breakpoints.large.max,
      }),
  },
}));

export const useBreakpoint = (): NavSizes => {
  const { width } = useWindowDimensions();

  const isXsmall = width <= breakpoints.xsmall.max;
  if (isXsmall) {
    return 'xsmall';
  }

  const isSmall = width <= breakpoints.small.max;
  if (isSmall) {
    return 'small';
  }

  const isMedium = width <= breakpoints.medium.max;
  if (isMedium) {
    return 'medium';
  }

  const isMobile = width <= breakpoints.mobile.max;
  if (isMobile) {
    return 'mobile';
  }

  const isLarge = width <= breakpoints.large.max;
  if (isLarge) {
    return 'large';
  }
  return 'xlarge';
};
