import type { ComponentProps } from 'react';
import { css } from '@emotion/react';
import { SpinningLoader, Text } from '@frontend/design-system';

type Props = {
  show?: boolean;
  message?: string;
  size?: ComponentProps<typeof SpinningLoader>['size'];
};
export const ContentLoader = ({ show, message, size }: Props) => {
  if (!show) {
    return null;
  }
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10000000; //??
      `}
    >
      <SpinningLoader key='loader' size={size} />
      {!!message && (
        <Text key='message' textAlign='center'>
          {message}
        </Text>
      )}
    </div>
  );
};
