import { useCallback } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $patchStyleText } from '@lexical/selection';
import { $isRangeSelection, $getSelection, $isTextNode } from 'lexical';
import { HighlightColorAction } from '../../molecules/highlight-color-action';
import { useToolbarState } from '../../providers';
import { $patchDynamicFieldLabelStyle } from '../../utils/node-helpers';

export const Highlight = ({ palette, shouldApply }: { palette?: string[]; shouldApply?: boolean }) => {
  const [editor] = useLexicalComposerContext();
  const { highlightColor } = useToolbarState(['highlightColor']);

  const applyStyleText = useCallback(
    (styles: Record<string, string>) => {
      editor.update(() => {
        const selection = $getSelection();
        if (selection && $isRangeSelection(selection)) {
          $patchStyleText(selection, styles);
        }
        $patchDynamicFieldLabelStyle(selection, styles);
      });
    },
    [editor]
  );

  const removeHighlight = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      if (selection && $isRangeSelection(selection)) {
        const nodes = selection.getNodes();

        nodes.forEach((node) => {
          if ($isTextNode(node)) {
            const styles = node.getStyle();
            if (styles && styles.includes('background-color')) {
              const newStyles = styles
                .split(';')
                .filter((style) => !style.includes('background-color'))
                .join(';');

              if (newStyles) {
                node.setStyle(newStyles);
              } else {
                node.setStyle('');
              }
            }
          }
        });

        selection.getNodes().forEach((node) => {
          if ($isTextNode(node) && node.getStyle() === '' && node.getTextContent() === '') {
            node.remove();
          }
        });
      }
    });
  }, [editor]);

  const onBgColorSelect = useCallback(
    (value: string) => {
      applyStyleText({ 'background-color': value });
    },
    [applyStyleText]
  );

  const onReset = useCallback(() => {
    removeHighlight();
  }, [removeHighlight]);

  return (
    <HighlightColorAction
      color={highlightColor}
      onChange={onBgColorSelect}
      shouldApply={shouldApply}
      palette={palette}
      onReset={onReset}
    />
  );
};

Highlight.displayName = 'Highlight';
