import type { WeaveTheme } from '@frontend/theme';
import { mergeClasses } from '../../../helpers';
import { useThemeValues } from '../../../hooks/use-theme-values';
import { Button } from '../../button';
import { Heading } from '../../heading';
import { Text } from '../../text';
import { useModalContext } from '../provider';

type Props = Omit<React.HTMLAttributes<HTMLHeadingElement>, 'color'> & {
  onClose?: () => void;
  closeBtnTrackingId?: string;
  children?: React.ReactNode;
  textAlign?: 'left' | 'center';
};

export const ModalHeader = ({
  children,
  className,
  textAlign = 'left',
  closeBtnTrackingId = 'modal-close-icon-button',
  onClose,
  ...rest
}: Props) => {
  const { titleId } = useModalContext();
  const { spacing } = useThemeValues();

  return (
    <header
      css={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        gridRowStart: 'header-start',
        gridRowEnd: 'header-end',
        padding: spacing(0, 3),
      }}
      className={mergeClasses('wv-modal-header', className)}
    >
      <Heading
        css={{
          margin: 0,
        }}
        level={2}
        textAlign={textAlign}
        {...rest}
        id={titleId}
      >
        {children}
      </Heading>
      {onClose && (
        <Button
          trackingId={closeBtnTrackingId}
          aria-label='close'
          onClick={onClose}
          css={{ alignSelf: 'baseline' }}
          iconName='x-small'
          variant='secondary'
        />
      )}
    </header>
  );
};

export const ModalLabel = ({
  children,
  className,
  ...rest
}: Omit<React.HTMLAttributes<HTMLHeadingElement>, 'color'> & {
  children: React.ReactNode;
}) => {
  const { spacing } = useThemeValues() as WeaveTheme;

  return (
    <div
      css={{
        margin: 0,
        padding: spacing(0, 3),
        gridRowStart: 'label-start',
        gridRowEnd: 'label-end',
      }}
      className={mergeClasses('wv-modal-label', className)}
      {...rest}
    >
      {typeof children === 'string' ? (
        <Text as='span' color='light' size='small'>
          {children}
        </Text>
      ) : (
        children
      )}
    </div>
  );
};
