import { useEffect, useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { PASTE_COMMAND, $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW } from 'lexical';
import { useModalControl, ConfirmationModal } from '../../modal';
import { INSERT_IMAGE_COMMAND } from './images-plugin';

const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

const checkIfImageLink = async (url) => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    const contentType = response.headers.get('Content-Type');
    return contentType && contentType.startsWith('image/');
  } catch (error) {
    console.error('Error checking image link:', error);
    return false;
  }
};

const pasteAsText = (editor, text) => {
  editor.update(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      selection.insertText(text);
    }
  });
};

export function ImagePastePlugin() {
  const [editor] = useLexicalComposerContext();
  const timeoutRef = useRef<null | NodeJS.Timeout>(null);
  const [pendingImage, setPendingImage] = useState(null);
  const { modalProps, openModal, closeModal } = useModalControl();

  const handleConfirm = () => {
    if (pendingImage) {
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
        src: pendingImage,
        altText: '',
      });
    }
    closeModal();
  };

  const handleCancel = () => {
    pasteAsText(editor, pendingImage);
    closeModal();
  };

  useEffect(() => {
    const handlePaste = (event) => {
      const clipboardData = event.clipboardData || event.dataTransfer;
      if (!clipboardData) return false;

      const text = clipboardData.getData('text');
      if (!isValidUrl(text)) return false;

      event.preventDefault();

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(async () => {
        const isImage = await checkIfImageLink(text);
        if (isImage) {
          setPendingImage(text);
          openModal();
          return;
        }

        pasteAsText(editor, text);
      }, 0);

      return true;
    };

    return editor.registerCommand(PASTE_COMMAND, handlePaste, COMMAND_PRIORITY_LOW);
  }, [editor, openModal]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <ConfirmationModal
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        confirmLabel='Insert as Image'
        cancelLabel='Insert as Text'
        {...modalProps}
        title='Insert Content'
        message='We detected an image link. Do you want to insert it as an image or as text?'
      />
    </>
  );
}
