import { $getSelectionStyleValueForProperty } from '@lexical/selection';
import { $isRangeSelection, BaseSelection } from 'lexical';
import { $isDynamicFieldNode } from '../nodes';

const formatToStyleMap = {
  bold: { 'font-weight': 'bold' },
  italic: { 'font-style': 'italic' },
  underline: { 'text-decoration': 'underline' },
  strikethrough: { 'text-decoration': 'line-through' },
  subscript: { 'vertical-align': 'sub' },
  superscript: { 'vertical-align': 'super' },
} as const satisfies { [key: string]: { [key: string]: string } };

type FormatToStyleMapList = (keyof typeof formatToStyleMap)[];

export function $patchDynamicFieldLabelStyle(
  selection: BaseSelection | null,
  formatOrStyles: string | React.CSSProperties
) {
  if (selection) {
    const styles: React.CSSProperties = {};

    if (typeof formatOrStyles === 'string') {
      const format = formatOrStyles as keyof typeof formatToStyleMap;
      const style = formatToStyleMap[format];
      if (style) {
        Object.assign(styles, style);
      }
    } else {
      Object.assign(styles, formatOrStyles);
    }

    const nodes = selection.getNodes();
    nodes.forEach((node) => {
      if ($isDynamicFieldNode(node)) {
        const dynamicNode = node;
        dynamicNode.$patchDynamicLabelStyle(styles);
      }
    });
  }
}

export const getStylesFromSelection = (selection: BaseSelection | null): { [key: string]: string } => {
  const styles: { [key: string]: string } = {};

  if ($isRangeSelection(selection)) {
    (Object.keys(formatToStyleMap) as FormatToStyleMapList).forEach((format) => {
      if (selection.hasFormat(format)) {
        Object.assign(styles, formatToStyleMap[format]);
      }
    });

    if (selection.hasFormat('highlight')) {
      const highlightColor = $getSelectionStyleValueForProperty(selection, 'background-color', '');
      styles['background-color'] = highlightColor;
    }

    const styleProperties = ['color', 'font-family', 'font-size'];
    styleProperties.forEach((property) => {
      const value = $getSelectionStyleValueForProperty(selection, property, '');
      if (value) {
        styles[property] = value;
      }
    });
  }

  return styles;
};
