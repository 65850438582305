import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { SerializedStyles } from '@emotion/react';
import composeRefs from '@seznam/compose-react-refs';
import { isEqual } from 'lodash-es';
import {
  Column,
  useBlockLayout,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useRowState,
  useTable,
  useExpanded,
  useColumnOrder,
  PluginHook,
  IdType,
  Row,
} from 'react-table';
import { F } from 'ts-toolbelt';
import { useMatchMedia, useMediaQuery, breakpoints } from '@frontend/responsiveness';
import { DeepReadonlyLoose } from '@frontend/types';
import { Pagination, PaginationProps, RowsPerPageOption } from '../';
import { usePreviousValue, useUniqueID } from '../../hooks';
import { useStyles } from '../../use-styles';
import { CollapsibleTableButton } from './atoms';
import { reservedCells } from './atoms/cells';
import { useEventListenersOnTableBody, useScrollShadow, useSticky, useExportData } from './hooks';
import { TableBody, TableHeaders } from './molecules';
import { TableData } from './table-data-type';
import {
  TableStyleConfig,
  TableRowActions,
  TableExpandableRender,
  RowSelectionProps,
  PaginationConfigProps,
  SortingValue,
  GlobalSearchConfigProps,
  CellPositionCord,
  CellPositionsConfig,
  DefaultColumnWidths,
  TableColumnConfig,
  TableActionsProps,
  EmptyStateConfig,
  ManualFilters,
  FullHeightProps,
  CollapsedTableConfig,
  FilterColumnsConfig,
  CustomRowConfig,
  MobileLayoutGlobalConfig,
  TableExportConfig,
  RowExpandingConfig,
  CustomToolbarRender,
} from './table-types';
import { TableToolbar } from './toolbar/table-toolbar.component';
import {
  defaultAutoResetOptions,
  filterTypeMap,
  formatColConfig,
  getGlobalFilterFn,
  sortTypeMap,
  getTotalColumnCount,
  getFilterColumnsId,
  getOrderColumnsId,
  getResizeColumnsId,
  findMaxLeftShift,
  tableTrackingIdGenerator,
  getExportFileBlob,
} from './utils';

export type TableProps<
  T extends TableData,
  C extends TableColumnConfig<T>[] | DeepReadonlyLoose<TableColumnConfig<T>[]> = TableColumnConfig<T>[]
> = {
  colConfig: C;
  instanceRef?: React.MutableRefObject<any>;
  data: T[];

  /**
   * ## Style Config
   * ***styleConfig*** is the configuration for customizing the style of table cells, headers, and rows.
   * It allows you to change the default styles of the table. The styleConfig object has two properties: `columns` and `rows`.
   * The `columns` property is an array of objects that each have an id, as well as two style properties called cellStyler
   * and headerStyler. The ***id*** property refers to the identifier of a specific column in the table,
   * which comes from the `id` field of an object in the colConfig array (Table's column configuration). This identifier is used to
   * identify a column and to apply a particular style to it.
   *
   * ***cellStyler*** and ***headerStyler*** can accept any style that is compatible with the css prop from Emotion,
   * or a function that returns a style. The function has access to the cell value (defined by the accessor in the column configuration),
   * and the column object,  which means that you can apply styles to cells and headers based on the cell value
   * or the data in the column object.
   *
   * The ***rows*** property accepts either a style or a function that returns a style.
   * The function can access the row data (the original data passed to the table), row index, and row object.
   *
   * @example
   * ```ts
   * const styleConfig = {
   *   columns: [
   *     {
   *       id: 'name',
   *       cellStyler: (cellValue, column) => {
   *         if (cellValue === 'John') {
   *           return css`
   *             color: ${theme.colors.warning};
   *           `;
   *         }
   *
   *         return css`
   *           color: blue;
   *         `;
   *       },
   *       headerStyler: css`
   *         color: green;
   *       `,
   *     }
   *   ],
   *   rows: (rowData, rowIndex) => {
   *     if (rowData.firstName === 'John') {
   *       return css`
   *         background-color: ${theme.colors.neutral5};
   *       `;
   *     }
   *
   *     return css`
   *       background-color: blue;
   *     `;
   *   },
   * };
   * ```
   */
  styleConfig?: TableStyleConfig<T, F.Narrow<C[number]['id']>>;
  hasResponsiveColWidths?: boolean;
  isPaginated?: boolean;
  isFocusable?: boolean;
  isLoading?: boolean;
  /**
   * When the `isLoading` is true, the data in the tableInstance is replaced with an array of empty objects.
   * `keepPreviousDataWhileLoading` allows you to keep the previous data in the tableInstance while the table is loading.
   */
  keepPreviousDataWhileLoading?: boolean;
  loadingRowsCount?: number;
  expandableRowComponent?: TableExpandableRender<T>;
  disableMultiSort?: boolean;
  onSortChange?: (sortValues: SortingValue<F.Narrow<C[number]['id']>>[]) => void;
  manualSortBy?: boolean;
  manualFilters?: boolean;
  manualFiltersOptions?: { initialBadgeState?: boolean };
  rowActions?: TableRowActions<T>;
  tableActions?: TableActionsProps<T>;
  tableStyle?: SerializedStyles;
  wrapperStyle?: SerializedStyles;
  hasGlobalSearch?: boolean;
  customToolbarRender?: CustomToolbarRender<T>;

  /**
   * Enables column filtering and ordering. Requires ***tableInstanceId*** to be set.
   */
  hasFilterColumns?: boolean;
  /**
   * Enables column resizing. Requires ***tableInstanceId*** to be set.
   */
  hasResizeColumns?: boolean;
  filterColumnsConfig?: FilterColumnsConfig;

  /**
   * A callback function that returns a unique ID for each row, which is typically derived from the row data.
   * This ID is used for tracking row selection and expanded state.
   * The function receives two arguments:
   *   - `rowData`: the data of the current row. This object may already contain a unique ID that this function can return.
   *   - `relativeIndex`: the index of the row relative to the table.
   * This function should return a string that is guaranteed to be unique across all rows.
   */
  uniqueRowId?: (rowData: T, relativeIndex: number) => string;

  /**
   * ## Table Instance Identifier
   * ***tableInstanceId*** property acts as a distinct identifier for a table, utilized to save user preferences in local storage.
   * This identifier must be unique.
   * To illustrate, a unique identifier can be the page name where the table is situated,
   * and if there are multiple tables on the same page, the identifier should be distinct for each table.
   * For instance, the identifier can be named `pageName-1` or `pageName-2` for each table, respectively.
   */
  tableInstanceId?: string;
  manualFiltersRender?: ManualFilters<T>;
  defaultColumnWidths?: DefaultColumnWidths;
  emptyStateConfig?: EmptyStateConfig<T>;
  customRowConfig?: CustomRowConfig<T>;
  collapsedTableConfig?: CollapsedTableConfig<T>;
  mobileLayoutGlobalConfig?: MobileLayoutGlobalConfig;
  exportConfig?: TableExportConfig;
  rowExpandingConfig?: RowExpandingConfig<T>;
  isRowDisabled?: boolean | ((rowData: Row<T>['original'], rowObject: Row<T>) => boolean);

  /**
   * ## Global Tracking Id
   * The ***globalTrackingId*** property functions as a unique identifier for a table, specifically for use in `pendo` tagging.
   * This identifier is combined with other identifiers throughout the table, ensuring each Table component instance
   * maintains unique identifiers for tagging purposes.
   * Despite its unique role, the ***globalTrackingId*** must comply with tracking id naming convention that we use here at Weave,
   * with the exception that this identifier only needs the app's name and feature (e.g. `'portal-calls'`).
   */
  globalTrackingId?: string;
} & RowSelectionProps<T> &
  PaginationConfigProps &
  GlobalSearchConfigProps &
  FullHeightProps;

const allHooks = [
  useGlobalFilter,
  useFilters,
  useSortBy,
  useExpanded,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useColumnOrder,
  useExportData,
  useRowState,
];

export const defaultRowsPerPageOptions: RowsPerPageOption = [10, 20, 30, 40];

export const Table = <
  T extends TableData,
  C extends TableColumnConfig<T>[] | DeepReadonlyLoose<TableColumnConfig<T>[]> = TableColumnConfig<T>[]
>({
  colConfig,
  instanceRef,
  data,
  wrapperStyle,
  tableStyle,
  hasResponsiveColWidths = true,
  styleConfig,
  defaultColumnWidths,
  isPaginated = false,
  clientPaginationConfig,
  manualPaginationConfig,
  expandableRowComponent,
  hasFilterColumns,
  hasResizeColumns,
  hasGlobalSearch = false,
  filterColumnsConfig,
  globalSearchConfig,
  isFocusable = true,
  isSelectable,
  rowSelectionConfig,
  rowExpandingConfig,
  rowActions,
  isLoading,
  manualFilters = false,
  manualSortBy = false,
  disableMultiSort = false,
  onSortChange,
  bulkActions,
  tableActions,
  emptyStateConfig,
  fullHeight,
  fullHeightConfig,
  collapsedTableConfig,
  uniqueRowId,
  tableInstanceId,
  manualFiltersRender,
  manualFiltersOptions,
  mobileLayoutGlobalConfig,
  exportConfig,
  customRowConfig,
  globalTrackingId,
  customToolbarRender,
  keepPreviousDataWhileLoading = false,
  loadingRowsCount = 20,
  isRowDisabled,
}: TableProps<T, C>) => {
  const uniqueFilterColumnsId = getFilterColumnsId(tableInstanceId);
  const uniqueOrderColumnsId = getOrderColumnsId(tableInstanceId);
  const uniqueResizeColumnsId = getResizeColumnsId(tableInstanceId);

  const tableId = useUniqueID();
  const tableRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const currentCellPositionRef = useRef<CellPositionCord>({ tableId, x: 0, y: -1 });
  const [tablePositioning, setTablePositioning] = useState(0);
  const [showEnabledFiltersBadge, setShowEnabledFiltersBadge] = useState(
    manualFiltersOptions?.initialBadgeState ?? false
  );
  const [isCollapsed, setIsCollapsed] = useState(collapsedTableConfig?.startingValue ?? false);
  const { scrollRef, isLeft, isRight, isTop, tableClientWidth, tableScrollWidth } = useScrollShadow();
  const isSmall = useMatchMedia({ maxWidth: breakpoints.small.max });
  const isScreen = useMediaQuery('screen');
  const isMobile = isSmall && isScreen;
  const tableStyles = useStyles('TableCommonStyles', 'tableContainerStyles', {
    isHoverable: !isLoading && (!!rowActions?.onRowClick || !!expandableRowComponent),
    tableId,
    isFocusable,
    tableStyle,
    wrapperStyle,
    leftShadow: !isLeft,
    rightShadow: !isRight,
    topShadow: !isTop,
    tableClientWidth,
    tableScrollWidth,
    tableYPositioning: tablePositioning,
    fullHeight,
    fullHeightConfig,
    isMobile,
    hasHoverableActions: rowActions?.shouldHover,
    hasStickyCustomRow: customRowConfig?.isSticky,
  });
  const currentlyExpanded = useRef<string>('');
  const scrollToTop = () => {
    if (tableRef?.current) {
      tableRef.current.scrollTop = 0;
    }
  };
  const defaultColumn = {
    minWidth: 60,
    width: 200,
    ...defaultColumnWidths,
  };

  useLayoutEffect(() => {
    if (fullHeight && tableRef?.current) {
      const tableVerticalPosition = tableRef.current.getBoundingClientRect().y;
      const paginationHeight = paginationRef.current?.getBoundingClientRect().height ?? 0;
      const positionOffset = fullHeightConfig?.offset ?? 0;

      setTablePositioning(tableVerticalPosition + paginationHeight + positionOffset);
    }
  }, []);

  const tableTrackingIds = useMemo(() => tableTrackingIdGenerator(globalTrackingId), [globalTrackingId]);

  const memoizedRowActionsColumn: Column<T>[] = useMemo((): Column<T>[] => {
    if (rowActions?.actions && (!rowActions?.shouldHover || isMobile)) {
      return [
        {
          id: reservedCells.ACTIONS,
          sticky: 'right',
          width: 52,
          maxWidth: 52,
          disableFilters: true,
          disableSortBy: true,
          disableGlobalFilter: true,
          disableResizing: true,
          ...rowActions,
        },
      ];
    }
    return [];
  }, [rowActions]);

  const memoizedRowExpandColumn: Column<T>[] = useMemo((): Column<T>[] => {
    if (expandableRowComponent) {
      return [
        {
          id: reservedCells.ROW_EXPAND,
          sticky: 'right',
          width: 52,
          maxWidth: 52,
          disableFilters: true,
          disableSortBy: true,
          disableGlobalFilter: true,
          disableResizing: true,
        },
      ];
    }
    return [];
  }, [rowActions]);

  const memoizedRowSelectionColumn: Column<T>[] = useMemo(() => {
    if (isSelectable) {
      return [
        {
          id: reservedCells.SELECT_ROW,
          minWidth: 50,
          width: 60,
          maxWidth: 60,
          sticky: 'left',
          disableFilters: true,
          disableSortBy: true,
          disableGlobalFilter: true,
          disableResizing: true,
        },
      ];
    }
    return [];
  }, [isSelectable]);

  const memoizedColumns = useMemo((): Column<T>[] => {
    return formatColConfig(
      [
        ...memoizedRowSelectionColumn,
        ...(colConfig as Column<T>[]),
        ...memoizedRowActionsColumn,
        ...memoizedRowExpandColumn,
      ],
      styleConfig
    );
  }, [colConfig, styleConfig, isSelectable, rowActions]);

  const mobileLeftShift = useMemo(
    () => (isMobile ? findMaxLeftShift(memoizedColumns) : undefined),
    [isMobile, memoizedColumns]
  );

  const memoizedData = useMemo(
    () => (isLoading && !keepPreviousDataWhileLoading ? Array(loadingRowsCount).fill({}) : data),
    [data, isLoading, keepPreviousDataWhileLoading]
  );
  const memoizedGlobalFilter = useMemo(() => getGlobalFilterFn(memoizedColumns), [memoizedColumns]);

  const handleCSSLayout = useCallback(() => {
    if (hasResponsiveColWidths && !isMobile) return useFlexLayout;
    return useBlockLayout;
  }, [hasResponsiveColWidths, isMobile]);

  const handleSticky = useCallback(() => {
    return !isMobile && useSticky;
  }, [isMobile]);

  const tableInstance = useTable<T>(
    {
      tableId,
      columns: memoizedColumns,
      data: memoizedData,
      disableMultiSort,
      defaultColumn,
      manualFilters: !!manualFiltersRender || manualFilters,
      manualSortBy: !!onSortChange || manualSortBy,
      manualGlobalFilter: !!globalSearchConfig?.searchHandler,
      manualPagination: !!manualPaginationConfig,
      filterTypes: filterTypeMap,
      stateReducer: rowSelectionConfig?.isSingleSelect
        ? (newState, action, previousState) => {
            if (action.type === 'toggleRowSelected') {
              const toggledId = action.id as IdType<T>;
              const previousSelectedRow = previousState.selectedRowIds[toggledId];
              const toggledState = !previousSelectedRow
                ? {
                    [toggledId]: true,
                  }
                : {};
              newState.selectedRowIds = toggledState as Record<IdType<T>, boolean>;
            }

            return newState;
          }
        : undefined,
      sortTypes: sortTypeMap,
      ...defaultAutoResetOptions,
      autoResetSelectedRows: !uniqueRowId,
      autoResetExpanded: !uniqueRowId,
      autoResetPage: !manualPaginationConfig,
      initialState: {
        pageIndex: 0,
        pageSize: clientPaginationConfig?.defaultRowsPerPage ?? clientPaginationConfig?.rowsPerPageOptions?.[0] ?? 10,
        selectedRowIds: rowSelectionConfig?.initialState ?? ({} as Record<IdType<T>, boolean>),
        expanded: rowExpandingConfig?.initialState ?? ({} as Record<IdType<T>, boolean>),
      },
      getRowId: uniqueRowId,
      globalFilter: memoizedGlobalFilter,
      isLoading,
      uniqueFilterColumnsId,
      uniqueOrderColumnsId,
      uniqueResizeColumnsId,
      filterColumnsConfig,
      hasFilterColumns,
      hasResizeColumns,
      isSelectable,
      customRowConfig,
      rowExpandingConfig,
      mobileLayoutGlobalConfig,
      rowSelectionConfig,
      leftShift: mobileLeftShift,
      tableTrackingIds,
      isMobile,
      isPaginated,
      scrollToTop,
      showEnabledFiltersBadge,
      getExportFileBlob,
      exportFileName: exportConfig?.exportFileName,
      setShowEnabledFiltersBadge,
      isRowDisabled,
    },
    ...allHooks,
    handleCSSLayout() as PluginHook<T>,
    handleSticky() as PluginHook<T>
  );

  if (instanceRef) {
    instanceRef.current = tableInstance;
  }

  useLayoutEffect(() => {
    if (hasResizeColumns && uniqueResizeColumnsId) {
      const resizedValues = window.localStorage.getItem(uniqueResizeColumnsId);
      if (resizedValues) {
        try {
          const values = JSON.parse(resizedValues);
          tableInstance.state.columnResizing.columnWidths = values;
        } catch (e) {
          console.error('Error parsing resized columns from local storage', e);
        }
      }
    }
  }, []);

  useLayoutEffect(() => {
    if (rowExpandingConfig?.controlledExpandId) {
      tableInstance.toggleRowExpanded([rowExpandingConfig?.controlledExpandId], true);
    }
  }, [rowExpandingConfig?.controlledExpandId]);

  useLayoutEffect(() => {
    if (hasFilterColumns && uniqueFilterColumnsId) {
      const hiddenValues = window.localStorage.getItem(uniqueFilterColumnsId);
      if (hiddenValues) {
        try {
          const values = JSON.parse(hiddenValues);
          if (Array.isArray(values)) {
            tableInstance.setHiddenColumns(values);
          }
        } catch (e) {
          console.error('Error parsing hidden columns from local storage', e);
        }
      }
    }
  }, []);

  useLayoutEffect(() => {
    if (hasFilterColumns && uniqueOrderColumnsId) {
      const reorderedColumns = window.localStorage.getItem(uniqueOrderColumnsId);
      if (reorderedColumns) {
        try {
          const values = JSON.parse(reorderedColumns);
          if (Array.isArray(values)) {
            tableInstance.setColumnOrder(values);
          }
        } catch (e) {
          console.error('Error parsing reordered columns from local storage', e);
        }
      }
    }
  }, []);

  const [prevSortBy, sortBy] = usePreviousValue(tableInstance?.state?.sortBy);

  const SortByTransformer = useCallback((): SortingValue<any>[] => {
    return (
      sortBy?.map((columnSort) => {
        return { id: columnSort.id, value: columnSort.desc ? 'desc' : 'asc' };
      }) ?? []
    );
  }, [sortBy]);

  useEffect(() => {
    if (!!onSortChange && !isEqual(prevSortBy, sortBy)) {
      onSortChange?.(SortByTransformer());
    }
  }, [sortBy]);

  const [prevSelectedRowIds, selectedRowIds] = usePreviousValue(tableInstance?.state?.selectedRowIds);

  useEffect(() => {
    if (
      selectedRowIds &&
      isSelectable &&
      !!rowSelectionConfig?.onSelectionToggle &&
      !isEqual(prevSelectedRowIds, selectedRowIds)
    ) {
      const selectedRows = tableInstance?.selectedFlatRows;
      const selectedRowsData = tableInstance?.selectedFlatRows.map((selectedRow) => selectedRow.original);
      rowSelectionConfig?.onSelectionToggle?.(selectedRows, selectedRowsData, selectedRowIds, tableInstance);
    }
  }, [selectedRowIds]);

  const expandedRow = Object.keys(tableInstance.state.expanded)?.[0] as string | undefined;

  useLayoutEffect(() => {
    if (!!rowExpandingConfig?.onExpand) {
      rowExpandingConfig?.onExpand?.(expandedRow, tableInstance);
    }
  }, [expandedRow]);

  const {
    canPreviousPage,
    page,
    rows,
    canNextPage,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageIndex },
  } = tableInstance;

  useEffect(() => {
    if (!!expandableRowComponent) {
      const expandedRows = Object.keys(tableInstance.state.expanded);
      if (expandedRows.length > 1) {
        const previouslyExpanded = expandedRows.find((rows) => rows === currentlyExpanded.current);
        if (previouslyExpanded) {
          tableInstance.toggleRowExpanded([previouslyExpanded], false);
        }
      }
      if (expandedRows.length === 1 && expandedRows[0]) {
        currentlyExpanded.current = expandedRows[0];
      }
    }
  }, [tableInstance.state.expanded]);

  const hasClientSideFilters = useMemo(() => {
    return tableInstance?.allColumns?.some((column) => !!column.filterConfig);
  }, [colConfig]);

  const isFilterEnabled = !!manualFiltersRender || hasClientSideFilters;
  const isToolbarEnabled =
    (isSelectable && !rowSelectionConfig?.hideBulkSelection && !rowSelectionConfig?.isSingleSelect) ||
    hasGlobalSearch ||
    isFilterEnabled ||
    !!tableActions ||
    !!(hasFilterColumns && uniqueFilterColumnsId) ||
    customToolbarRender;

  const { rowsPerPageOptions, ...restPaginationConfig } = clientPaginationConfig ?? {};

  const optimizedRowPerPageOptions = useCallback(
    (rowPerPageOptions: any, numberOfRows: any) => {
      if (numberOfRows >= rowPerPageOptions[rowPerPageOptions.length - 1]) {
        return rowPerPageOptions;
      }

      const firstLargerElementIndex = rowPerPageOptions.indexOf(rowPerPageOptions.find((el) => el >= numberOfRows));
      return rowPerPageOptions.slice(0, firstLargerElementIndex + 1);
    },
    [rowsPerPageOptions, memoizedData.length]
  );

  const clientRowPerPageOptions = optimizedRowPerPageOptions(
    rowsPerPageOptions ?? defaultRowsPerPageOptions,
    memoizedData.length
  );

  const defaultPaginationConfig: PaginationProps = {
    boundaries: 0,
    total: pageOptions.length,
    page: pageIndex + 1,
    hasPrevious: canPreviousPage,
    hasNext: canNextPage,
    disablePagination:
      clientRowPerPageOptions.length <= 1 ||
      tableInstance.rows.length <= (clientPaginationConfig?.defaultRowsPerPage || 10),
    handleChange: (pageNumber) => {
      gotoPage(pageNumber - 1);
      scrollToTop();
    },
    onNumRowsChange: setPageSize,
    rowsPerPageOptions: clientRowPerPageOptions,
    ...restPaginationConfig,
  };

  const activePaginationConfig = manualPaginationConfig ?? defaultPaginationConfig;

  const rowsToDisplay = isPaginated ? page : rows;
  const cellPositionsConfig: CellPositionsConfig = {
    maximumX: getTotalColumnCount(memoizedColumns) - 1,
    maximumY: rowsToDisplay.length - 1,
  };

  useLayoutEffect(() => {
    if (memoizedData.length < currentCellPositionRef.current.y) {
      currentCellPositionRef.current.y = -1;
    }

    if (rowsToDisplay.length < currentCellPositionRef.current.y) {
      currentCellPositionRef.current.y = -1;
    }
  }, [memoizedData.length, rowsToDisplay.length]);

  const isTableCollapsed = useMemo(() => {
    if (!collapsedTableConfig) {
      return false;
    }
    return !isCollapsed;
  }, [collapsedTableConfig, isCollapsed]);

  const tableWithListenersRefCb = useEventListenersOnTableBody({
    currentCellPositionRef,
    cellPositionsConfig,
    isFocusable,
    tableId,
    rows: rowsToDisplay,
  });

  const isColumnResizing = !!tableInstance.state.columnResizing.isResizingColumn;

  return (
    <div css={tableStyles}>
      {isToolbarEnabled && (
        <TableToolbar
          tableInstance={tableInstance}
          bulkActions={bulkActions}
          customToolbarRender={customToolbarRender}
          isSelectable={isSelectable}
          hideBulkSelection={rowSelectionConfig?.hideBulkSelection}
          hasGlobalSearch={hasGlobalSearch}
          manualGlobalSearch={!!globalSearchConfig}
          globalSearchConfig={globalSearchConfig}
          isFilterEnabled={isFilterEnabled}
          manualFiltersRender={manualFiltersRender}
          tableActions={tableActions}
        />
      )}
      <div
        aria-rowcount={tableInstance.rows.length}
        className={`table sticky`}
        role='table'
        id={tableId}
        ref={composeRefs(scrollRef, tableRef, tableWithListenersRefCb)}
      >
        {!isMobile && (
          <TableHeaders
            tableInstance={tableInstance}
            tableId={tableId}
            currentCellPositionRef={currentCellPositionRef}
          />
        )}
        <TableBody
          tableInstance={tableInstance}
          isPaginated={isPaginated}
          columns={memoizedColumns}
          rowActions={rowActions}
          isFocusable={isFocusable}
          tableId={tableId}
          currentCellPositionRef={currentCellPositionRef}
          expandableRowComponent={expandableRowComponent}
          emptyStateConfig={emptyStateConfig}
          tableClientWidth={tableClientWidth}
          collapsedTableConfig={collapsedTableConfig}
          isTableCollapsed={isTableCollapsed}
          isColumnResizing={isColumnResizing}
        />
      </div>
      {!isTableCollapsed && isPaginated ? (
        <Pagination globalTrackingId={globalTrackingId} ref={paginationRef} {...activePaginationConfig} />
      ) : null}
      {!!collapsedTableConfig && (
        <CollapsibleTableButton
          tableInstance={tableInstance}
          collapsedTableConfig={collapsedTableConfig}
          setIsCollapsed={setIsCollapsed}
          isCollapsed={isCollapsed}
        />
      )}
    </div>
  );
};
