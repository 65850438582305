import { useState } from 'react';
import { css } from '@emotion/react';
import { weaveBG, weaveBGPassword, weaveLogoDark } from '@frontend/assets';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { LoginFormComponent } from '../components/login-form.component';

export default function UsernameForm() {
  const [isShowingBGImage, setIsShowingBGImage] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        flex-wrap: wrap;
      `}
    >
      <div
        css={css`
          width: 40%;
          padding: ${theme.spacing(2)};
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          @media screen and (max-width: ${breakpoints.medium.max}px) {
            width: 100%;
          }
        `}
      >
        <div
          css={css`
            align-self: start;
            top: 0;
          `}
        >
          <img
            src={weaveLogoDark}
            alt='weave-app-logo'
            css={css`
              height: ${theme.spacing(3.5)};
            `}
            data-tour-guide='portal-logo'
          />
        </div>

        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
          `}
        >
          <div
            css={css`
              // display: flex;
              display: none;
              justify-content: center;
              align-items: center;

              color: #dd3131;
              font-family: DM Sans;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              background: #fff1f0;
              padding: 10px 10px;
              border: 1px solid #dd3131;
              border-radius: 8px 8px 8px 8px;
              margin-bottom: 15px;
              margin-top: 15px;
            `}
          >
            <span
              css={css`
                padding: 10px;
              `}
            >
              <b
                css={css`
                  font-weight: 700;
                `}
              >
                Important Notice:
              </b>{' '}
              Weave is currently experiencing an issue with our email service provider. Password reset emails will not
              be sent. We are actively working on addressing this issue. Please see our{' '}
              <a href={'https://status.getweave.com/'}>Status Page</a> for updates.
            </span>
          </div>
          <LoginFormComponent setIsShowingBGImage={setIsShowingBGImage} />
        </div>
      </div>
      <div
        css={css`
          width: 60%;
          flex: auto;
          @media screen and (max-width: 1400px) {
            flex: 1;
          }
          @media screen and (max-width: ${breakpoints.medium.max}px) {
            display: none;
          }
        `}
      >
        <img
          css={css`
            height: 100%;
            object-fit: cover;
            @media screen and (min-width: 1800px) {
              position: relative;
              left: 5%;
              height: ${theme.spacing(125)};
            }
          `}
          src={isShowingBGImage ? weaveBGPassword : weaveBG}
        />
      </div>
    </div>
  );
}
