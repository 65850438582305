import {
  Children,
  FC,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { SerializedStyles } from '@emotion/react';
import {
  FloatingPortal,
  Placement,
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
  FloatingNode,
  useFloatingNodeId,
} from '@floating-ui/react-dom-interactions';
import { AnimatePresence, motion } from 'framer-motion';
import { onlyText } from 'react-children-utilities';
import { KeyNames } from '../../../../constants/key-names';
import { useThemeValues } from '../../../../hooks/use-theme-values';
import { IconProps } from '../../../../icon/Icon.component';
import { NakedUl } from '../../../../naked/naked-ul.component';
import { useStyles } from '../../../../use-styles';
import { Text } from '../../../text';
import { DropdownInput } from '../../atoms/dropdown-input.component';
import { CustomChangeEvent } from '../../hooks/types';
import { FieldLayout } from '../../layouts/field-layout.component';
import type { BasicFormFieldProps } from '../../layouts/types';
import { activeDropdownStyle } from '../../list-fields/select-list/old-styles';
import { SelectContext } from './dropdown-field.context';
import { DropdownIcon } from './dropdown-icon.component';
import { DropdownListItem, DropdownListItemProps } from './dropdown-list-item';
import { OptionGroup } from './option-group.component';
import { MapByIndex, MapByValue, LookupValue } from './types';
import { getDisplayValue } from './utils';

type FlattenedChild = {
  children: ReactElement | ReactElement[];
} & DropdownListItemProps;

type OptionGroupProps = { label: string; children: ReactElement[] };

type DropdownFieldProps = Omit<BasicFormFieldProps<'dropdown'>, 'onChange'> & {
  children: ReactNode;
  containerCss?: SerializedStyles;
  className?: string;
  icon?: FC<React.PropsWithChildren<IconProps>>;
  maxHeight?: number | null;
  openOnArrowKeyDown?: boolean;
  initialPlacement?: Placement | '';
  onChange: (changeEvent: CustomChangeEvent<string>) => void;
  startAdornment?: ReactNode;
  dropdownListStyle?: SerializedStyles;
};

type DropdownFieldComponent = (props: DropdownFieldProps) => ReactElement;

export const DropdownFieldPreComposition: DropdownFieldComponent = ({
  children,
  containerCss,
  disabled,
  icon,
  label,
  maxHeight = null,
  name,
  onChange,
  openOnArrowKeyDown = true,
  placeholder = 'Select one',
  initialPlacement = '',
  value,
  dropdownListStyle,
  ...rest
}) => {
  const [floatingElementOpen, setFloatingElementOpen] = useState(false);
  const [placement, setPlacement] = useState<DropdownFieldProps['initialPlacement']>(initialPlacement);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const listItemsRef = useRef<Array<HTMLLIElement | null>>([]);
  const theme = useThemeValues();

  /**
   * depending on the configuration of the component, the children could be nested a few different ways
   * most commonly, values that come from the option group configuration will be nested inside OptionGroup
   * the flatChildren algorithm flattens all of the children and returns values that are meaningful to option selection
   * @input
   * <DropdownField>
   *   <DropdownField.OptionGroup label='Primary characters'>
   *     <DropdownField.Option value='Kramer' disabled={disableSome}>
   *       Cosmo Kramer
   *     </DropdownField.Option>
   *   </DropdownField.OptionGroup>
   *   <DropdownField.OptionGroup label='Secondary characters'>
   *     <DropdownField.Option value='Newman'>Newman</DropdownField.Option>
   *   </DropdownField.OptionGroup>
   * </DropdownField>
   *
   * @returns (with a lot of the react component properties such as `$$typeof: Symbol(react.element)` removed):
   * [
   *   {
   *     value: 'Kramer',
   *     children: 'Cosmo Kramer',
   *     disabled: false,
   *   },
   *   {
   *     value: 'Newman',
   *     children: 'Newman',
   *   },
   * ];
   */
  const flatChildren = Children.toArray(children).flatMap((child) => {
    const childProps = (child as ReactElement<OptionGroupProps>).props;
    if (!!childProps.label && Array.isArray(childProps.children)) return childProps.children.flat();
    else if (!!childProps.label && !Array.isArray(childProps.children)) return childProps.children;
    else return child;
  }) as ReactElement<FlattenedChild>[];
  const displayValue = getDisplayValue({
    children: flatChildren,
    value,
  });
  const hasChildren = flatChildren.length > 0;
  const menuStyles = useStyles('DropdownField', 'dropdownMenu', { maxHeight, placement });
  const inputStyles = useStyles('DropdownField', 'dropdownInput', { disabled });

  const lookupByIndex: MapByIndex = {};
  const lookupByValue: MapByValue = {};

  // now that the children are flattened, each child props pulled out and an index added.
  // useTypeahead needs an array of strings to search for the typeahead feature.
  // create two maps for quick lookup by either index or value in order to
  // avoid iterating through the whole array of strings each time floating-ui needs an index
  flatChildren.map((child, index) => {
    const lookupValue: LookupValue = {
      childText: onlyText(child as React.ReactNode),
      disabled: child.props?.disabled,
      index,
      searchValue: child.props?.searchValue || '',
      value: child.props?.value,
    };
    lookupByIndex[index] = lookupValue;
    lookupByValue[child.props?.value] = lookupValue;
  });

  const [selectedIndex, setSelectedIndex] = useState(lookupByValue?.[value]?.index);

  useEffect(() => {
    setSelectedIndex(lookupByValue?.[value]?.index);
  }, [value, Object.entries(lookupByValue).length]);

  const nodeId = useFloatingNodeId();
  const { x, y, reference, floating, strategy, context } = useFloating<HTMLInputElement>({
    nodeId,
    whileElementsMounted: autoUpdate,
    open: floatingElementOpen,
    onOpenChange: setFloatingElementOpen,
    middleware: [
      offset(!!label && placement === 'top' ? 12 : 3),
      flip(),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight - 8}px`,
          });
        },
      }),
    ],
  });

  // we need to further distill lookupByIndex into an array of strings.
  // searchableValues creates an array of strings, giving preference to searchValue.
  // if there is no searchValue, just use value
  const searchableValues: LookupValue['searchValue' | 'value' | 'childText'][] = Object.values(lookupByIndex).map(
    ({ value, childText, searchValue, index }: Omit<LookupValue, 'disabled'>) =>
      searchValue || childText || value || String(index)
  );

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useRole(context, { role: 'listbox' }),
    useDismiss(context),
    useListNavigation(context, {
      activeIndex,
      allowEscape: true,
      listRef: listItemsRef,
      loop: true,
      onNavigate: setActiveIndex,
      openOnArrowKeyDown,
      selectedIndex,
      virtual: true,
    }),
    useTypeahead(context, {
      // useTypeahead expects the array inside of a ref,
      // placing it as the value of `current` is just to keep useTypeahead happy
      listRef: { current: searchableValues },
      onMatch: (matchedIndex) => {
        if (lookupByIndex[matchedIndex]?.disabled) return;
        if (floatingElementOpen) setActiveIndex(matchedIndex);
        if (!floatingElementOpen && lookupByIndex[matchedIndex]?.value) {
          setSelectedIndex(matchedIndex);
          onChange({ name, value: lookupByIndex[matchedIndex]?.value });
        }
      },
      activeIndex,
      selectedIndex,
    }),
  ]);

  useLayoutEffect(() => {
    // IMPORTANT: When the floating element first opens, this runs when the
    // styles have **not yet** been applied to the element. This can cause an
    // infinite loop as `size` has not yet limited the maxHeight, so the whole
    // page tries to scroll. We must wrap it in rAF.
    requestAnimationFrame(() => {
      if (activeIndex != null) {
        listItemsRef.current[activeIndex]?.scrollIntoView({ block: 'nearest' });
      }
    });
  }, [activeIndex]);

  useEffect(() => {
    // need to hold placement in state to reference it in the `offset` property of `useFloating`
    setPlacement(context.placement);
  }, [context.placement]);

  return (
    <>
      <FieldLayout
        css={floatingElementOpen && activeDropdownStyle(placement || '')}
        disabled={disabled}
        endAdornment={
          <DropdownIcon active={floatingElementOpen} icon={icon} color={disabled ? 'disabled' : 'default'} />
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: figure out mixmatch between onBlur handlers
        field={DropdownInput}
        fieldComponentProps={{
          containerCss,
          css: inputStyles,
          displayValue,
          ref: reference,
        }}
        label={label}
        name={name}
        data-focusable
        placeholder={placeholder}
        {...getReferenceProps({
          value,
          placeholder,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore expects a browser event, but this is a custom change event
          onChange: (index: number) => {
            onChange({ name, value: lookupByIndex[index]?.value ?? '' });
            setActiveIndex(index);
          },
          onClick() {
            setFloatingElementOpen((open) => !open);
          },
          onKeyDown(e: KeyboardEvent<HTMLDivElement>) {
            if (e.key === KeyNames.Tab) {
              // close flyout if it is open and the user hits tab
              setFloatingElementOpen(false);
            }
            if (e.key === KeyNames.Enter) {
              e.preventDefault();
              setFloatingElementOpen((open) => !open);
            }
            if (
              /**
               * only allow enter selection (as opposed to allowing user to select with either enter or space)
               * for better search experience with values that have a space.
               * ex. typeahead should be able to differentiate New York/New Jersey
               */
              e.key === KeyNames.Enter &&
              activeIndex != null &&
              lookupByIndex?.[activeIndex]?.value
            ) {
              e.preventDefault();
              onChange({ name, value: lookupByIndex[activeIndex].value });
              setSelectedIndex(activeIndex);
              setFloatingElementOpen(false);
            }
          },
        })}
        {...rest}
      />
      <AnimatePresence>
        <FloatingNode id={nodeId}>
          {floatingElementOpen && (
            <FloatingPortal>
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                {...getFloatingProps({
                  ref: floating,
                  style: {
                    left: x ?? '',
                    minHeight: 40,
                    overflowY: 'auto',
                    position: strategy,
                    top: y ?? '',
                    zIndex: theme.zIndex.popover,
                  },
                })}
                css={[menuStyles, dropdownListStyle]}
              >
                <SelectContext.Provider
                  value={{
                    activeIndex,
                    name,
                    getItemProps,
                    listItemsRef,
                    lookupByValue,
                    onChange,
                    selectedIndex: selectedIndex!,
                    setFloatingElementOpen,
                    setSelectedIndex,
                  }}
                >
                  {hasChildren ? (
                    <NakedUl css={{ margin: 0 }}>{children}</NakedUl>
                  ) : (
                    <Text
                      css={{
                        margin: 0,
                        height: 40,
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: theme.spacing(1),
                      }}
                    >
                      No data
                    </Text>
                  )}
                </SelectContext.Provider>
              </motion.div>
            </FloatingPortal>
          )}
        </FloatingNode>
      </AnimatePresence>
    </>
  );
};

const DropdownFieldNamespace = Object.assign(DropdownFieldPreComposition, {
  Option: DropdownListItem,
  OptionGroup,
});

export { DropdownFieldNamespace as DropdownField };
