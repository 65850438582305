import React, { useRef, useCallback } from 'react';
import { calculateZoomLevel } from '@lexical/utils';
import type { LexicalEditor } from 'lexical';

const clamp = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max);
};

type Direction = {
  vertical: 'north' | 'south' | null;
  horizontal: 'east' | 'west' | null;
};

interface ImageResizerProps {
  editor: LexicalEditor;
  imageRef: React.RefObject<HTMLElement>;
  maxWidth?: number;
  onResizeStart: () => void;
  onResizeEnd: (width: number | 'inherit', height: number | 'inherit') => void;
}

interface Positioning {
  currentHeight: number | 'inherit';
  currentWidth: number | 'inherit';
  direction: Direction;
  isResizing: boolean;
  ratio: number;
  startHeight: number;
  startWidth: number;
  startX: number;
  startY: number;
}

const ImageResizer: React.FC<ImageResizerProps> = ({ editor, imageRef, maxWidth, onResizeStart, onResizeEnd }) => {
  const controlWrapperRef = useRef<HTMLDivElement>(null);
  const userSelectRef = useRef<{ priority: string; value: string }>({
    priority: '',
    value: 'default',
  });
  const positioningRef = useRef<Positioning>({
    currentHeight: 'inherit',
    currentWidth: 'inherit',
    direction: { vertical: null, horizontal: null },
    isResizing: false,
    ratio: 1,
    startHeight: 0,
    startWidth: 0,
    startX: 0,
    startY: 0,
  });

  const editorRootElement = editor.getRootElement();

  const calculateMaxDimensions = () => {
    if (editorRootElement) {
      const rect = editorRootElement.getBoundingClientRect();
      return {
        maxWidth: maxWidth ?? rect.width - 20,
        maxHeight: rect.height - 20,
      };
    }
    return { maxWidth: maxWidth ?? 100, maxHeight: 100 };
  };

  const { maxWidth: maxWidthContainer, maxHeight: maxHeightContainer } = calculateMaxDimensions();

  const minWidth = 100;

  const setStartCursor = useCallback(
    (direction: Direction) => {
      const isHorizontal = direction.horizontal !== null;
      const isVertical = direction.vertical !== null;

      let cursor = 'default';
      if (isHorizontal && isVertical) {
        const isNorthEast = direction.vertical === 'north' && direction.horizontal === 'east';
        const isSouthWest = direction.vertical === 'south' && direction.horizontal === 'west';
        cursor = isNorthEast || isSouthWest ? 'nesw' : 'nwse';
      } else if (isHorizontal) {
        cursor = 'ew';
      } else if (isVertical) {
        cursor = 'ns';
      }

      if (editorRootElement) {
        editorRootElement.style.setProperty('cursor', `${cursor}-resize`, 'important');
      }
      if (document.body) {
        document.body.style.setProperty('cursor', `${cursor}-resize`, 'important');
        userSelectRef.current.value = document.body.style.getPropertyValue('-webkit-user-select');
        userSelectRef.current.priority = document.body.style.getPropertyPriority('-webkit-user-select');
        document.body.style.setProperty('-webkit-user-select', 'none', 'important');
      }
    },
    [editorRootElement]
  );

  const setEndCursor = useCallback(() => {
    if (editorRootElement) {
      editorRootElement.style.setProperty('cursor', 'text');
    }
    if (document.body) {
      document.body.style.setProperty('cursor', 'default');
      document.body.style.setProperty(
        '-webkit-user-select',
        userSelectRef.current.value,
        userSelectRef.current.priority
      );
    }
  }, [editorRootElement]);

  const handlePointerDown = useCallback(
    (event: React.PointerEvent<HTMLDivElement>, direction: Direction) => {
      if (!editor.isEditable()) return;

      const image = imageRef.current;
      const controlWrapper = controlWrapperRef.current;

      if (image && controlWrapper) {
        event.preventDefault();
        const { width, height } = image.getBoundingClientRect();
        const zoom = calculateZoomLevel(image);
        const positioning = positioningRef.current;

        positioning.startWidth = width;
        positioning.startHeight = height;
        positioning.ratio = width / height;
        positioning.currentWidth = width;
        positioning.currentHeight = height;
        positioning.startX = event.clientX / zoom;
        positioning.startY = event.clientY / zoom;
        positioning.isResizing = true;
        positioning.direction = direction;

        setStartCursor(direction);
        onResizeStart();

        controlWrapper.classList.add('image-control-wrapper--resizing');
        image.style.height = 'auto';
        image.style.width = `${width}px`;

        document.addEventListener('pointermove', handlePointerMove);
        document.addEventListener('pointerup', handlePointerUp);
      }
    },
    [editor, imageRef, onResizeStart, setStartCursor]
  );

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      const image = imageRef.current;
      const positioning = positioningRef.current;

      if (image && positioning.isResizing) {
        const { direction, startWidth, startHeight, startX, startY } = positioning;
        const zoom = calculateZoomLevel(image);
        const currentX = event.clientX / zoom;
        const currentY = event.clientY / zoom;

        let newWidth = startWidth;
        const newHeight = startHeight;

        const isHorizontal = direction.horizontal !== null;
        const isVertical = direction.vertical !== null;

        if (isHorizontal && isVertical) {
          let diffX = startX - currentX;
          diffX = direction.horizontal === 'east' ? -diffX : diffX;

          newWidth = clamp(startWidth + diffX, minWidth, maxWidthContainer);
          image.style.width = `${newWidth}px`;
          image.style.height = 'auto';
        } else if (isVertical) {
          let diffY = startY - currentY;
          diffY = direction.vertical === 'south' ? -diffY : diffY;
          newWidth = clamp(startWidth + diffY, minWidth, maxWidthContainer);

          image.style.width = `${newWidth}px`;
        } else if (isHorizontal) {
          let diffX = startX - currentX;
          diffX = direction.horizontal === 'east' ? -diffX : diffX;

          newWidth = clamp(startWidth + diffX, minWidth, maxWidthContainer);

          image.style.width = `${newWidth}px`;
        }

        positioning.currentWidth = newWidth;
        positioning.currentHeight = newHeight;
      }
    },
    [imageRef, maxWidthContainer, maxHeightContainer]
  );

  const handlePointerUp = useCallback(() => {
    const image = imageRef.current;
    const controlWrapper = controlWrapperRef.current;
    const positioning = positioningRef.current;

    if (image && controlWrapper && positioning.isResizing) {
      const { currentWidth, currentHeight } = positioning;

      Object.assign(positioning, {
        currentHeight: 'inherit',
        currentWidth: 'inherit',
        direction: { vertical: null, horizontal: null },
        isResizing: false,
        ratio: 1,
        startHeight: 0,
        startWidth: 0,
        startX: 0,
        startY: 0,
      });

      controlWrapper.classList.remove('image-control-wrapper--resizing');

      setEndCursor();
      onResizeEnd(currentWidth, currentHeight);

      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
    }
  }, [imageRef, handlePointerMove, onResizeEnd, setEndCursor]);

  const resizerDirections = [
    { direction: { vertical: 'north', horizontal: null }, className: 'image-resizer-n' },
    { direction: { vertical: 'north', horizontal: 'east' }, className: 'image-resizer-ne' },
    { direction: { vertical: null, horizontal: 'east' }, className: 'image-resizer-e' },
    { direction: { vertical: 'south', horizontal: 'east' }, className: 'image-resizer-se' },
    { direction: { vertical: 'south', horizontal: null }, className: 'image-resizer-s' },
    { direction: { vertical: 'south', horizontal: 'west' }, className: 'image-resizer-sw' },
    { direction: { vertical: null, horizontal: 'west' }, className: 'image-resizer-w' },
    { direction: { vertical: 'north', horizontal: 'west' }, className: 'image-resizer-nw' },
  ] as const;

  return (
    <div ref={controlWrapperRef} className='image-resizer-wrapper'>
      {resizerDirections.map(({ direction, className }) => (
        <div
          key={className}
          className={`image-resizer ${className}`}
          onPointerDown={(event) => handlePointerDown(event, direction)}
        />
      ))}
    </div>
  );
};

export default ImageResizer;
