import { getInitialParams } from '@frontend/env';
import { HttpClient } from '@frontend/auth-helpers';
import { sentry } from '@frontend/tracking';

const { backendApi } = getInitialParams();

export const resetPassword = (email: string): Promise<Record<string, any>> => {
  const options: HttpClient = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  };

  return fetch(`${backendApi}/portal/v1/users/resetPassword`, options)
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => {
      sentry.error({
        topic: 'auth',
        error: err,
        addContext: {
          name: 'auth',
          context: {
            errMessage: 'password reset',
          },
        },
      });
    });
};

export const fetchSession = () => {
  const options: HttpClient = {
    method: 'GET',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch('https://auth.weaveconnect.com/api/v1/sessions/me', options)
    .then((res) => {
      if (res.status === 404) return false;
      return res.json();
    })
    .then((res) => res.status === 'ACTIVE')
    .catch((err) => {
      sentry.warn({
        topic: 'auth',
        error: err,
        addContext: {
          name: 'auth',
          context: { errMessage: 'Fetching Okta session' },
        },
      });
    });
};
