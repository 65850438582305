import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { Icon, type IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu } from '../../popover';
import { ActionDropdownChip } from '../atoms';

type AlignmentOptions = 'left' | 'center' | 'right' | 'justify';

type AlignmentActionProps = {
  value?: AlignmentOptions;
  onChange: (align: AlignmentOptions) => void;
} & Pick<React.ComponentProps<typeof ActionDropdownChip>, 'showBoxShadow' | 'compact' | 'css'>;

export const AlignmentAction = ({
  value = 'left',
  showBoxShadow = true,
  compact = true,
  onChange,
  ...rest
}: AlignmentActionProps) => {
  const { getTriggerProps, getMenuProps, getItemProps, isOpen } = usePopoverMenu<HTMLButtonElement | HTMLAnchorElement>(
    {
      interactionOptions: {
        listNavigation: {
          selectedIndex: selectedIndex(value),
        },
      },
      placement: 'bottom-start',
      middlewareOptions: {
        offset: {
          crossAxis: 0,
          mainAxis: 12,
        },
      },
    }
  );

  const activeAlignmentIcon = useMemo(() => alignmentItems.find((val) => val.value === value)?.icon, [value]);

  return (
    <>
      <ActionDropdownChip
        showBoxShadow={showBoxShadow}
        isOpen={isOpen}
        isActive={isOpen}
        compact={compact}
        {...getTriggerProps()}
        {...rest}
      >
        {activeAlignmentIcon && (
          <Icon
            name={activeAlignmentIcon}
            className='alignment-icon'
            css={css`
              margin-right: ${theme.spacing(0.5)};
            `}
          />
        )}
      </ActionDropdownChip>
      <PopoverMenu
        {...getMenuProps()}
        css={css`
          max-width: 160px;
        `}
        returnFocus={false}
      >
        {alignmentItems.map(({ label, value, icon }, index) => (
          <PopoverMenuItem
            Icon={() => <Icon name={icon} />}
            key={label}
            {...getItemProps({
              index,
              onClick: () => onChange(value),
            })}
          >
            {label}
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </>
  );
};

const selectedIndex = (alignment: string) => {
  const index = alignmentItems.findIndex((alignObj) => alignObj.value === alignment);
  return index < 0 ? null : index;
};

const alignmentItems = [
  {
    icon: 'align-left-small',
    label: 'Align Left',
    value: 'left',
  },
  {
    icon: 'align-center-small',
    label: 'Align Center',
    value: 'center',
  },
  {
    icon: 'align-right-small',
    label: 'Align Right',
    value: 'right',
  },
  {
    icon: 'justify-small',
    label: 'Justify',
    value: 'justify',
  },
] as const satisfies { icon: IconName; label: string; value: string }[];
