import React from 'react';
import { Icon } from '@frontend/icons';
import { styles } from '../../../styles';
import { Text } from '../../text';
import { ActionPressedChip } from '../atoms';

type IndentIncreaseActionProps = Omit<React.ComponentProps<typeof ActionPressedChip>, 'children'>;

export const IndentIncreaseAction = ({ compact = true, showBoxShadow = true, ...rest }: IndentIncreaseActionProps) => {
  return (
    <ActionPressedChip {...rest} compact={compact} showBoxShadow={showBoxShadow}>
      <Text as='strong' weight='bold' css={styles.flexCenter}>
        <Icon name='indent-increase-small' />
      </Text>
    </ActionPressedChip>
  );
};
