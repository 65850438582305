import { css } from '@emotion/react';
import { motion, AnimatePresence } from 'framer-motion';
import { WeaveThemeValues } from '../../../component-theme/theme-types';
import { useThemeValues } from '../../../hooks';
import { styles } from '../../../styles';
import { TrayModalBackdrop } from '../atoms';
import { ModalProvider } from '../provider';
import type { ModalControlModalProps, ModalProviderProps } from '../provider';
import { TrayActions } from './tray-actions';
import { TrayBody } from './tray-body';
import { TrayHeader } from './tray-header';

export type TrayProps = ModalControlModalProps &
  Pick<ModalProviderProps, 'mountTarget' | 'prepend'> & {
    animateOrigin?: 'right' | 'top';
    autoFocusTimeout?: number;
    children: React.ReactNode;
    width?: keyof typeof WIDTH_DIMS;
    disableCloseOnEscape?: boolean;
    zIndex?: WeaveThemeValues['ZIndex'];
  };

const trayClassname = 'wv-tray';

export const WIDTH_DIMS = {
  small: 360,
  smallXL: 400,
  medium: 500,
  large: 600,
  xlarge: 700,
} as const;

const variants = (animateOrigin: TrayProps['animateOrigin']) => ({
  hidden: {
    opacity: 0,
    transform: animateOrigin === 'right' ? 'translate3d(5%, 0, 0)' : 'translate3d(0%, -100px, 0)',
  },
  visible: { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
});

export const Tray = ({
  animateOrigin = 'right',
  autoFocusTimeout = 0,
  children,
  mountTarget,
  prepend,
  disableCloseOnEscape = false,
  width = 'small',
  zIndex,
  onClose,
  show,
  ...rest
}: TrayProps) => {
  const { zIndex: zIndexDefault, colors, spacing } = useThemeValues();

  return (
    <AnimatePresence>
      {show && (
        <ModalProvider
          timeout={autoFocusTimeout}
          onClose={onClose}
          className={trayClassname}
          mountTarget={mountTarget}
          prepend={prepend}
          disableCloseOnEscape={disableCloseOnEscape}
        >
          <TrayModalBackdrop
            onClick={() => onClose()}
            initial={{ opacity: 0 }}
            animate={{ opacity: show ? 1 : 0 }}
            exit={{ opacity: 0 }}
            variants={variants(animateOrigin)}
            zIndex={zIndex ?? zIndexDefault.modals}
            omitOffset={!!mountTarget}
          />
          <motion.section
            css={[
              css`
                width: calc(100% - 16px);
                max-width: ${WIDTH_DIMS[width]}px;
                height: 100%;
                background: ${colors.white};
                position: absolute;
                top: 0;
                right: 0;
                z-index: ${zIndex ?? zIndexDefault.modals};
                display: flex;
                flex-direction: column;
                padding: ${spacing(4)};
                gap: ${spacing(2)};
              `,
              !mountTarget && ((theme) => styles.getAdjustedHeight(theme)),
            ]}
            {...rest}
            initial='hidden'
            animate={show ? 'visible' : 'hidden'}
            exit='hidden'
            variants={variants(animateOrigin)}
            transition={{ duration: 0.3 }}
          >
            {children}
          </motion.section>
        </ModalProvider>
      )}
    </AnimatePresence>
  );
};

Tray.Header = TrayHeader;
Tray.Actions = TrayActions;
Tray.Body = TrayBody;
