import { css } from '@emotion/react';
import {
  chatActionContainerTheme,
  chatActionContainerThemeOriginal,
  chatBubbleTheme,
  chatBubbleThemeOriginal,
  chatContainerTheme,
  chatContainerThemeOriginal,
  ChatContainerThemeValues,
} from './chat-theme';

export const GRID_AREAS = {
  CONTENT: 'content',
  META: 'meta',
  AVATAR: 'avatar',
  EMPTY: 'empty',
} as const;

const containerStyles = ({ spacing }, { direction }) => {
  const gridRowTemplate1 =
    direction === 'outbound'
      ? `${GRID_AREAS.CONTENT} ${GRID_AREAS.AVATAR}`
      : `${GRID_AREAS.AVATAR} ${GRID_AREAS.CONTENT}`;
  const gridRowTemplate2 =
    direction === 'outbound' ? `${GRID_AREAS.META} ${GRID_AREAS.EMPTY}` : `${GRID_AREAS.EMPTY} ${GRID_AREAS.META}`;

  return css`
    width: 100%;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: ${direction === 'inbound' ? 'auto 1fr' : '1fr auto'};
    padding: 0;
    gap: ${spacing(0.5, 1)}; // Column gap is theme.spacing(1), row gap is 0
    grid-template-areas:
      '${gridRowTemplate1}'
      '${gridRowTemplate2}';
    align-items: end;
    margin-top: ${spacing(1)};
  `;
};

const contentStyle = ({ spacing, borderColor }: ChatContainerThemeValues, { direction }) => {
  return css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${direction === 'inbound' ? 'start' : 'end'};
    gap: ${spacing(1)};
    position: relative;
    grid-area: ${GRID_AREAS.CONTENT};

    .bubbles-container {
      display: flex;
      flex-direction: column;
      max-width: min(80%, 600px);
      gap: ${spacing(1)};
      align-items: ${direction === 'inbound' ? 'start' : 'end'};
    }

    .image-loader {
      padding: ${spacing(6)};
      border: 1px solid ${borderColor};
      display: flex;
      border-radius: 8px;
      justify-content: center;
      align-items: center;
    }
  `;
};

const metaInfoStyle = ({ spacing }, { direction }) => css`
  grid-area: ${GRID_AREAS.META};
  display: flex;
  flex-direction: column;
  align-items: ${direction === 'inbound' ? 'flex-start' : 'flex-end'};

  .tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: ${spacing(0.5)};
    justify-content: ${direction === 'inbound' ? 'flex-start' : 'flex-end'};
    align-items: ${direction === 'inbound' ? 'flex-start' : 'flex-end'};
    gap: ${spacing(1)};
  }

  .meta-info-container {
    display: flex;
    gap: ${spacing(1)};
    justify-content: ${direction === 'outbound' ? 'flex-end' : 'flex-start'};
    flex-direction: ${direction === 'inbound' ? 'row-reverse' : 'row'};
    padding-bottom: ${spacing(0.5)};
  }

  .metadata-container {
    display: flex;
    gap: ${spacing(1)};
  }
`;

const actionsStyle = ({ borderColor, borderRadius, borderShadow, spacing, backgroudColor, zIndex }) => css`
  border: 1px solid ${borderColor};
  border-radius: ${borderRadius};
  box-shadow: ${borderShadow};
  position: absolute;
  top: ${spacing(-2)};
  background-color: ${backgroudColor};
  display: flex;
  z-index: ${zIndex};
`;

const bubbleStyles = (
  { spacing, borderRadius, backgroundColorInbound, backgroundColorOutbound },
  { direction, isImage, backgroundColor, maxWidth, maxHeight }
) => css`
  list-style-type: none;
  padding: ${spacing(isImage ? 0 : 2)};
  display: ${isImage ? 'list-item' : 'flex'};
  overflow: ${isImage ? 'hidden' : 'visible'};
  gap: ${spacing(2)};
  border-radius: ${borderRadius};
  background-color: ${backgroundColor ?? (direction === 'inbound' ? backgroundColorInbound : backgroundColorOutbound)};
  white-space: pre-line;
  width: fit-content;
  max-height: ${maxHeight ?? (isImage ? 'min(40vh, 480px)' : '100%')};
  max-width: ${maxWidth ?? (isImage ? 'min(40%, 320px)' : '100%')};

  .img-bubble {
    width: 100%;
    border-radius: ${borderRadius};
  }
`;

const previewModalStyles = ({ spacing, backgroundColor }) => css`
  padding: 0;
  max-width: unset;
  overflow: visible;

  .wv-modal-flex-content {
    padding: 0;
  }

  .btn-containers {
    position: absolute;
    display: flex;
    gap: ${spacing(1)};
    top: ${spacing(-4)};
    right: 0;
  }

  .download-button {
    background-color: ${backgroundColor};
    height: ${spacing(3)};
    width: ${spacing(3)};
    padding: 0;
  }

  .close-button {
    background-color: ${backgroundColor};
    height: ${spacing(3)};
    width: ${spacing(3)};
    padding: 0;
  }
`;

export const ChatStyles = {
  Chat: {
    container: (props) => containerStyles(chatContainerTheme, props),
    content: (props) => contentStyle(chatContainerTheme, props),
    meta: (props) => metaInfoStyle(chatContainerTheme, props),
    actions: () => actionsStyle(chatActionContainerTheme),
    bubble: (props) => bubbleStyles(chatBubbleTheme, props),
    previewModal: () => previewModalStyles(chatContainerTheme),
  },
};

export const ChatStylesOriginal: typeof ChatStyles = {
  Chat: {
    container: (props) => containerStyles(chatContainerThemeOriginal, props),
    content: (props) => contentStyle(chatContainerThemeOriginal, props),
    meta: (props) => metaInfoStyle(chatContainerThemeOriginal, props),
    actions: () => actionsStyle(chatActionContainerThemeOriginal),
    bubble: (props) => bubbleStyles(chatBubbleThemeOriginal, props),
    previewModal: () => previewModalStyles(chatContainerThemeOriginal),
  },
};
