import { isNumber } from 'lodash-es';

type NumericOperationProps = {
  allowDecimal: boolean;
  max: number;
  min?: number;
  value?: string;
};

type EnsuredNumericProps = Omit<NumericOperationProps, 'value'> & { value: number };

type EnsureNumericFn = (props: EnsuredNumericProps) => number;
type EnsureNumericReturn = (props: NumericOperationProps) => number;

type CaretInfo = {
  typedValue: string;
  transformedValue: string;
  caretPosition: number | undefined;
  addedCharacter: string;
  newCharacterPosition: number;
  isNonNumericCharacterAdded: boolean;
  wholePart: string;
  decimalPart: string;
};

const ensureNumericValue =
  (fn: EnsureNumericFn): EnsureNumericReturn =>
  ({ value = '', ...rest }: NumericOperationProps): number => {
    const castValue = +(value || '').replace(/,/g, '');
    return fn({
      ...rest,
      value: isNaN(castValue) ? 0 : castValue,
    });
  };

const hasDecimal = (n: number): boolean => n % 1 !== 0;

export const incrementValue = ensureNumericValue(({ value, allowDecimal, max, min = 0 }) => {
  let next = value;
  if (allowDecimal && hasDecimal(value)) {
    next = Math.ceil(next);
  } else {
    next += 1;
  }
  if (isNumber(min) && next < min!) return min;
  return +(next <= max ? next : max);
});

export const decrementValue = ensureNumericValue(({ value, allowDecimal, max, min }) => {
  let next = value;
  if (allowDecimal && hasDecimal(value)) {
    next = Math.floor(next);
  } else {
    next -= 1;
  }
  if (next > max) return max;
  return +(!isNumber(min) || next >= min! ? next : min!);
});

// also allows negatives
export const floatCharsOnly = (value: string): string => value.replace(/[^0-9-.]/g, '');

const MIN_WHOLE_DIGITS = 1;
const MIN_DECIMAL_DIGITS = 2;
const TOTAL_ZEROS = MIN_WHOLE_DIGITS + MIN_DECIMAL_DIGITS;

const regexHelpers = {
  leadingZeroBeforeTensDigit: new RegExp(`^0(?=\\d{${MIN_WHOLE_DIGITS},}\\.\\d{${MIN_DECIMAL_DIGITS}})`, 'g'),
  nonNumericCharacters: /[^0-9]/g,
  nonDecimalCharacters: /[^0-9.]/g,
  leadingZeros: /(^0+|\$0+)/g,
  numberBeforeDollarSign: /^\d(?=\$)/g,
  requiredCurrencyFormat: new RegExp(`^\\d{${MIN_WHOLE_DIGITS},}\\.\\d{${MIN_DECIMAL_DIGITS}}$`, 'g'),
  numbersBeforeAndAfterDecimal: /^(\$?)0*(\d*)\.*(\d*)/g,
  thousandsSeparator: /\B(?=(\d{3})+(?!\d))/g,
};

const CARET_POSITIONS = {
  afterDot: -3,
  firstNumberAfterDot: -1,
};

/**
 * This object has helper methods used by the transformCurrencyValue method in the leftShiftingNumberHelpers object.
 */
const transformHelpers = {
  /**
   * Formats the input value to have two decimal places and two leading zeros.
   */
  transformDefaultCurrencyValue: (typedValue: string) => {
    typedValue = typedValue.replace(regexHelpers.nonDecimalCharacters, '');
    return typedValue.replace(regexHelpers.numbersBeforeAndAfterDecimal, (_, __, group2, group3) => {
      const leadingZerosCount = group2.length <= MIN_WHOLE_DIGITS ? MIN_WHOLE_DIGITS - group2.length : 0;
      const leadingZeros = '0'.repeat(leadingZerosCount);
      const trailingZerosCount = group3.length <= MIN_DECIMAL_DIGITS ? MIN_DECIMAL_DIGITS - group3.length : 0;
      const trailingZeros = '0'.repeat(trailingZerosCount);
      return `${leadingZeros}${group2 ?? ''}.${group3?.slice(0, MIN_DECIMAL_DIGITS) ?? ''}${trailingZeros}`;
    });
  },
  /**
   * Formats the input value to have two decimal places and two leading zeros.
   */
  formatTheNumberWithTwoDecimals: (typedValue: string) => {
    typedValue = typedValue.replace(regexHelpers.nonNumericCharacters, '').replace(regexHelpers.leadingZeros, '');
    typedValue =
      typedValue.length < TOTAL_ZEROS ? '0'.repeat(TOTAL_ZEROS - typedValue.length) + typedValue : typedValue;
    typedValue = typedValue.slice(0, -MIN_DECIMAL_DIGITS) + '.' + typedValue.slice(-MIN_DECIMAL_DIGITS);
    typedValue = typedValue.replace(regexHelpers.leadingZeroBeforeTensDigit, '');
    return typedValue;
  },
  /**
   * Moves the new number entered before the dollar sign to the end of the string as the last decimal number.
   */
  moveTheNumberBeforeDollarToTheEnd: (typedValue: string) => {
    const [numberBeforeDollarSign] = typedValue.match(regexHelpers.numberBeforeDollarSign) ?? [];
    if (numberBeforeDollarSign) typedValue = typedValue.slice(1) + numberBeforeDollarSign;
    return typedValue;
  },
  /**
   * Adds dollar sign and commas to the currency value just for display purposes,
   * after the value is sent to the parent component.
   */
  addDollarAndCommas(typedValue: string) {
    typedValue = typedValue.replace(/(\$|,)/g, '');
    const [wholePart, decimalPart] = typedValue.split('.');
    const wholePartWithCommas = wholePart?.replace(regexHelpers.thousandsSeparator, ',');
    return `$${wholePartWithCommas ?? ''}.${decimalPart}`;
  },
};

/**
 * This object has helper methods used by the getNewCaretPosition method in the leftShiftingNumberHelpers object.
 */
const caretHelpers = {
  /**
   * Returns the cursor position of the newly typed character based on if the character is a number or not.
   * This is used in the getCaretInfo method.
   */
  newCharacterPosition: (isNonNumericCharacterAdded: boolean, currentCaretPosition: number) => {
    return isNonNumericCharacterAdded && currentCaretPosition > 0 ? currentCaretPosition - 1 : currentCaretPosition;
  },
  /**
   * Returns the information needed by the other methods in the caretHelpers object.
   */
  getCaretInfo(typedValue: string, transformedValue: string, pressedKey: string, currentCaretPosition: number) {
    const addedCharacter = pressedKey;
    const [wholePart = '', decimalPart = ''] = typedValue.split('.');
    const isNonNumericCharacterAdded = !!addedCharacter && !!addedCharacter.match(regexHelpers.nonNumericCharacters);
    const newCharacterPosition = caretHelpers.newCharacterPosition(isNonNumericCharacterAdded, currentCaretPosition);
    const caretInfo: CaretInfo = {
      typedValue,
      addedCharacter,
      newCharacterPosition,
      isNonNumericCharacterAdded,
      caretPosition: undefined,
      transformedValue: transformHelpers.addDollarAndCommas(transformedValue),
      wholePart,
      decimalPart,
    };
    return caretInfo;
  },
  /**
   * Handles the cursor position when a number is entered before the dollar sign or when the input is initially empty.
   */
  numberEnteredBeforeDollarSign: (caretInfo: CaretInfo) => {
    if (caretInfo.caretPosition) return;
    const { transformedValue, newCharacterPosition, addedCharacter } = caretInfo;
    const isEnteredBeforeDollarSign = newCharacterPosition === 1;
    const lastLetterPosition = transformedValue.length;
    if (isEnteredBeforeDollarSign && addedCharacter) {
      caretInfo.caretPosition = lastLetterPosition + 1;
    }
  },
  /**
   * Handles the cursor position when a number is removed from the currency value.
   */
  numberRemoved: (caretInfo: CaretInfo) => {
    if (caretInfo.caretPosition) return;
    const { typedValue, transformedValue, newCharacterPosition, wholePart, decimalPart } = caretInfo;

    //if significant decimal digits are removed
    if (decimalPart.length === MIN_DECIMAL_DIGITS - 1) {
      const lastNumberRemoved = newCharacterPosition === typedValue.length;
      caretInfo.caretPosition = lastNumberRemoved ? transformedValue.length : transformedValue.length - 1;
      //if significant whole digits are removed
    } else if (wholePart.replace(regexHelpers.nonNumericCharacters, '').length === MIN_WHOLE_DIGITS - 1) {
      const firstNumberRemoved = newCharacterPosition === 1;
      caretInfo.caretPosition = firstNumberRemoved ? MIN_WHOLE_DIGITS - 1 : MIN_WHOLE_DIGITS;
    }
  },
  /**
   * Handles the cursor position when the first min digits before the decimal point are added.
   * This is used in the numberAdded method.
   */
  firstTwoNumbersAddedBeforeDot: (caretInfo: CaretInfo) => {
    const { wholePart, newCharacterPosition } = caretInfo;
    const isCaretAtEndOfWholePart = newCharacterPosition === wholePart.length;
    const leadingZerosMatch = wholePart.match(regexHelpers.leadingZeros);
    const leadingZeros = leadingZerosMatch?.[0].replace(regexHelpers.nonNumericCharacters, '') ?? '';
    const leadingZerosCount = leadingZeros.length ?? 0;
    const isCaretInWholePart = newCharacterPosition <= wholePart.length;
    const wholePartAdded = wholePart.length > MIN_WHOLE_DIGITS + 1 && isCaretInWholePart;

    return {
      firstTwoNumbersAddedBeforeDot: leadingZerosCount > 0 && wholePartAdded,
      firstNumberAdded: leadingZerosCount === 1 && !isCaretAtEndOfWholePart,
    };
  },
  /**
   * Handles the cursor position when a number is added in the fractional part of the currency value.
   * This is used in the numberAdded method.
   */
  numberAddedInFrationalPart: (caretInfo: CaretInfo) => {
    const { decimalPart, newCharacterPosition, typedValue, transformedValue } = caretInfo;
    const decimalPartAdded = decimalPart.length > MIN_DECIMAL_DIGITS;
    const firstDecimalChanged = newCharacterPosition === typedValue.length - 1;
    const numberAddedAfterDot = newCharacterPosition === typedValue.length - 2;
    const decimalPartCaretPosition = numberAddedAfterDot
      ? transformedValue.length + CARET_POSITIONS.afterDot
      : firstDecimalChanged
      ? transformedValue.length + CARET_POSITIONS.firstNumberAfterDot
      : transformedValue.length;
    return { decimalPartAdded, decimalPartCaretPosition };
  },
  /**
   * Handles the cursor position when a number is added to the currency value.
   * This uses the firstTwoNumbersAddedBeforeDot and numberAddedInFrationalPart methods.
   */
  numberAdded: (caretInfo: CaretInfo) => {
    if (caretInfo.caretPosition) return;
    const { firstTwoNumbersAddedBeforeDot, firstNumberAdded } = caretHelpers.firstTwoNumbersAddedBeforeDot(caretInfo);
    const { decimalPartAdded, decimalPartCaretPosition } = caretHelpers.numberAddedInFrationalPart(caretInfo);
    if (firstTwoNumbersAddedBeforeDot) {
      caretInfo.caretPosition = firstNumberAdded ? MIN_WHOLE_DIGITS : MIN_WHOLE_DIGITS + 1;
    } else if (decimalPartAdded) {
      caretInfo.caretPosition = decimalPartCaretPosition;
    }
  },
  /**
   * Modifies the cursor position to account for the commas are added or removed after it is calculated.
   */
  commasChanged: (caretInfo: CaretInfo) => {
    const { typedValue, newCharacterPosition, transformedValue, caretPosition, decimalPart, addedCharacter } =
      caretInfo;
    const dotEntered = addedCharacter === '.' && typedValue.replace(/[^.]/g, '').length > 1;

    if (!dotEntered) {
      const typedValueBeforeCaret = typedValue.slice(0, newCharacterPosition);
      const typedValueCommas = typedValueBeforeCaret.replace(/[^,]/g, '').length;
      const decimalPartChanged = decimalPart.length !== MIN_DECIMAL_DIGITS;

      const transformedCaretPosition = caretPosition ?? newCharacterPosition;
      const transformedValueBeforeCaret = transformedValue.slice(0, transformedCaretPosition);
      const transformedValueCommas = transformedValueBeforeCaret.replace(/[^,]/g, '').length;

      const changedCommas = transformedValueCommas - typedValueCommas;
      caretInfo.caretPosition = !decimalPartChanged
        ? transformedCaretPosition + changedCommas
        : transformedCaretPosition;
    }
  },
};

/**
 * This object has exported functions to transform the currency value and manage the caret position
 */
export const currencyInputHelpers = {
  /**
   * Transforms the default value sent to the currency input.
   */
  transformDefaultValue(value: string) {
    if (value.trim() && !value.match(regexHelpers.requiredCurrencyFormat)) {
      value = transformHelpers.transformDefaultCurrencyValue(value);
    }
    return value;
  },
  /**
   * Adds dollar sign and commas to the currency value just for display purposes,
   * after the value is sent to the parent component
   */
  addDollarAndCommas: transformHelpers.addDollarAndCommas,
  /**
   * Transforms the input value to the expected format
   */
  transformCurrencyValue(typedValue: string) {
    if (!typedValue.trim()) return typedValue;
    typedValue = transformHelpers.moveTheNumberBeforeDollarToTheEnd(typedValue);
    typedValue = transformHelpers.formatTheNumberWithTwoDecimals(typedValue);
    typedValue = floatCharsOnly(typedValue);
    return typedValue;
  },
  /**
   * Manages the caret position in the input field after the value is transformed
   * and sent to the parent component
   */
  getNewCaretPosition(typedValue: string, transformedValue: string, pressedKey: string, currentCaretPosition: number) {
    const caretInfo = caretHelpers.getCaretInfo(typedValue, transformedValue, pressedKey, currentCaretPosition);
    if (!caretInfo.isNonNumericCharacterAdded) {
      caretHelpers.numberEnteredBeforeDollarSign(caretInfo);
      caretHelpers.numberRemoved(caretInfo);
      caretHelpers.numberAdded(caretInfo);
      caretHelpers.commasChanged(caretInfo);
    }

    return caretInfo.caretPosition ?? caretInfo.newCharacterPosition;
  },
};
