import { theme as themeOriginal } from '@frontend/theme-original';
import { theme } from '@frontend/theme';
import { WeaveThemeValues } from '../../component-theme';

export type ChatContainerThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  backgroundColor: WeaveThemeValues['Color'];
  borderColor?: WeaveThemeValues['Color'];
};

export const chatContainerTheme: ChatContainerThemeValues = {
  spacing: theme.spacing,
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.neutral20,
};

export const chatContainerThemeOriginal: ChatContainerThemeValues = {
  spacing: themeOriginal.spacing,
  backgroundColor: themeOriginal.colors.white,
  borderColor: themeOriginal.colors.gray400,
};

export type ChatActionContainerThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  borderShadow: WeaveThemeValues['BoxShadow'];
  borderColor: WeaveThemeValues['Color'];
  backgroudColor: WeaveThemeValues['Color'];
  zIndex: WeaveThemeValues['ZIndex'];
};

export const chatActionContainerTheme: ChatActionContainerThemeValues = {
  spacing: theme.spacing,
  borderRadius: theme.borderRadius.small,
  borderShadow: theme.shadows.light,
  borderColor: theme.colors.neutral20,
  backgroudColor: theme.colors.white,
  zIndex: theme.zIndex.popover,
};

export const chatActionContainerThemeOriginal: ChatActionContainerThemeValues = {
  spacing: themeOriginal.spacing,
  borderRadius: themeOriginal.borderRadius.small,
  borderShadow: themeOriginal.shadows.light,
  borderColor: themeOriginal.colors.gray400,
  backgroudColor: themeOriginal.colors.white,
  zIndex: themeOriginal.zIndex.high,
};

export type ChatBubbleThemeValues = {
  spacing: WeaveThemeValues['Spacing'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  backgroundColorInbound: WeaveThemeValues['Color'];
  backgroundColorOutbound: WeaveThemeValues['Color'];
};

export const chatBubbleTheme: ChatBubbleThemeValues = {
  spacing: theme.spacing,
  borderRadius: theme.borderRadius.medium,
  backgroundColorInbound: theme.colors.neutral5,
  backgroundColorOutbound: theme.colors.primary5,
};

export const chatBubbleThemeOriginal: ChatBubbleThemeValues = {
  spacing: themeOriginal.spacing,
  borderRadius: themeOriginal.borderRadius.medium,
  backgroundColorInbound: themeOriginal.colors.gray100,
  backgroundColorOutbound: themeOriginal.colors.weaveBlue,
};

export const easeInOutVariants = {
  visible: { opacity: 1, transition: { duration: 0.3 } },
  hidden: { opacity: 0, transition: { duration: 0.3 } },
};

export const slideInVariants = {
  hidden: { height: 0, opacity: 0 },
  visible: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
};
