import { useState, ReactNode } from 'react';
import { contextFactory } from '../../hooks';

interface SlideCarouselContextProps {
  activeId: string;
  setActiveId: (id: string) => void;
  slideIds: string[];
  canDrag?: boolean;
}

export const [SlideCarouselContext, useSlideCarousel] = contextFactory<SlideCarouselContextProps>();

interface SlideCarouselProviderProps {
  children: ReactNode;
  slides: string[];
  initialSlide?: string;
  canDrag?: boolean;
}

export const SlideCarouselProvider = ({ children, slides, initialSlide, canDrag }: SlideCarouselProviderProps) => {
  const [activeId, setActiveId] = useState<string>(initialSlide ?? slides[0]!);
  const [slideIds] = useState<string[]>(slides);

  return (
    <SlideCarouselContext.Provider value={{ activeId, setActiveId, slideIds, canDrag }}>
      {children}
    </SlideCarouselContext.Provider>
  );
};
