import * as Sentry from '@sentry/react';
import { log, warn, error } from './sentry-logger';

export const sentry = {
  instance: Sentry,
  init: (config: Sentry.BrowserOptions) => Sentry.init(config),
  log,
  warn,
  error,
};
