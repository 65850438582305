/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const MessageIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M2.84475834,16.0710159 L2.81825388,16.1441893 C2.56460108,16.8434603 2.475706,17.1447379 2.4470079,17.5935127 C2.39650317,18.383295 2.69599563,19.0941669 3.37953694,19.5719882 C4.15071924,20.1110737 4.96838044,20.149459 5.78377551,19.8122986 C6.04221198,19.7054369 6.11013041,19.6680568 6.62510084,19.3698912 C7.11212033,19.087909 7.30884632,19.0224856 7.51060265,19.0567834 C8.44961275,19.2164118 9.64628889,19.25 12,19.25 C17.6019328,19.25 22.25,15.6348366 22.25,11 C22.25,6.36516337 17.6019328,2.75 12,2.75 C6.39806719,2.75 1.74999997,6.36516337 1.74999997,11 C1.74999997,12.5111272 2.21105352,14.2867311 3.04078022,15.4138545 C3.02374704,15.5439961 2.96960784,15.7254574 2.84475834,16.0710159 Z M5.16841408,16.9966839 L5.19600287,16.9205153 C5.67590371,15.5922456 5.76487178,14.8453928 5.08187335,13.9684783 C4.59605246,13.3447238 4.24999997,12.042142 4.24999997,11.0000019 C4.24999997,7.90285013 7.66080731,5.25 12,5.25 C16.3391927,5.25 19.75,7.90285013 19.75,11 C19.75,14.0971499 16.3391927,16.75 12,16.75 C9.83319016,16.75 8.66535714,16.7172214 7.92958289,16.5921424 C6.99168143,16.4327024 6.32671691,16.6538439 5.37243194,17.2063708 C5.20394387,17.3039246 5.09941267,17.3641692 5.02610399,17.4048086 C5.060118,17.2999619 5.10638688,17.1676807 5.16841408,16.9966839 Z'
    />
  </Icon>
);
