import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils';
import {
  $createParagraphNode,
  $createRangeSelection,
  $getSelection,
  $insertNodes,
  $isNodeSelection,
  $isRootOrShadowRoot,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  createCommand,
  DRAGOVER_COMMAND,
  DRAGSTART_COMMAND,
  DROP_COMMAND,
  LexicalCommand,
  LexicalEditor,
} from 'lexical';
import { $createImageNode, $isImageNode, ImageNode, ImagePayload } from '../nodes/image-node';

export type InsertImagePayload = Readonly<ImagePayload>;

type DragImageData = {
  type: 'image';
  data: InsertImagePayload;
};

const TRANSPARENT_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const MIME_TYPES = {
  LEXICAL_DRAG: 'application/x-lexical-drag',
  PLAIN_TEXT: 'text/plain',
} as const;

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> = createCommand('INSERT_IMAGE_COMMAND');

const getDOMSelection = (targetWindow: Window | null): Selection | null => (targetWindow || window).getSelection();

const createTransparentImage = (): HTMLImageElement => {
  const img = document.createElement('img');
  img.src = TRANSPARENT_IMAGE;
  return img;
};

function $getImageNodeInSelection(): ImageNode | null {
  const selection = $getSelection();
  if (!$isNodeSelection(selection)) {
    return null;
  }
  const [node] = selection.getNodes();
  return $isImageNode(node) ? node : null;
}

class DragDropManager {
  static transparentImage = createTransparentImage();

  static canDropImage(event: DragEvent): boolean {
    const target = event.target as HTMLElement;
    return !!(
      target &&
      !target.closest('code, span.editor-image') &&
      target.parentElement?.closest('div.ContentEditable__root')
    );
  }

  static getDragImageData(event: DragEvent): InsertImagePayload | null {
    const dragData = event.dataTransfer?.getData(MIME_TYPES.LEXICAL_DRAG);
    if (!dragData) return null;

    try {
      const parsedData = JSON.parse(dragData) as DragImageData;
      return parsedData.type === 'image' ? parsedData.data : null;
    } catch {
      return null;
    }
  }

  static getDragSelection(event: DragEvent): Range | null {
    const target = event.target as null | Element | Document;
    const targetWindow = DragDropManager.getTargetWindow(target);
    const domSelection = getDOMSelection(targetWindow);

    try {
      if (document.caretRangeFromPoint) {
        return document.caretRangeFromPoint(event.clientX, event.clientY);
      }

      if ((event as any).rangeParent && domSelection) {
        domSelection.collapse((event as any).rangeParent, (event as any).rangeOffset || 0);
        return domSelection.getRangeAt(0);
      }
    } catch (error) {
      console.error('Error getting drag selection:', error);
    }

    return null;
  }

  private static getTargetWindow(target: null | Element | Document): Window | null {
    if (!target) return null;
    return target.nodeType === Node.DOCUMENT_NODE
      ? (target as Document).defaultView
      : (target as Element).ownerDocument.defaultView;
  }
}

function createDragHandlers(editor: LexicalEditor) {
  const onDragStart = (event: DragEvent): boolean => {
    const node = $getImageNodeInSelection();
    if (!node || !event.dataTransfer) return false;

    const nodeData: DragImageData = {
      type: 'image',
      data: {
        altText: node.__altText,
        height: node.__height,
        key: node.getKey(),
        maxWidth: node.__maxWidth,
        src: node.__src,
        width: node.__width,
      },
    };

    event.dataTransfer.setData(MIME_TYPES.PLAIN_TEXT, '_');
    event.dataTransfer.setDragImage(DragDropManager.transparentImage, 0, 0);
    event.dataTransfer.setData(MIME_TYPES.LEXICAL_DRAG, JSON.stringify(nodeData));

    return true;
  };

  const onDragOver = (event: DragEvent): boolean => {
    const node = $getImageNodeInSelection();
    if (!node) return false;

    if (!DragDropManager.canDropImage(event)) {
      event.preventDefault();
    }
    return true;
  };

  const onDrop = (event: DragEvent): boolean => {
    const node = $getImageNodeInSelection();
    if (!node) return false;

    const imageData = DragDropManager.getDragImageData(event);
    if (!imageData) return false;

    event.preventDefault();

    if (DragDropManager.canDropImage(event)) {
      const range = DragDropManager.getDragSelection(event);
      node.remove();

      if (range) {
        const rangeSelection = $createRangeSelection();
        rangeSelection.applyDOMRange(range);
        $setSelection(rangeSelection);
      }

      editor.dispatchCommand(INSERT_IMAGE_COMMAND, imageData);
    }

    return true;
  };

  return { onDragStart, onDragOver, onDrop };
}

export function ImagesPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagesPlugin: ImageNode not registered on editor');
    }

    const { onDragStart, onDragOver, onDrop } = createDragHandlers(editor);

    return mergeRegister(
      editor.registerCommand<InsertImagePayload>(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const imageNode = $createImageNode(payload);
          $insertNodes([imageNode]);

          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
          }

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(DRAGSTART_COMMAND, onDragStart, COMMAND_PRIORITY_HIGH),
      editor.registerCommand(DRAGOVER_COMMAND, onDragOver, COMMAND_PRIORITY_LOW),
      editor.registerCommand(DROP_COMMAND, onDrop, COMMAND_PRIORITY_HIGH)
    );
  }, [editor]);

  return null;
}
