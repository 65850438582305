import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  BackIcon,
  EmailField,
  FeedbackIcon,
  FormRow,
  Heading,
  PrimaryButton,
  Text,
  TextLink,
  useDebouncedValue,
  useFormField,
  useAlert,
} from '@frontend/design-system';
import { sendPasswordReset } from '../services';

interface Props {
  initialUsername?: string;
  onBack?: (username?: string) => void;
}

export const PasswordResetOIDC = ({ initialUsername = '', onBack }: Props) => {
  const { t } = useTranslation('SignIn');
  const alerts = useAlert();
  const [passwordResetSuccess, setPasswordResetSuccess] = useState<boolean>(false);

  // username props
  const usernameProps = useFormField({ type: 'email', required: true, value: initialUsername });
  const isUsernameComplete = useDebouncedValue(!usernameProps.error);
  const username = useDebouncedValue(usernameProps.value);

  const handleResetPassword = () => {
    sendPasswordReset(username as string)
      .then(() => {
        setPasswordResetSuccess(true);
        // const email = usernameProps.value;
        // alerts.success(t('The reset password link has been sent to {{value}}', { value: email }));
      })
      .catch(() => {
        alerts.error(t('Failed to send the password reset link'));
      });
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 15%;
        min-width: 300px;
        max-width: 400px;
        // position: absolute;
        // top: 30%;
        // left: 0;
        // bottom: 0;
        // right: 0;
        align-items: ${passwordResetSuccess ? 'center' : 'initial'};
        text-align: ${passwordResetSuccess ? 'center' : 'initial'};
      `}
    >
      <FeedbackIcon
        css={css`
          display: ${passwordResetSuccess ? 'block' : 'none'};
          color: ${theme.colors.primary50};
          align-items: center;
        `}
        size={40}
      />
      <Heading
        css={css`
          margin: ${theme.spacing(2, 0)};
        `}
      >
        {!passwordResetSuccess ? t('Forgot password?') : t('Success')}
      </Heading>
      <Text>
        {!passwordResetSuccess
          ? t('No worries, we will send you reset instructions')
          : t('If your user credentials exist in our system we have sent you a password reset link via email.')}
      </Text>
      <form
        css={css`
          display: ${!passwordResetSuccess ? 'flex' : 'none'};
          flex-direction: column;
        `}
      >
        <FormRow
          css={css`
            text-align: initial;
            margin: ${theme.spacing(4, 0)};
          `}
        >
          <EmailField name='username' {...usernameProps} label={t('Email')} />
        </FormRow>

        <PrimaryButton onClick={handleResetPassword} disabled={!isUsernameComplete} type='button' size='large'>
          {t('Reset Password')}
        </PrimaryButton>
      </form>

      <TextLink
        css={css`
          display: flex;
          margin: ${theme.spacing(4, 0, 0)};
          cursor: pointer;
          text-align: left;
          width: 100%;
        `}
        onClick={() => onBack?.(usernameProps.value)}
      >
        <>
          <BackIcon
            css={css`
              margin-right: ${theme.spacing(1)};
            `}
          />
          {t('Back to Log In')}
        </>
      </TextLink>
    </div>
  );
};
