import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { DRAG_DROP_PASTE } from '@lexical/rich-text';
import { isMimeType, mediaFileReader } from '@lexical/utils';
import { COMMAND_PRIORITY_LOW } from 'lexical';
import { INSERT_IMAGE_COMMAND } from '../plugins/images-plugin';

const ACCEPTABLE_IMAGE_TYPES: string[] = [
  'image/heic',
  'image/heif',
  'image/gif',
  'image/webp',
  'image/png',
  'image/jpeg',
  'image/bmp',
  'image/svg+xml',
];

const getImageDimensions = async (src: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
    img.src = src;
  });
};

export const DropPaste: React.FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const handleDragDropPaste = (files: File[]) => {
      (async () => {
        try {
          const filesResult = await mediaFileReader(files, ACCEPTABLE_IMAGE_TYPES);

          for (const { file, result } of filesResult) {
            if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
              const dimensions = await getImageDimensions(result);

              const initialWidth = dimensions.width;
              const initialHeight = dimensions.height;

              editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                src: result,
                altText: '',
                width: initialWidth,
                height: initialHeight,
              });
            }
          }
        } catch (error) {
          console.error('Error processing drag and drop paste:', error);
        }
      })();
      return true;
    };

    editor.registerCommand(DRAG_DROP_PASTE, handleDragDropPaste, COMMAND_PRIORITY_LOW);
  }, [editor]);

  return null;
};
