import { ElementType, ReactNode, forwardRef, KeyboardEvent, MouseEvent } from 'react';
import { CSSObject } from '@emotion/react';
import { theme } from '@frontend/theme';
import { KeyNames } from '../../../../constants';
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '../../../../type-utils';
import { useStyles } from '../../../../use-styles';
import { ContextMenu, ContextMenuActions } from '../../../context-menu/context-menu';
import { SpinningLoader } from '../../../loader';
import { useChatBubbleContext } from '../../chat';
import { ImageBubble } from './image-bubble';
import { TextBubble } from './text-bubble';

export type BubbleProps = {
  text?: string | ReactNode;
  subText?: ReactNode;
  bubbleIcon?: ReactNode;
  onClick?: (e?: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void;
  contextActions?: ContextMenuActions;
  contextMenuReturnFocus?: boolean;
  isDeleted?: boolean;
  variant: 'text' | 'deleted' | 'image';
  src: string;
  maxWidth: string;
  maxHeight: string;
  imageProps?: {
    suppressPreview?: boolean;
    onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
    onClickDownload?: () => void;
    downloadImageTrackingId?: string;
    backgroundColor?: (typeof theme.colors)[keyof typeof theme.colors];
    css?: CSSObject;
  };
};

type PolymorphicBubbleProps<C extends ElementType = 'li'> = PolymorphicComponentPropWithRef<C, BubbleProps>;

const BubbleComponent = <C extends ElementType = 'li'>(
  {
    as,
    text,
    subText,
    bubbleIcon,
    onClick,
    contextActions,
    contextMenuReturnFocus,
    variant = 'text',
    src,
    imageProps,
    backgroundColor,
    maxWidth,
    maxHeight,
  }: PolymorphicBubbleProps<C>,
  ref: PolymorphicRef<C>
) => {
  const Component = as || 'li';
  const { direction, isSending } = useChatBubbleContext();
  const bubbleStyles = useStyles('Chat', 'bubble', {
    direction,
    isImage: variant === 'image',
    backgroundColor,
    maxWidth,
    maxHeight,
  });

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === KeyNames.Enter || event.key === KeyNames.Space) {
      onClick?.(event);
    }
  };

  if (isSending || (variant === 'image' && !src)) {
    return (
      <div className={variant === 'image' ? 'image-loader' : ''}>
        <SpinningLoader size='medium' />
      </div>
    );
  }

  return (
    <ContextMenu actions={contextActions ?? []} returnFocus={contextMenuReturnFocus}>
      <Component ref={ref} onClick={onClick} onKeyDown={handleKeyDown} tabIndex={0} css={bubbleStyles}>
        {bubbleIcon}
        {variant === 'image' ? (
          <ImageBubble src={src} {...imageProps} />
        ) : (
          <TextBubble text={text} subText={subText} variant={variant} />
        )}
      </Component>
    </ContextMenu>
  );
};

export const Bubble = forwardRef(BubbleComponent);
Bubble.displayName = 'Bubble';
