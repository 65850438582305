import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  type LexicalNode,
  $getSelection,
  COMMAND_PRIORITY_CRITICAL,
} from 'lexical';
import {
  BeautifulMentionComponentProps,
  BeautifulMentionNode,
  BeautifulMentionsItemData,
  SerializedBeautifulMentionNode,
  $createBeautifulMentionNode,
  useBeautifulMentions,
} from 'lexical-beautiful-mentions';
import { DYNAMIC_FIELD_TRIGGER } from '../constants';

const dataLabelAttribute = 'data-dynamic-field-label';

export class DynamicFieldNode extends BeautifulMentionNode {
  __styles: React.CSSProperties;

  constructor(
    trigger: string,
    value: string,
    data?: { [p: string]: BeautifulMentionsItemData },
    key?: string,
    styles: React.CSSProperties = {}
  ) {
    super(trigger, value, data, key);
    if (data?.styles) {
      this.__styles = data.styles as React.CSSProperties;
    } else {
      this.__styles = styles;
    }
  }

  static getType() {
    return 'dynamic-field';
  }
  static clone(node: DynamicFieldNode) {
    return new DynamicFieldNode(node.__trigger, node.__value, node.__data, node.__key, node.__styles);
  }
  static importJSON(serializedNode: SerializedBeautifulMentionNode) {
    const dynamicField = new DynamicFieldNode(serializedNode.trigger, serializedNode.value, serializedNode.data);
    if (serializedNode?.data?.styles) {
      dynamicField.__styles = serializedNode.data.styles as React.CSSProperties;
    }
    return dynamicField;
  }
  exportJSON(): SerializedBeautifulMentionNode & { symbol: string; text: string } {
    const data = this.__data;
    return {
      trigger: this.__trigger,
      value: this.__value,
      symbol: this.__value,
      text: this.getTextContent(),
      ...(data ? { data: { ...data, styles: this.__styles as unknown as string } } : {}),
      type: 'dynamic-field',
      version: 1,
    };
  }
  canHaveFormat(): boolean {
    return false;
  }

  getStyle(): React.CSSProperties | null {
    return this.__styles;
  }

  createDOM(): HTMLElement {
    const dom = super.createDOM();
    dom.classList.add('dynamic-field');
    return dom;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      data: (domNode: HTMLElement) => {
        if (!domNode.classList.contains('dynamic-field')) {
          return null;
        }
        return {
          conversion: convertDynamicFieldDataElement,
          priority: COMMAND_PRIORITY_CRITICAL,
        };
      },
      span: (domNode: HTMLSpanElement) => {
        if (!domNode.getAttribute(dataLabelAttribute)) {
          return null;
        }
        return {
          conversion: convertDynamicFieldSpanElement,
          priority: COMMAND_PRIORITY_CRITICAL,
        };
      },
    };
  }

  $patchDynamicLabelStyle(newStyles: React.CSSProperties) {
    const writableNode = this.getWritable();
    writableNode.__styles = { ...writableNode.__styles, ...newStyles };
  }

  exportDOM(): DOMExportOutput {
    if (!!this.__data?.noTransform) {
      const element = document.createElement('span');
      element.setAttribute(dataLabelAttribute, `${this.__data?.label ?? ''}`);
      element.textContent = this.__value as string;
      if (this.__styles) {
        Object.assign(element.style, this.__styles);
      }
      return { element };
    }

    if (!!this.__data?.val) {
      const element = document.createElement('span');
      element.setAttribute('data-dynamic-field-value', this.__value);
      element.textContent = this.__data?.val as string;
      if (this.__styles) {
        Object.assign(element.style, this.__styles);
      }
      return { element };
    }

    const element = document.createElement('data');
    element.className = 'dynamic-field';
    element.title = `This is a placeholder, and will be replaced with the real value upon sending`;
    element.value = this.__value;
    element.textContent = this.__data?.label as string;
    if (this.__styles) {
      Object.assign(element.style, this.__styles);
    }
    return { element };
  }

  component(): React.ElementType<BeautifulMentionComponentProps> | null {
    return CustomMentionComponent;
  }
  decorate(editor: LexicalEditor, config: EditorConfig): React.ReactElement {
    const Component = super.decorate(editor, config);
    const ClonedComponent = React.cloneElement(Component, {
      data: { ...(this.__data ?? {}), styles: { ...this.getStyle(), ...(this.__styles ?? {}) }, key: this.__key },
    });
    return ClonedComponent;
  }
}

export const CustomMentionComponent = React.forwardRef<
  HTMLDivElement,
  BeautifulMentionComponentProps<{ label: string; val?: string; styles?: string; key?: string }>
>(({ trigger, value, data: myData, children, styles, ...other }, ref) => {
  const isEditable = useLexicalEditable();
  const { openMentionMenu } = useBeautifulMentions();
  const [editor] = useLexicalComposerContext();
  const [isSelected] = useLexicalNodeSelection(myData?.key ?? '');

  if (!isEditable) {
    return <span style={myData?.styles as unknown as React.CSSProperties}>{myData?.val ?? value}</span>;
  }

  return (
    <span
      onClick={() => {
        editor.update(() => {
          if (isSelected) {
            const selection = $getSelection();
            const selectedNode = selection?.getNodes()[0];
            if ($isDynamicFieldNode(selectedNode)) {
              selectedNode.remove();
            }
            openMentionMenu({ trigger: DYNAMIC_FIELD_TRIGGER });
          }
        });
      }}
      draggable={isSelected}
      className='dynamic-field'
      {...other}
      ref={ref}
      title={myData?.label}
    >
      {myData?.label}
    </span>
  );
});

export function $isDynamicFieldNode(node: LexicalNode | null | undefined): node is DynamicFieldNode {
  return node instanceof DynamicFieldNode;
}

function convertDynamicFieldDataElement(domNode: HTMLElement): DOMConversionOutput | null {
  const textContent = domNode.textContent;
  if (!(domNode instanceof HTMLDataElement)) {
    return null;
  }
  const symbol = domNode.value;

  if (textContent !== null) {
    const node = $createBeautifulMentionNode(DYNAMIC_FIELD_TRIGGER, symbol, { label: textContent });
    return {
      node,
    };
  }

  return null;
}

function convertDynamicFieldSpanElement(domNode: HTMLElement): DOMConversionOutput | null {
  const textContent = domNode.textContent;
  if (!(domNode instanceof HTMLSpanElement)) {
    return null;
  }

  const label = domNode.getAttribute(dataLabelAttribute);
  const styles = domNode.getAttribute('style');

  if (textContent !== null) {
    const node = $createBeautifulMentionNode(DYNAMIC_FIELD_TRIGGER, textContent, { label, noTransform: true, styles });
    return {
      node,
    };
  }

  return null;
}
