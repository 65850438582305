import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import type { BaseSelection, LexicalCommand, LexicalEditor, NodeKey } from 'lexical';
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  $isRangeSelection,
  $setSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  createCommand,
  DRAGSTART_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_ESCAPE_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { SpinningLoader } from '../../loader/spinning-loader';
import { Text } from '../../text';
import { $isImageNode } from '../nodes/image-node';
import ImageResizer from './image-resizer';

interface ImageProps {
  altText: string;
  nodeKey: NodeKey;
  resizable: boolean;
  src: string;
  width: 'inherit' | number;
}

interface LazyImageProps {
  altText: string;
  className: string | undefined;
  imageRef: React.RefObject<HTMLImageElement>;
  src: string;
  width: 'inherit' | number;
  onError: () => void;
}

export const RIGHT_CLICK_IMAGE_COMMAND: LexicalCommand<MouseEvent> = createCommand('RIGHT_CLICK_IMAGE_COMMAND');

const imageCache = new Map<string, { loaded: boolean; error: boolean }>();

function useImageCache(src: string, onError: () => void) {
  if (!imageCache.has(src)) {
    throw new Promise((resolve) => {
      const img = new Image();
      img.src = src;

      img.onload = () => {
        imageCache.set(src, { loaded: true, error: false });
        resolve(null);
      };

      img.onerror = () => {
        imageCache.set(src, { loaded: true, error: true });
        onError();
        resolve(null);
      };
    });
  }

  const cachedImage = imageCache.get(src);
  if (cachedImage?.error) {
    onError();
  }
}

function useImageHandlers(props: {
  editor: LexicalEditor;
  isResizing: boolean;
  isSelected: boolean;
  setSelected: (selected: boolean) => void;
  clearSelection: () => void;
  imageRef: React.RefObject<HTMLImageElement>;
}) {
  const { editor, isResizing, isSelected, setSelected, clearSelection, imageRef } = props;

  const onClick = useCallback(
    (event: MouseEvent) => {
      if (isResizing) return true;
      if (event.target === imageRef.current) {
        if (event.shiftKey) {
          setSelected(!isSelected);
        } else {
          clearSelection();
          setSelected(true);
        }
        return true;
      }
      return false;
    },
    [isResizing, isSelected, setSelected, clearSelection, imageRef]
  );

  const onRightClick = useCallback(
    (event: MouseEvent) => {
      editor.getEditorState().read(() => {
        const selection = $getSelection();
        const target = event.target as HTMLElement;
        if (target.tagName === 'IMG' && $isRangeSelection(selection) && selection.getNodes().length === 1) {
          editor.dispatchCommand(RIGHT_CLICK_IMAGE_COMMAND, event);
        }
      });
    },
    [editor]
  );

  return { onClick, onRightClick };
}

function LazyImage({ altText, className, imageRef, src, width, onError }: LazyImageProps): JSX.Element {
  useImageCache(src, onError);

  return (
    <img
      className={className}
      src={src}
      alt={altText}
      ref={imageRef}
      style={{
        display: 'block',
        width: typeof width === 'number' ? `${width}px` : width,
        height: 'auto',
      }}
      draggable='false'
      onError={onError}
    />
  );
}

function ErrorImage(): JSX.Element {
  return (
    <div className='error-image-container'>
      <Text weight='bold'>Error Loading Image...</Text>
    </div>
  );
}

function LoadingImage(): JSX.Element {
  return (
    <div className='loading-image-container'>
      <SpinningLoader />
    </div>
  );
}

export default function ImageComponent({ src, altText, nodeKey, width, resizable }: ImageProps): JSX.Element {
  const imageRef = useRef<HTMLImageElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const activeEditorRef = useRef<LexicalEditor | null>(null);
  const [editor] = useLexicalComposerContext();
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey);
  const [isResizing, setIsResizing] = useState(false);
  const [selection, setSelection] = useState<BaseSelection | null>(null);
  const [isLoadError, setIsLoadError] = useState(false);
  const isEditable = useLexicalEditable();

  const handleImageError = useCallback(() => {
    setIsLoadError(true);
  }, []);

  useEffect(() => {
    setIsLoadError(false);
  }, [src]);

  const { onClick, onRightClick } = useImageHandlers({
    editor,
    isResizing,
    isSelected,
    setSelected,
    clearSelection,
    imageRef,
  });

  const onDelete = useCallback(
    (event: KeyboardEvent) => {
      const selection = $getSelection();
      if (isSelected && $isNodeSelection(selection)) {
        event.preventDefault();
        editor.update(() => {
          selection.getNodes().forEach((node) => {
            if ($isImageNode(node)) {
              node.remove();
            }
          });
        });
      }
      return false;
    },
    [editor, isSelected]
  );

  const onEnter = useCallback(
    (event: KeyboardEvent) => {
      const selection = $getSelection();
      if (
        isSelected &&
        $isNodeSelection(selection) &&
        selection.getNodes().length === 1 &&
        buttonRef.current &&
        buttonRef.current !== document.activeElement
      ) {
        event.preventDefault();
        buttonRef.current.focus();
        return true;
      }
      return false;
    },
    [isSelected]
  );

  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      if (buttonRef.current === event.target) {
        $setSelection(null);
        editor.update(() => {
          setSelected(true);
          editor.getRootElement()?.focus();
        });
        return true;
      }
      return false;
    },
    [editor, setSelected]
  );

  const onResizeEnd = useCallback(
    (nextWidth: 'inherit' | number, nextHeight: 'inherit' | number) => {
      setTimeout(() => setIsResizing(false), 200);
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if ($isImageNode(node)) {
          node.setWidthAndHeight(nextWidth, nextHeight);
        }
      });
    },
    [editor, nodeKey]
  );

  const onResizeStart = useCallback(() => {
    setIsResizing(true);
  }, []);

  useEffect(() => {
    const rootElement = editor.getRootElement();
    const unregister = mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        setSelection(editorState.read(() => $getSelection()));
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_, activeEditor) => {
          activeEditorRef.current = activeEditor;
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(CLICK_COMMAND, onClick, COMMAND_PRIORITY_LOW),
      editor.registerCommand(RIGHT_CLICK_IMAGE_COMMAND, onClick, COMMAND_PRIORITY_LOW),
      editor.registerCommand(
        DRAGSTART_COMMAND,
        (event) => {
          if (event.target === imageRef.current) {
            event.preventDefault();
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ENTER_COMMAND, onEnter, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ESCAPE_COMMAND, onEscape, COMMAND_PRIORITY_LOW)
    );

    rootElement?.addEventListener('contextmenu', onRightClick);

    return () => {
      unregister();
      rootElement?.removeEventListener('contextmenu', onRightClick);
    };
  }, [editor, onClick, onRightClick, onDelete, onEnter, onEscape]);

  const draggable = isSelected && $isNodeSelection(selection) && !isResizing;
  const isFocused = (isSelected || isResizing) && isEditable;

  return (
    <Suspense fallback={<LoadingImage />}>
      <div draggable={draggable} className='image-container'>
        {isLoadError ? (
          <ErrorImage />
        ) : (
          <LazyImage
            className={isFocused ? `focused ${$isNodeSelection(selection) ? 'draggable' : ''}` : undefined}
            src={src}
            altText={altText}
            imageRef={imageRef}
            width={width}
            onError={handleImageError}
          />
        )}

        {resizable && $isNodeSelection(selection) && isFocused && (
          <ImageResizer editor={editor} imageRef={imageRef} onResizeStart={onResizeStart} onResizeEnd={onResizeEnd} />
        )}
      </div>
    </Suspense>
  );
}
