import { useState } from 'react';
import { Icon } from '@frontend/icons';
import { useStyles } from '../../../../use-styles';
import { Button } from '../../../button';
import { Modal, useModalControl } from '../../../modal';
import { Text } from '../../../text';

type ImageBubbleProps = {
  src: string;
  suppressPreview?: boolean;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  onClickDownload?: () => void;
  downloadImageTrackingId?: string;
};
export const ImageBubble = ({
  src,
  suppressPreview,
  onError,
  onClickDownload,
  downloadImageTrackingId,
}: ImageBubbleProps) => {
  const [error, setError] = useState(false);
  const previewModalProps = useModalControl();

  return (
    <>
      <figure {...(suppressPreview && src ? {} : previewModalProps.triggerProps)}>
        {src && !error && (
          <img
            className='img-bubble'
            src={src}
            onError={(e) => {
              setError(true);
              onError?.(e);
            }}
            css={{
              cursor: !suppressPreview ? 'pointer' : 'default',
            }}
          />
        )}
        {error && (
          <>
            <Icon name='warning-badge' color='error' />
            <Text>There was an error loading this image.</Text>
          </>
        )}
      </figure>
      {src && (
        <PreviewModal
          src={src}
          previewModalProps={previewModalProps}
          onClickDownload={onClickDownload}
          downloadImageTrackingId={downloadImageTrackingId}
        />
      )}
    </>
  );
};

type PreviewModalProps = {
  src: string;
  previewModalProps: ReturnType<typeof useModalControl>;
  onClickDownload?: () => void;
  downloadImageTrackingId?: string;
};
export const PreviewModal = ({
  src,
  previewModalProps,
  onClickDownload,
  downloadImageTrackingId,
}: PreviewModalProps) => {
  const modalStyles = useStyles('Chat', 'previewModal');

  return (
    <Modal css={modalStyles} {...previewModalProps.modalProps}>
      <div className='btn-containers'>
        {onClickDownload && (
          <Button
            onClick={onClickDownload}
            iconName='download'
            variant='secondary'
            className='download-button'
            {...(downloadImageTrackingId && { trackingId: downloadImageTrackingId })}
          />
        )}
        <Button
          size='small'
          onClick={previewModalProps.closeModal}
          className='close-button'
          variant='secondary'
          iconName='x'
        />
      </div>
      <Modal.Body>
        <img
          src={src}
          css={{
            maxWidth: '70vw',
            maxHeight: '90vh',
            padding: 0,
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
