import { css } from '@emotion/react';
import { Heading, IconButton, PrimaryButton } from '@frontend/design-system';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import type { FallbackRender } from '@sentry/react/types/errorboundary';
import { useState } from 'react';

type Props = Parameters<FallbackRender>[0];

export const ErrorBoundary = ({ error }: Props) => {
  const [showingDetails, setShowingDetails] = useState(false);

  return (
    <main
      css={css`
        margin: auto;
        margin-top: ${theme.spacing(4)};
        max-width: 400px;
        border-radius: ${theme.borderRadius.small};
        padding: ${theme.spacing(2)};
      `}
    >
      <Heading
        level={2}
        css={css`
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        An Error Occurred
      </Heading>
      <PrimaryButton onClick={() => (location.href = '/')}>Start Over</PrimaryButton>
      <IconButton
        size='small'
        label='Show Details'
        onClick={() => setShowingDetails(!showingDetails)}
        css={css`
          margin-left: auto;
          margin-top: ${theme.spacing(2)};
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <Icon name='info' />
      </IconButton>
      {showingDetails && (
        <p
          css={css`
            overflow: auto;
            padding: ${theme.spacing(2)};
            background: ${theme.colors.neutral10};
            border-radius: ${theme.borderRadius.small};
          `}
        >
          {error instanceof Error ? error?.stack : <code>Hmm, no error found</code>}
        </p>
      )}
    </main>
  );
};
