import { AlertType, usePortal, genUID, deprecated_Alerts as Alert } from '@frontend/design-system';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  CSSProperties,
} from 'react';

import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useTransition } from 'react-spring';

type AlertByTypePayload = string | Omit<AddAlertPayload, 'type'>;

type AlertContextType = {
  addAlert: (payload: AddAlertPayload) => string;
  clearAlerts: () => void;
  error: (payload: AlertByTypePayload) => string;
  info: (payload: AlertByTypePayload) => string;
  removeAlert: (id: string) => void;
  subscribe: (fn: AlertSubscriptionFn) => () => void;
  success: (payload: AlertByTypePayload) => string;
  warning: (payload: AlertByTypePayload) => string;
};

export const AlertsStateContext = createContext<AlertData[]>([]);

export const AlertsContext = createContext<AlertContextType>({} as AlertContextType);

export type AlertData = {
  id: string;
  message: string;
  type: AlertType;
};

export type AddAlertPayload = Omit<AlertData, 'id'> & {
  autoDismissAfter?: number;
  id?: string;
};

type AlertSubscriptionPayload = {
  action: 'add' | 'clear' | 'remove';
  ids: string[];
};
export type AlertSubscriptionFn = (payload: AlertSubscriptionPayload) => void;

const DEFAULT_DELAY = 3500;

/**
 * @deprecated use AlertsProvider from @frontend/design-system instead
 */
export const AlertsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertData[]>([]);
  const timersRef = useRef<Map<string, number>>(new Map());
  // handling for hooking into removals to sync elsewhere as needed (eg redux store)
  const subscribersRef = useRef<{ [key: string]: AlertSubscriptionFn }>({});
  const alertsListUid = genUID();

  const unsubscribe = (id: string) => () => {
    delete subscribersRef.current[id];
  };

  const publish = (payload: AlertSubscriptionPayload) => {
    Object.values(subscribersRef.current).forEach((fn) => fn(payload));
  };

  const clearTimer = (id: string) => {
    if (timersRef.current.has(id)) {
      window.clearTimeout(timersRef.current.get(id));
      timersRef.current.delete(id);
    }
  };

  const removeAlert = useCallback((id: string) => {
    clearTimer(id);
    setAlerts((curAlerts) => curAlerts.filter((alert) => alert.id !== id));
    publish({ action: 'remove', ids: [id] });
  }, []);

  const startTimer = (id: string, delay: number) => {
    clearTimer(id);
    timersRef.current.set(
      id,
      window.setTimeout(() => {
        removeAlert(id);
      }, delay)
    );
  };

  const contextValue = useMemo(() => {
    const addAlert = (payload: AddAlertPayload) => {
      const { autoDismissAfter = DEFAULT_DELAY, id, message, type } = payload;
      const uid = id ?? genUID();
      setAlerts((curAlerts) => [...curAlerts, { id: uid, message, type }]);
      if (autoDismissAfter > 0) startTimer(uid, autoDismissAfter);
      publish({ action: 'add', ids: [uid] });
      return uid;
    };

    const alertByType = (type: AlertType) => (payload: AlertByTypePayload) => {
      if (typeof payload === 'string') {
        return addAlert({ type, message: payload });
      } else {
        return addAlert({ ...payload, type });
      }
    };

    return {
      addAlert,
      clearAlerts: () => {
        setAlerts((curAlerts) => {
          publish({ action: 'clear', ids: curAlerts.map((alert) => alert.id) });
          return [];
        });
      },
      error: alertByType('error'),
      info: alertByType('info'),
      removeAlert,
      subscribe: (fn: AlertSubscriptionFn) => {
        const uid = genUID();
        subscribersRef.current[uid] = fn;
        return unsubscribe(uid);
      },
      success: alertByType('success'),
      warning: alertByType('warning'),
    };
  }, [setAlerts]);

  // clear all timers on unmount
  useEffect(() => {
    return () => {
      timersRef.current.clear();
    };
  }, []);

  const { Portal } = usePortal({
    attributes: { id: `alertsList-${alertsListUid}` },
  });

  return (
    <AlertsContext.Provider value={contextValue}>
      {children}
      <AlertsStateContext.Provider value={alerts}>
        <Portal>
          <AlertsList />
        </Portal>
      </AlertsStateContext.Provider>
    </AlertsContext.Provider>
  );
};

/**
 * @deprecated use ***useAlert*** from `@frontend/design-system` instead.
 */
export const useAlert = () => {
  const context = useContext(AlertsContext);
  if (typeof context === 'undefined') {
    throw new Error('useAlert must be used inside an AlertsProvider');
  }
  return {
    error: context.error,
    info: context.info,
    success: context.success,
    warning: context.warning,
  };
};

type NextFunctionType = (stylesObject: CSSProperties) => void;

export const AlertsList = () => {
  const [refMap] = useState(() => new WeakMap());
  const alerts = useContext(AlertsStateContext);
  const { removeAlert } = useContext(AlertsContext);

  const transitions = useTransition(
    alerts,
    // @ts-ignore hard to tell if this error is on weave's or react-spring's end
    (item) => (item ? item.id : false),
    {
      from: { opacity: 0, transform: 'translateX(100%)' },
      enter: (item) => async (next: NextFunctionType) =>
        // the 3 `awaits` below are necessary for the correct animations
        await next({
          opacity: 1,
          transform: 'translateX(0%)',
          height: refMap.get(item)?.offsetHeight,
          marginBottom: 8,
        }),
      leave: () => async (next: NextFunctionType) => {
        await next({ opacity: 0, transform: 'translateX(100%)' });
        await next({ height: 0, marginBottom: 0 });
      },
      config: { tension: 125, friction: 20, precision: 0.1 },
    }
  );

  return (
    <div
      css={css`
        width: 100%;
        max-width: ${theme.spacing(46)};
        height: 100%;
        padding: ${theme.spacing(2)};
        pointer-events: none;
        position: fixed;
        top: 0;
        right: 0;
        z-index: ${theme.zIndex.alerts};
      `}
    >
      {transitions.map(({ key, item, props }) => {
        const { message, type, ...rest } = item;
        return (
          <Alert
            key={key}
            {...rest}
            type={type}
            onClick={removeAlert}
            style={props}
            ref={(ref: any) => ref && refMap.set(item, ref)}
          >
            {message}
          </Alert>
        );
      })}
    </div>
  );
};
