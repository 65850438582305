import { FormEvent, useEffect, forwardRef, useMemo } from 'react';
import { css } from '@emotion/react';
import { isFunction } from 'lodash-es';
import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { DropdownField, IconButton, NumberField, useFormField, Text } from '..';
import { usePreviousValue, useThemeValues } from '../../hooks';
import { CaretLeftIcon, CaretRightIcon } from '../../icon';
import { defaultRowsPerPageOptions } from '../table/table.component';
import { PaginationButton } from './pagination-button.component';
import { usePagination } from './use-pagination';

export type RowsPerPageOption = number[];

export type PaginationProps = {
  boundaries?: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
  nextHoverText?: string;
  page: number;
  siblings?: number;
  prevHoverText?: string;
  showGoToPageField?: boolean;
  showNumRowSelector?: boolean;
  goToPageLabel?: string;
  rowsPerPageLabel?: string;
  pageChangeLabel?: string;
  defaultRowsPerPage?: number;
  rowsPerPageOptions?: RowsPerPageOption;
  disablePagination?: boolean;
  pageByPageSelector?: boolean;
  onNumRowsChange?: (num: number) => void;
  globalTrackingId?: string;
} & HasNumOfPagesProps;

type HasNumOfPagesProps =
  | {
      total?: undefined;
      handleChange: (prevOrNext: 'next' | 'prev') => void;
    }
  | {
      total: number;
      handleChange: (selectedPage: number) => void;
    };

const trackingIds = {
  goToPage: 'num-paginationgotopage',
  pageSelector: 'drp-paginationpageselector',
  nextPage: 'btn-paginationnextpage',
  previousPage: 'btn-paginationpreviouspage',
  pageNumber: 'btn-paginationpagenumber',
} as const;

type TrackingIds = typeof trackingIds;
type PaginationTrackingIds = { -readonly [K in keyof TrackingIds]: TrackingIds[K] | undefined };
export type PaginationTrackingId = Readonly<PaginationTrackingIds>;

const paginationTrackingIdGenerator = (globalId?: string) => {
  const delimiter = globalId?.endsWith('-') ? '' : '-';

  const trackIds = Object.keys(trackingIds).reduce((acc, key) => {
    const trackingKey = key;
    acc[trackingKey] = globalId ? `${globalId}${delimiter}${trackingIds[trackingKey]}` : undefined;
    return acc;
  }, {} as PaginationTrackingIds);
  return trackIds;
};

// eslint-disable-next-line react/display-name
export const Pagination = forwardRef<HTMLDivElement, PaginationProps>(
  (
    {
      boundaries = 3,
      handleChange,
      hasNext = false,
      hasPrevious = false,
      nextHoverText = 'Next',
      page,
      prevHoverText = 'Previous',
      showGoToPageField = true,
      siblings = 1,
      total,
      onNumRowsChange,
      showNumRowSelector = true,
      defaultRowsPerPage,
      goToPageLabel = 'Go to page',
      rowsPerPageLabel = 'Rows per page',
      pageChangeLabel,
      rowsPerPageOptions,
      disablePagination,
      pageByPageSelector,
      globalTrackingId,
      ...rest
    }: PaginationProps,
    ref
  ) => {
    const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });
    const { spacing } = useThemeValues();
    const hasEndRangeValue = !!total && !isMobile;
    const showForm = hasEndRangeValue && showGoToPageField;
    const paginationTrackingIds = useMemo(() => paginationTrackingIdGenerator(globalTrackingId), [globalTrackingId]);

    const jumpToPageFieldProps = useFormField(
      {
        allowDecimal: false,
        max: total,
        min: 1,
        type: 'number',
        value: page.toString(),
        validateOnChange: pageByPageSelector ? true : false,
      },
      [page]
    );

    const { range, setPage, next, previous, active, skipBackward, skipForward } = usePagination({
      page,
      siblings,
      total: total! > 0 ? total : undefined,
      onChange: (pageNumber) => {
        if (total !== undefined) {
          handleChange(pageNumber);
        } else {
          const prevOrNext = pageNumber > active! ? 'next' : 'prev';
          handleChange(prevOrNext);
        }
      },
      initialPage: page,
      boundaries,
    });

    const handleSubmit = (e: FormEvent) => {
      e.preventDefault();
      if (total) {
        if (parseInt(jumpToPageFieldProps.value) > total!) {
          return;
        }
        handleChange(parseInt(jumpToPageFieldProps.value));
      }
    };

    return pageByPageSelector ? (
      <div
        ref={ref}
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          width: 100%;
          padding: ${spacing(1)};
        `}
        {...rest}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          <IconButton
            disabled={page === 1}
            label={prevHoverText}
            onClick={previous}
            showLabelOnHover={hasEndRangeValue}
            style={{ display: 'inline-flex' }}
          >
            <CaretLeftIcon />
          </IconButton>
          <Text
            css={css`
              margin: 0;
              margin-right: ${spacing(1)};
              margin-top: ${spacing(1)};
            `}
          >
            {pageChangeLabel ?? 'Page'}
          </Text>
          <form onSubmit={handleSubmit} style={{ display: 'inline', margin: spacing(0, 1) }}>
            <NumberField
              disabled={range.length <= 1 || disablePagination}
              name='page-jump'
              label=''
              {...jumpToPageFieldProps}
              helperText='OKK'
              hideErrorText
              css={numFieldStyles}
            />
          </form>
          {total && (
            <Text
              css={css`
                margin: 0;
                margin-left: ${spacing(1)};
                margin-top: ${spacing(1)};
              `}
            >
              {'of'} {total}
            </Text>
          )}
          <IconButton
            disabled={active === total}
            label={nextHoverText}
            onClick={next}
            showLabelOnHover={hasEndRangeValue}
            style={{
              display: 'inline-flex',
              marginLeft: hasEndRangeValue ? spacing(1.5) : 0,
            }}
          >
            <CaretRightIcon />
          </IconButton>
        </div>
        <Text size='small' color='error' style={{ marginTop: spacing(0.5) }}>
          {jumpToPageFieldProps.error}
        </Text>
      </div>
    ) : (
      <div
        ref={ref}
        css={css`
          display: flex;
          align-items: center;
          justify-content: ${showNumRowSelector ? 'space-between' : 'flex-end'};
          width: 100%;
          padding: ${spacing(1)};
        `}
      >
        {showNumRowSelector && (
          <PageSelector
            isMobile={isMobile}
            trackingId={paginationTrackingIds.pageSelector}
            defaultRowsPerPage={defaultRowsPerPage}
            onSelectionChanged={onNumRowsChange}
            rowsPerPageLabel={rowsPerPageLabel}
            rowsPerPageOptions={rowsPerPageOptions}
            disablePagination={disablePagination}
          />
        )}
        <div
          css={css`
            align-items: center;
            display: flex;
          `}
          {...rest}
        >
          {/* IconButtons always render, but get displayed differently based on component configuration */}
          {!hasEndRangeValue && (
            <Text
              css={css`
                margin: 0;
                margin-right: ${spacing(1)};
              `}
            >
              {pageChangeLabel ?? 'Page'} {page}
            </Text>
          )}
          <IconButton
            disabled={page === 1 || !hasPrevious}
            label={prevHoverText}
            onClick={previous}
            showLabelOnHover={hasEndRangeValue}
            trackingId={paginationTrackingIds.previousPage}
            style={{ display: 'inline-flex' }}
          >
            <CaretLeftIcon />
          </IconButton>
          {/* Only show the range if the end value is known */}
          {hasEndRangeValue && (
            <>
              {range.map((rangeValue, index) => (
                <PaginationButton
                  trackingId={paginationTrackingIds.pageNumber}
                  key={index}
                  page={page}
                  rangeValue={rangeValue}
                  setPage={setPage}
                  shouldShowSkipToEnd={index > range.length / 2 && rangeValue === 'dots'}
                  shouldShowSkipToStart={index < range.length / 2 && rangeValue === 'dots'}
                  skipBackward={skipBackward}
                  skipForward={skipForward}
                />
              ))}
            </>
          )}
          {/* IconButtons always render, but get displayed differently based on component configuration */}
          <IconButton
            disabled={active === total || !hasNext}
            label={nextHoverText}
            onClick={next}
            showLabelOnHover={hasEndRangeValue}
            trackingId={paginationTrackingIds.nextPage}
            style={{
              display: 'inline-flex',
              marginLeft: hasEndRangeValue ? spacing(1.5) : 0,
            }}
          >
            <CaretRightIcon />
          </IconButton>
          {showForm && (
            <form onSubmit={handleSubmit} style={{ display: 'inline', margin: spacing(0, 1) }}>
              <NumberField
                disabled={range.length <= 1 || disablePagination}
                name='page-jump'
                data-trackingid={paginationTrackingIds.goToPage}
                label={goToPageLabel}
                {...jumpToPageFieldProps}
                // 105 is the smallest width that doesn't cause the label to wrap
                css={{ width: 105 }}
              />
            </form>
          )}
        </div>
      </div>
    );
  }
);

type PageSelectorProps = {
  defaultRowsPerPage?: number;
  onSelectionChanged?: (num: number) => void;
  rowsPerPageLabel: string;
  rowsPerPageOptions?: RowsPerPageOption;
  disablePagination?: boolean;
  isMobile?: boolean;
  trackingId?: string;
};

const PageSelector = ({
  defaultRowsPerPage,
  onSelectionChanged,
  rowsPerPageLabel,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  disablePagination,
  trackingId,
  isMobile,
}: PageSelectorProps) => {
  const { spacing } = useThemeValues();
  const dropdownProps = useFormField({
    type: 'dropdown',
    value: defaultRowsPerPage?.toString() ?? rowsPerPageOptions[0]?.toString(),
  });
  const [previousValue, currentValue] = usePreviousValue(dropdownProps.value);

  useEffect(() => {
    if (previousValue !== currentValue && isFunction(onSelectionChanged)) {
      onSelectionChanged(+dropdownProps.value as number);
    }
  }, [dropdownProps.value]);

  return (
    <section
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      {!isMobile && (
        <Text
          css={css`
            margin: 0;
            margin-right: ${spacing(2)};
          `}
        >
          {rowsPerPageLabel}
        </Text>
      )}
      <DropdownField
        data-trackingid={trackingId}
        disabled={!!disablePagination}
        {...dropdownProps}
        label=''
        name='rows-per-page'
      >
        {[...rowsPerPageOptions].reverse().map((option) => (
          <DropdownField.Option key={option} value={String(option)}>
            {option}
          </DropdownField.Option>
        ))}
      </DropdownField>
    </section>
  );
};

const numFieldStyles = css`
  padding: 0;
  width: 50px;

  input {
    text-align: center;
  }
`;
