import { MouseEventHandler, useMemo } from 'react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, TagChipProps } from '../../../chip';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu } from '../../../popover';
import { Text } from '../../../text';
import { useChatBubbleContext } from '../../chat';
import { BubbleTag, BubbleTagAction } from '../../types';

type RightElementProps = Pick<TagChipProps<MouseEventHandler<HTMLButtonElement>>, 'rightElement'>;
type TagsProps = BubbleTag & {
  actions?: BubbleTagAction[];
  leftIcon?: React.ReactNode;
  actionHeading?: string;
  children?: React.ReactNode;
};
export const Tag = ({
  color,
  onClick,
  onRemoveClick,
  trackingId,
  label,
  actions,
  leftIcon,
  actionHeading,
  children,
}: TagsProps) => {
  const { getItemProps, getTriggerProps, getMenuProps, isOpen } = usePopoverMenu({
    placement: 'bottom-start',
  });

  const { direction } = useChatBubbleContext();
  const hasActions = !!actions?.length;

  const rightElementProps = useMemo<RightElementProps>(
    () =>
      hasActions
        ? {
            rightElement: (
              <Icon
                name='caret-down-tiny'
                css={{
                  transition: 'transform 0.2s ease-in-out',
                  transform: `rotate(${isOpen ? 180 : 0}deg)`,
                }}
              />
            ),
          }
        : {},
    [hasActions, Icon, isOpen]
  );

  return (
    <>
      <div
        className='tag-buttons'
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: direction === 'inbound' ? 'flex-start' : 'flex-end',
        }}
      >
        <Chip.Tag
          color={color}
          onClick={hasActions ? getTriggerProps().onClick : onClick}
          isRemovable={!hasActions}
          onRemoveClick={hasActions ? undefined : onRemoveClick}
          leftElement={leftIcon}
          {...rightElementProps}
          trackingId={trackingId}
          ref={getTriggerProps().ref}
        >
          {label}
        </Chip.Tag>
        {children}
      </div>
      {actions && (
        <PopoverMenu {...getMenuProps()}>
          {actionHeading && <Text css={{ padding: theme.spacing(1, 2) }}>{actionHeading}</Text>}
          {actions.map(({ label, onClick, icon, trackingId, css }, index) => (
            <PopoverMenuItem
              key={`chat-item-more-action-${trackingId ?? index}`}
              {...getItemProps({
                index,
                onClick,
              })}
              css={{
                ...css,
                cursor: 'pointer',
              }}
              trackingId={trackingId}
            >
              {icon}
              {label}
            </PopoverMenuItem>
          ))}
        </PopoverMenu>
      )}
    </>
  );
};
Tag.displayName = 'ChatTag';
