export const getBaseFontSize = (): number => {
  if (typeof window !== 'undefined') {
    const sizeString = window.getComputedStyle(document.documentElement).getPropertyValue('font-size').match(/\d+/) ?? [
      '',
    ];
    return +sizeString?.[0] || 16;
  }
  return 16;
};

export const fontSize = (size: number) => `${size / getBaseFontSize()}rem`;
