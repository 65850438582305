import { ReactElement } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Icon } from '@frontend/icons';
import { sentenceCase } from '@frontend/string';
import { theme } from '@frontend/theme';
import { WarningBadgeIcon } from '../../icon';
import { Text } from '../text';
import { useTooltip } from '../tooltip';
import { useChatBubbleContext } from './chat';
import { easeInOutVariants } from './chat-theme';

type MetaInfoProps = {
  senderName?: string;
  timestampText?: string;
  error?: string;
  isHovering: boolean;
  StatusBar?: ReactElement;
  errorTooltip?: string;
};
export const MetaInfo = ({ senderName, timestampText, error, isHovering, StatusBar, errorTooltip }: MetaInfoProps) => {
  return (
    <span className='meta-info-container'>
      <AnimatePresence>
        <motion.span
          className='metadata-container'
          initial='hidden'
          animate={isHovering ? 'visible' : 'hidden'}
          variants={easeInOutVariants}
        >
          {senderName && (
            <Text size='small' className='sender-name' color='light'>
              {senderName}
            </Text>
          )}
          <Text className='timestamp' as='time' size='small' color='light'>
            {timestampText}
          </Text>
        </motion.span>
      </AnimatePresence>
      {error && !StatusBar && (
        <DefaultErrorStatusComponent errorText={error} iconOnly={isHovering} errorTooltip={errorTooltip} />
      )}
      {!!StatusBar && <span className='status-container'>{StatusBar}</span>}
    </span>
  );
};

type DefaultErrorStatusComponentProps = {
  errorText: string;
  iconOnly: boolean;
  errorTooltip?: string;
};
const DefaultErrorStatusComponent = ({ errorText, iconOnly, errorTooltip }: DefaultErrorStatusComponentProps) => {
  if (iconOnly) return <Icon name='remove' size={16} color='error' />;
  const { direction } = useChatBubbleContext();

  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: direction === 'inbound' ? 'top-end' : 'top-start',
    trigger: 'hover',
  });
  return (
    <div
      css={{
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
      }}
    >
      <WarningBadgeIcon size={16} color='error' />
      <Text color='error' size='small' {...triggerProps}>
        {errorText}
      </Text>
      {errorTooltip && <Tooltip {...tooltipProps}>{sentenceCase(errorTooltip)}</Tooltip>}
    </div>
  );
};
