import React from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button } from '../../button';
import { ColorPicker } from '../../color-picker/color-picker';
import { PopoverDialog, usePopoverDialog } from '../../popover/dialog';
import { ActionDropdownChip } from '../atoms';

type HighlightColorActionProps = Pick<
  React.ComponentProps<typeof ColorPicker>,
  'color' | 'onChange' | 'palette' | 'imageSrc' | 'shouldApply'
> &
  Pick<React.ComponentProps<typeof ActionDropdownChip>, 'showBoxShadow' | 'compact' | 'css'> & {
    onReset?: () => void;
  };

export const HighlightColorAction = ({
  color = undefined,
  showBoxShadow = true,
  compact = true,
  onReset,
  css: actionStyle,
  ...rest
}: HighlightColorActionProps) => {
  const { getTriggerProps, getDialogProps, isOpen, close } = usePopoverDialog<HTMLButtonElement>({
    placement: 'bottom-start',
  });

  return (
    <>
      <ActionDropdownChip
        compact={compact}
        {...getTriggerProps()}
        showBoxShadow={showBoxShadow}
        isActive={isOpen || !!color}
        isOpen={isOpen}
        css={actionStyle}
      >
        <Icon name='highlight-small' />
      </ActionDropdownChip>
      <PopoverDialog
        css={css`
          border-radius: ${theme.borderRadius.medium};
        `}
        {...getDialogProps()}
        returnFocus={false}
      >
        <Button
          disabled={!color}
          onClick={onReset}
          variant='secondary'
          css={css`
            width: 100%;
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          Clear Highlight
        </Button>
        <ColorPicker syncColor={color} onClick={close} {...rest} />
      </PopoverDialog>
    </>
  );
};
