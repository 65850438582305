import React, { ReactNode, forwardRef, useImperativeHandle, useRef } from 'react';
import { NakedButton } from '../../naked';
import { useStyles } from '../../use-styles';
import { Placement } from '../hint';
import { useTooltip } from '../tooltip/use-tooltip';

interface PositionProps {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement>, PositionProps {
  /**
   * If `true`, the button is grayed out and unclickable.
   */
  disabled?: boolean;
  /**
   * If `true`, shadows are applied to the button, and the button has `absolute` positioning. Use `top`, `right`, `left`,
   * and `bottom` to position the button.
   */
  floating?: boolean;
  size?: 'medium' | 'large';
  trackingId?: string;
  label?: ReactNode;
  hoverLabelPlacement?: Placement;
}

// eslint-disable-next-line react/display-name
export const ActionButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, disabled, floating = false, onClick, size = 'medium', label, hoverLabelPlacement, ...props }, ref) => {
    const { top, right, bottom, left, ...rest } = props;
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const { Tooltip, tooltipProps, triggerProps } = useTooltip({
      placement: hoverLabelPlacement,
    });

    /**
     * Simply yields the buttonRef to the parent component.
     * Doing this allows us to merge refs with a predictable API.
     */
    useImperativeHandle(ref, () => buttonRef.current!);

    const styles = useStyles('ActionButton', {
      floating,
      size,
      position: {
        top,
        right,
        bottom,
        left,
      },
    });
    return (
      <NakedButton
        css={styles}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        {...rest}
        ref={(node) => {
          /** Merge the forwarded ref with the tooltip ref */
          buttonRef.current = node;
          triggerProps.ref(node);
        }}
      >
        <>{children}</>
        {label && <Tooltip {...tooltipProps}>{label}</Tooltip>}
      </NakedButton>
    );
  }
);
