import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $isLinkNode } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isAtNodeEnd } from '@lexical/selection';
import { mergeRegister } from '@lexical/utils';
import { $getSelection, $isRangeSelection, RangeSelection, SELECTION_CHANGE_COMMAND } from 'lexical';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { LinkIconSmall } from '../../../../icon';
import { NakedButton } from '../../../../naked';
import { Button } from '../../../button';
import { TextField, useFormField } from '../../../forms';
import { PopoverDialog } from '../../../popover';
import { usePopoverDialog } from '../../../popover';
import { useTooltip } from '../../../tooltip';
import { ActionPressedChip } from '../../atoms';

interface SelectionState {
  isEditing: boolean;
  isLink: boolean;
  selectedText: string;
  anchor: number;
  focus: number;
  url: string;
}

export const Link = () => {
  const [editor] = useLexicalComposerContext();
  const [selectionState, setSelectionState] = useState<SelectionState>({
    isEditing: false,
    isLink: false,
    selectedText: '',
    anchor: 0,
    focus: 0,
    url: '',
  });

  const { getTriggerProps, isOpen, getDialogProps, close } = usePopoverDialog<HTMLButtonElement | HTMLAnchorElement>({
    placement: 'bottom-start',
    middlewareOptions: {
      offset: {
        crossAxis: 0,
        mainAxis: 12,
      },
    },
  });

  const getSelectedNode = useCallback((selection: RangeSelection) => {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();

    if (anchorNode === focusNode) {
      return anchorNode;
    }

    return selection.isBackward()
      ? $isAtNodeEnd(focus)
        ? anchorNode
        : focusNode
      : $isAtNodeEnd(anchor)
      ? focusNode
      : anchorNode;
  }, []);

  const updateLinkState = useCallback(() => {
    const selection = $getSelection();

    if (!$isRangeSelection(selection)) {
      setSelectionState((prev) => ({ ...prev, isEditing: false, isLink: false }));
      return;
    }

    const node = getSelectedNode(selection);
    const parent = node.getParent();
    const selectedText = selection.getTextContent();
    const anchor = selection.anchor.offset;
    const focus = selection.focus.offset;

    let newState: Partial<SelectionState> = {
      selectedText,
      isEditing: false,
      isLink: false,
      url: '',
      anchor,
      focus,
    };

    if ($isLinkNode(parent)) {
      newState = { ...newState, url: parent.getURL(), isEditing: true, isLink: true };
    } else if ($isLinkNode(node)) {
      newState = { ...newState, url: node.getURL(), isEditing: true, isLink: true };
    } else if (selectedText.length > 0) {
      newState = { ...newState, isEditing: true };
    }

    setSelectionState((prev) => ({ ...prev, ...newState }));
  }, [getSelectedNode]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkState();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkState();
          return true;
        },
        1
      )
    );
  }, [editor, updateLinkState]);

  return (
    <>
      <ActionPressedChip
        showBoxShadow={true}
        isActive={isOpen || selectionState.isLink}
        compact={true}
        disabled={!selectionState.isEditing}
        {...getTriggerProps()}
      >
        <LinkIconSmall />
      </ActionPressedChip>
      <PopoverDialog {...getDialogProps()} returnFocus={false} css={popoverStyles}>
        {selectionState.isEditing && <LinkDialog initialState={selectionState} isOpen={isOpen} close={close} />}
      </PopoverDialog>
    </>
  );
};

const popoverStyles = css`
  border-radius: ${theme.borderRadius.medium};
  display: flex;
  alignt-items: flex-start;
  gap: ${theme.spacing(1.5)};
`;

const buttonGroupStyles = css`
  display: flex;
  align-items: center;
  align-self: start;
  gap: ${theme.spacing(1.5)};
`;

const textFieldStyle = css`
  max-width: 200px;
`;

interface LinkDialogProps {
  initialState: SelectionState;
  isOpen: boolean;
  close: () => void;
}

export const LinkDialog = ({ initialState, isOpen, close }: LinkDialogProps) => {
  const [editor] = useLexicalComposerContext();
  const { Tooltip, triggerProps, tooltipProps, setOpen } = useTooltip();
  const [hasError, setHasError] = useState(false);

  const textField = useFormField(
    {
      type: 'text',
      value: initialState.selectedText,
      required: true,
    },
    [isOpen]
  );

  const linkField = useFormField(
    {
      type: 'text',
      value: initialState.url,
    },
    [isOpen]
  );

  const validateUrl = useCallback((url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }, []);

  const insertLink = useCallback(() => {
    if (!validateUrl(linkField.value)) {
      setHasError(true);
      setOpen(true);
      return;
    }

    if (textField.error) return;

    editor.update(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;

      selection.anchor.offset = initialState.anchor;
      selection.focus.offset = initialState.focus;

      selection.insertText('');

      const node = selection.anchor.getNode();
      const offset = selection.anchor.offset;

      selection.insertText(textField.value);

      selection.anchor.set(node.getKey(), offset, 'text');
      selection.focus.set(node.getKey(), offset + textField.value.length, 'text');

      editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkField.value);
    });

    close();
  }, [editor, linkField.value, textField.value, textField.error, initialState, close, validateUrl]);

  const removeLink = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;

      selection.anchor.offset = initialState.anchor;
      selection.focus.offset = initialState.focus;

      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    });

    close();
  }, [editor, close, initialState]);

  return (
    <>
      <TextField css={textFieldStyle} label='Text' {...textField} name='link-text' />
      <TextField
        label='Link'
        {...linkField}
        onChange={(e) => {
          setHasError(false);
          linkField.onChange(e);
        }}
        css={[
          textFieldStyle,
          hasError &&
            css`
              padding-right: ${theme.spacing(1)};
            `,
        ]}
        name='link-link'
        placeholder='https://example.com/'
        endAdornment={
          hasError ? (
            <>
              <NakedButton
                css={css`
                  display: flex;
                  align-items: center;
                `}
                {...triggerProps}
              >
                <Icon name='info' color='warn' />
              </NakedButton>
              <Tooltip {...tooltipProps}>The link specified appears invalid</Tooltip>
            </>
          ) : undefined
        }
      />
      <div css={buttonGroupStyles}>
        <Button size='large' onClick={insertLink}>
          Save
        </Button>
        <Button disabled={!initialState.isLink} onClick={removeLink} variant='secondary' iconName='unlink-small' />
      </div>
    </>
  );
};
