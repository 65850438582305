import { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { VerifyAuthResponse } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { authnClient } from '@frontend/auth';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, FormRow } from '@frontend/design-system';

interface Props {
  onError: (value: Error) => void;
  onSuccess: (res: VerifyAuthResponse) => void;
}

export const WebauthnLoginComponent = ({ onError, onSuccess }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('SignIn');

  const handleBeginWebauthnLogin = async () => {
    authnClient.changeAuthMethod('legacy');
    return authnClient.signIn({ useWebauthn: true }).then(onSuccess).catch(onError);
  };

  //autofocus on the username field
  useEffect(() => {
    if (buttonRef?.current) {
      setTimeout(() => {
        buttonRef?.current?.focus();
      }, 300);
    }
  }, [buttonRef]);

  return (
    <div>
      <FormRow
        css={css`
          text-align: initial;
          margin: ${theme.spacing(4, 0)};
        `}
      >
        <Button
          ref={buttonRef}
          variant='primary'
          onClick={handleBeginWebauthnLogin}
          size='large'
          type='submit'
          css={{ width: '100%' }}
        >
          {t('Login using Passkey')}
        </Button>
      </FormRow>
    </div>
  );
};
