import { forwardRef, ReactNode, type ComponentProps } from 'react';
import { PhoneInput } from '../../atoms';
import type { BasicFormFieldProps, ExtraFieldProps, FieldActionProps } from '../../layouts';
import { FieldLayoutWithAction } from '../../layouts';

type Props = BasicFormFieldProps<'phone'> &
  ExtraFieldProps &
  FieldActionProps & {
    autoFocus?: boolean;
    endAdornment?: ReactNode;
  };

export const PhoneField = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <FieldLayoutWithAction
    {...props}
    //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
    field={PhoneInput as ComponentProps<typeof FieldLayoutWithAction>['field']}
    ref={ref}
  />
));
