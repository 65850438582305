import {
  addDays,
  addMonths,
  compareAsc,
  differenceInDays,
  endOfMonth,
  endOfWeek,
  format,
  getDate,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isSameDay,
  parse,
  setMonth,
  setYear,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { isString } from 'lodash-es';
import { PickRequired } from '@frontend/types';
import type { CalendarDayType, CalendarMonthType, FocusedDate } from '../../calendar-types';
import { Actions, CalendarBoundaries } from './calendar-actions';

type DateValue = {
  isRange?: false;
  value?: string;
};

type DateRange = {
  isRange?: true;
  value?: string[];
};

type CalendarData = DateValue | DateRange;

export type DayNameFormat = 'one' | 'two' | 'three' | 'full';

export type UseCalendarProps = CalendarData & {
  today?: string;
  autoFocusDay?: boolean;
  blackoutDates?: string[];
  dateFormat?: string;
  dayNameFormat?: DayNameFormat;
  focusedRange?: 'min' | 'max' | undefined;
  maxDate?: string;
  minDate?: string;
  monthsInView?: number;
  onEsc?: () => void;
  onSelect?: (value: string | string[]) => void;
};

type GetDayProps = CalendarBoundaries & {
  start: Date;
  month: number;
  monthsIndex: number;
  week: number;
};

// The date format used for calendar state,
// this gets added to dom elements as the date
export const INTERNAL_DATE_FORMAT = 'yyyy-M-d';

const parseFormats = [
  { format: INTERNAL_DATE_FORMAT, pattern: /^\d{4}-\d{1,2}-\d{1,2}$/ },
  { format: 'M/d/yy', pattern: /^\d{1,2}\/\d{1,2}\/\d{2}$/ },
  { format: 'M/d/yyyy', pattern: /^\d{1,2}\/\d{1,2}\/\d{4}$/ },
];
const stripTime = (date: string) => date.replace(/(T.*)/, '');
const matchFormat = (date: string) => parseFormats.find(({ pattern }) => pattern.test(date))?.format;
/**
 * Create a new day object from an internal formatted string or an object with day, month,
 * year properties. Useful for date-fns.
 */
export const createDateObject = (date: string | PickRequired<CalendarDayType, 'day' | 'month' | 'year'>) => {
  if (typeof date === 'string') {
    if (date) {
      const dateOnly = stripTime(date);
      const format = matchFormat(dateOnly);
      if (format) return parse(dateOnly, format, new Date());
      console.error(
        `Invalid date format provided: ${date}. Format should be one of ${parseFormats
          .map(({ format }) => format)
          .join(', ')}.`
      );
    }
    return new Date();
  } else {
    return new Date(date.year, date.month, date.day);
  }
};

/**
 * For creating a focus date object from a string or object
 *
 * @param inputDate String can be 'yyyy-M-d', 'yyyy-MM-dd' or object: { day: num, month:
 *   num, year: num }
 */
export const createFocusedDate = (inputDate: string | PickRequired<CalendarDayType, 'day' | 'month' | 'year'>) => {
  if (typeof inputDate === 'string') {
    const date = createDateObject(inputDate);
    return {
      date: inputDate,
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear(),
    };
  } else {
    const { day, month, year } = inputDate;
    return {
      date: `${year}-${month + 1}-${day}`,
      day,
      month,
      year,
    };
  }
};

export const createFocusedMonth = (currentFocusedDate: string, month: string) => {
  const focusMonth = setMonth(parseInternalDate(currentFocusedDate), Number(month));
  const newFocusedDate = format(focusMonth, INTERNAL_DATE_FORMAT);
  return {
    date: newFocusedDate,
    day: focusMonth.getDate(),
    month: focusMonth.getMonth(),
    year: focusMonth.getFullYear(),
  };
};

export const createFocusedYear = (currentFocusedDate: string, year: string) => {
  const focusYear = setYear(parseInternalDate(currentFocusedDate), Number(year));
  const newFocusedDate = format(focusYear, INTERNAL_DATE_FORMAT);
  return {
    date: newFocusedDate,
    day: focusYear.getDate(),
    month: focusYear.getMonth(),
    year: focusYear.getFullYear(),
  };
};

/**
 * Takes an input date format and normalizes it to what is used with date-fns, Formats can
 * consist of days, months or years. Ex: 'yyyy/mm/dd'
 */
export const normalizeDateFormat = (format: string) => {
  const map = {
    m: 'M',
    M: 'M',
    d: 'd',
    D: 'd',
    y: 'y',
    Y: 'y',
  };

  // could use replaceAll for M, but it isn't well supported yet
  return format.split('').reduce((s, char) => `${s}${map[char] ?? char}`, '');
};

/** Convert the external date format to what useCalendar uses internally. */
export const convertToInternalFormat = (dateString: string, dateFormat: string) => {
  try {
    return format(parse(dateString, normalizeDateFormat(dateFormat), new Date()), INTERNAL_DATE_FORMAT);
  } catch (error) {
    return '';
  }
};

/** Converts a date string based used in the calendar to and external format */
export const convertToExternalFormat = (date: string | string[], dateFormat: string) => {
  if (isString(date)) {
    return format(createDateObject(date as string), normalizeDateFormat(dateFormat));
  } else {
    return (date as string[]).map((d) => (!!d ? format(createDateObject(d), normalizeDateFormat(dateFormat)) : ''));
  }
};

/** Computes the behavior for setting the next range */
export const curriedGetNextRange =
  ({ focusedRange }: Pick<UseCalendarProps, 'focusedRange'>) =>
  (date: string, currentRange: string[]): string[] => {
    if (!focusedRange) {
      // fills in min, then max, then replaces min if both exists
      if (!currentRange || currentRange.length < 1) {
        return [date, ''];
      } else if (!currentRange || currentRange[0] === '') {
        return [date, currentRange[1]!];
      } else if (currentRange.length < 2 || currentRange[1] == '') {
        return [currentRange[0]!, date].sort((a, b) => compareAsc(createDateObject(a!), createDateObject(b!)));
      } else {
        return [date, ''];
      }
    } else {
      const index = focusedRange === 'min' ? 0 : 1;
      const notIndex = focusedRange === 'min' ? 1 : 0;

      const returnVal = ['', ''];
      if (!currentRange || currentRange.length < 2) {
        returnVal[index] = date;
        if (focusedRange === 'max') returnVal[notIndex] = currentRange[notIndex] ?? '';
        return returnVal;
      } else {
        returnVal[index] = date;
        returnVal[notIndex] = currentRange[notIndex]!;

        if (returnVal.every((i) => !!i)) {
          returnVal.sort((a, b) => compareAsc(createDateObject(a), createDateObject(b)));
        }

        return returnVal;
      }
    }
  };

/**
 * Generates data for a specific day at a zero-based index in a week
 *
 * @param {object} data Data to seed the week/month/year data for a day generator for
 *   mapping days in a week.
 * @returns {function} Function for mapping a week to days based on seeded month/week/year data
 */
const getDay = ({ start, month, monthsIndex, week, blackoutDates = [], minDate, maxDate }: GetDayProps) => {
  const isBlackoutDay = curriedIsBlackoutDay({ blackoutDates });
  const isOutOfBounds = curriedIsDayOutOfBounds({ minDate, maxDate });
  const isDayDisabled = (day: string) => isBlackoutDay(day) || isOutOfBounds(day);

  return (_, index: number) => {
    const date = addDays(start, week * 7 + index);
    const targetMonth = getMonth(date);
    if (targetMonth === month) {
      // helper functions rely on this format
      const dayDate = format(date, INTERNAL_DATE_FORMAT);
      return {
        date: dayDate,
        day: getDate(date),
        disabled: isDayDisabled(dayDate),
        index,
        month: targetMonth,
        monthsIndex,
        year: getYear(date),
        week,
      };
    }
    return null;
  };
};

type GetCalendarDataProps = CalendarBoundaries & {
  startDate?: Date;
  monthsInView?: number;
};

/**
 * Generates calendar data based on a start date and the number of months to show,
 * including the month of the start date and moving forward.
 *
 * @param {string} [props.startDate] The start date for calendar day availability.
 * @param {number} [props.monthsInView] The number of months to show in the calendar (default = 1)
 * @param {string} [props.maxDate] Optional maxDate boundary.
 * @param {string} [props.minDate] Optional minDate boundary.
 * @param {string[]} [props.maxDate] Optional blackout dates array.
 * @returns {object[]} An array of month objects with the following keys: `month`
 *   (number), `year` (number), `weeks`: ([[object]])
 */
export const getCalendarData = ({
  startDate = new Date(),
  monthsInView = 1,
  ...boundaries
}: GetCalendarDataProps): CalendarMonthType[] => {
  // return array of months (could be 1 or more months displayed at a time)
  return Array.from(new Array(monthsInView)).map((_, monthsIndex) => {
    const current = addMonths(startDate, monthsIndex);
    const currentMonth = getMonth(current);
    const start = startOfWeek(startOfMonth(current));
    const end = endOfWeek(endOfMonth(current));
    const weekCount = Math.ceil(differenceInDays(end, start) / 7);

    return {
      month: currentMonth,
      year: getYear(current),
      weeks: Array.from(new Array(weekCount)).map((_, idx) => {
        const dayGetter = getDay({
          start,
          monthsIndex,
          month: currentMonth,
          week: idx,
          ...boundaries,
        });
        return Array.from(new Array(7)).map(dayGetter);
      }),
    };
  });
};

/**
 * For converting strings to numbers for a set of data attributes representing a calendar
 * day. (Values from data attributes will always be returned as strings.)
 *
 * @param {object} data Object of data attributes representing a calendar day
 * @returns {object} Object with values cast to numbers
 */
export const dataToNumber = (data: DOMStringMap) =>
  Object.entries(data).reduce(
    (obj, [key, value]) => ({
      ...obj,
      [key]: key === 'date' ? value : value ? +value : 0,
    }),
    {}
  );

export const firstDayOfMonth = (month: CalendarMonthType): CalendarDayType => month.weeks[0]!.filter(Boolean).shift()!;

export const lastDayOfMonth = (month: CalendarMonthType): CalendarDayType => {
  const lastWeek = month.weeks[month.weeks.length - 1];
  return lastWeek!.filter(Boolean).pop()!;
};

/**
 * Returns the first non null day that exists at an index.
 *
 * @param startIndex 0 starts from beginning of month, -1 starts from end.
 */
const findClosestDayAtIndex =
  (startIndex: number) =>
  (month: CalendarMonthType, dayIndex: number): CalendarDayType | null => {
    if (dayIndex > 6 || dayIndex < 0) {
      //throw new Error('index must be a day index: 0-6');
    }
    const weeks = startIndex < 0 ? month.weeks.slice().reverse() : month.weeks;
    const length = weeks.length;

    for (let i = 0; i < length; i++) {
      if (weeks[i]![dayIndex]) return weeks[i]![dayIndex]!;
    }
    return null;
  };

/**
 * Gets day from from the first week that exists at the given index. Used for keying DOWN
 * on a day that jumps between months.
 */
export const firstMatchingDayAtIndex = findClosestDayAtIndex(0);

/**
 * Gets day from from the last week that exists at the given index. Used for keying UP on
 * a day that jumps between months.
 */
export const lastMatchingDayAtIndex = findClosestDayAtIndex(-1);

const yearsMatch = (year: number) => (month) => month.year === year;
/** Returns a CalendarDayType in the months data, null if it doesn't exist */
export const isTodayMonth = (monthLeft: string, monthRight: string) => monthLeft === monthRight;

export const isTodayYear = (yearLeft: string, yearRight: string) => yearLeft === yearRight;

export const curriedFindDay = (months: CalendarMonthType[]) => (date: FocusedDate) => {
  if (!months.some(yearsMatch(date.year))) return null;

  const monthIndex = months.findIndex((month) => month.month === date.month);
  if (monthIndex < 0) return null;

  // search weeks
  const startIndex = Math.ceil(date.day / 7) - 1;
  let dayIndex = -1;

  for (let weekIndex = startIndex; weekIndex < startIndex + 2; weekIndex++) {
    dayIndex = months[monthIndex]!.weeks[weekIndex]!.findIndex((day) => day?.day === date.day);
    if (dayIndex > -1) {
      return months[monthIndex]!.weeks[weekIndex]![dayIndex];
    }
  }

  return null;
};

// These props are curried to the day helper utils functions from use calendar
type CurriedProps = {
  minDate: string;
  maxDate: string;
  months: CalendarMonthType[];
  blackoutDates: string[];
};

/** Returns a day util that checks if the day is blacked out. */
export const curriedIsBlackoutDay =
  ({ blackoutDates }: Pick<CurriedProps, 'blackoutDates'>) =>
  (date: string) =>
    !!blackoutDates.length && !!blackoutDates.find((d) => isSameDay(createDateObject(d), createDateObject(date)));

/** Returns a day util that checks if the day is disabled */
export const curriedIsDayOutOfBounds =
  ({ minDate, maxDate }: Partial<Pick<CurriedProps, 'minDate' | 'maxDate'>>) =>
  (date: string) => {
    const dayDate = createDateObject(date);
    return (
      (!!minDate && isBefore(dayDate, createDateObject(minDate))) ||
      (!!maxDate && isAfter(dayDate, createDateObject(maxDate)))
    );
  };

/**
 * Returns new date
 *
 * @param startDay
 * @param diff Positive or negative integer, representing the difference in months
 */
export const getNextStartByDiff = (
  startDay: PickRequired<CalendarDayType, 'year' | 'month' | 'day'>,
  diff: number
): FocusedDate => {
  const action = diff > 0 ? addMonths : subMonths;
  return createFocusedDate(format(action(createDateObject(startDay), Math.abs(diff)), INTERNAL_DATE_FORMAT));
};

export const hasPreviousView = (months: CalendarMonthType[], minDate?: string) => {
  if (!minDate) return true;
  const { month, year } = months[0]!;
  const minMonth = getMonth(createDateObject(minDate));
  const minYear = getYear(createDateObject(minDate));
  if (year === minYear) return month > minMonth;
  return year > minYear;
};

export const hasNextView = (months: CalendarMonthType[], maxDate?: string) => {
  if (!maxDate) return true;
  const { month, year } = months[months.length - 1]!;
  const maxMonth = getMonth(createDateObject(maxDate));
  const maxYear = getYear(createDateObject(maxDate));
  if (year === maxYear) return month < maxMonth;
  return year < maxYear;
};

/**
 * It takes a year and returns an array of 12 years. If any year from that array is passed
 * to this function, it would return the same array of years.
 *
 * @param {number | string} year - Number | string
 * @returns An array of 12 years.
 */
export const getDecadeRange = (year: number | string) => {
  const numberOfYears = 12;
  const yearNumber = Number(year);
  const rounded = yearNumber - (yearNumber % numberOfYears);
  return Array.from({ length: numberOfYears }, (_, i) => rounded + i);
};

interface IsMonthInRange {
  monthYear: string;
  minDate?: string;
  maxDate?: string;
}

/**
 * Checks if a specific month is in range. It returns true if the month is between the
 * minDate and maxDate, or if there is no minDate or maxDate passed
 *
 * @param {IsMonthInRange} - IsMonthInRange
 * @returns A boolean value
 */
export const isMonthInRange = ({ monthYear, minDate, maxDate }: IsMonthInRange): boolean => {
  if (!minDate && !maxDate) {
    return true;
  }

  const parsedMonth = parse(monthYear, 'yyyy-MM', new Date());
  const monthEnd = endOfMonth(parsedMonth);
  const monthStart = startOfMonth(parsedMonth);
  const maxInRange = maxDate ? isBefore(monthStart, new Date(maxDate)) : true;
  const minInRange = minDate ? isAfter(monthEnd, new Date(minDate)) : true;

  return maxInRange && minInRange;
};

interface IsYearInRange {
  year: string;
  minDate?: string;
  maxDate?: string;
}

/**
 * Checks if a year is outside of the range of the minDate and maxDate
 *
 * @param {IsYearInRange} - IsYearInRange
 * @returns A boolean value
 */
export function isYearInRange({ year, minDate, maxDate }: IsYearInRange): boolean {
  if (!minDate && !maxDate) {
    return false;
  }
  const yearNumber = Number(year);
  const minYear = minDate ? getYear(new Date(minDate)) : 0;
  const maxYear = maxDate ? getYear(new Date(maxDate)) : Infinity;

  return yearNumber < minYear || yearNumber > maxYear;
}

type IsDateShiftValid = {
  year: number;
  action: Actions;
};
type IsYearLeftShiftValid = IsDateShiftValid & {
  minDate?: string;
};

export const isYearLeftShiftValid = ({ year, minDate, action }: IsYearLeftShiftValid) => {
  if (!minDate) return true;
  const yearRange = getDecadeRange(year);
  const yearIndex = yearRange.indexOf(year);
  const decadeBottomRow = [0, 1, 2];
  const isYearIndexTop = decadeBottomRow.includes(yearIndex);
  const minDateYear = getYear(new Date(minDate));

  if (minDateYear >= yearRange[0]!) {
    if (action === Actions.yearUp && isYearIndexTop) return false;
    if (action === Actions.yearLeft && yearIndex === 0) return false;
  }

  return true;
};

type IsYearRightShiftValid = IsDateShiftValid & {
  maxDate?: string;
};

export const isYearRightShiftValid = ({ year, maxDate, action }: IsYearRightShiftValid) => {
  if (!maxDate) return true;
  const yearRange = getDecadeRange(year);
  const yearIndex = yearRange.indexOf(year);
  const decadeLastIndex = yearRange.length - 1;
  const decadeTopRow = [decadeLastIndex, decadeLastIndex - 1, decadeLastIndex - 2];

  const isYearIndexTop = decadeTopRow.includes(yearIndex);

  const maxDateYear = getYear(new Date(maxDate));

  if (maxDateYear <= yearRange[decadeLastIndex]!) {
    if (action === Actions.yearDown && isYearIndexTop) return false;
    if (action === Actions.yearRight && yearIndex === decadeLastIndex) return false;
  }

  return true;
};

type IsMonthLeftShiftValid = IsDateShiftValid & {
  minDate?: string;
  month: number;
};

export const isMonthLeftShiftValid = ({ month, year, minDate, action }: IsMonthLeftShiftValid) => {
  if (!minDate) return true;

  const bottomRow = [0, 1, 2];
  const isMonthIndexTop = bottomRow.includes(month);
  const minDateYear = getYear(new Date(minDate));

  if (minDateYear >= year) {
    if (action === Actions.monthUp && isMonthIndexTop) return false;
    if (action === Actions.monthLeft && month === 0) return false;
  }

  return true;
};

type IsMonthRightShiftValid = IsDateShiftValid & {
  maxDate?: string;
  month: number;
};

export const isMonthRightShiftValid = ({ month, year, maxDate, action }: IsMonthRightShiftValid) => {
  if (!maxDate) return true;

  const topRow = [9, 10, 11];
  const isMonthIndexBottom = topRow.includes(month);

  const maxDateYear = getYear(new Date(maxDate));
  if (maxDateYear <= year) {
    if (action === Actions.monthDown && isMonthIndexBottom) return false;
    if (action === Actions.monthRight && month === 11) return false;
  }

  return true;
};

export function isDecadeInRange({ year, minDate, maxDate }: IsYearInRange): boolean {
  if (!minDate && !maxDate) {
    return true;
  }
  const yearNumber = Number(year);
  const minYear = minDate ? getYear(new Date(minDate)) : 0;
  const maxYear = maxDate ? getYear(new Date(maxDate)) : Infinity;

  return yearNumber < minYear || yearNumber > maxYear;
}

export function parseInternalDate(date: string): Date {
  return parse(date, INTERNAL_DATE_FORMAT, new Date());
}
