import { DropPaste } from '../../plugins/drop-paste';
import { ImagePastePlugin } from '../../plugins/image-paste-plugin';
import { ImagesPlugin } from '../../plugins/images-plugin';

export const Image = () => {
  return (
    <>
      <ImagesPlugin />
      <DropPaste />
      <ImagePastePlugin />
    </>
  );
};
