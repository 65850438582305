import { useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ErrorBadgeIcon, Text } from '@frontend/design-system';

const errorTypes: {
  [key: number | string]: {
    errorMessage: string;
  };
} = {
  500: {
    errorMessage:
      'Our team has been notified, and we are working to resolve the issue as quickly as possible. Please try again later. If the problem persists, please contact Customer Support at (888) 579-5668 for further assistance.',
  },
  401: {
    errorMessage: 'Invalid login credentials.',
  },
  402: {
    errorMessage:
      'If your email matches a registered account, you will have received instructions on how to regain access. You can also reset your password.',
  },
  pawnedPassword: {
    errorMessage:
      "We've detected that this password matches one found in a public database of exposed passwords. To protect your account, please create a new password.",
  },
  invalidMFACode: {
    errorMessage: 'The verification code you entered is incorrect. Please check your code and try again.',
  },
  codeSendFailure: {
    errorMessage: 'We were unable to send the verification code. Please try again.',
  },
  webauthnError: {
    errorMessage: 'There was an error with your passkey. Please try again.',
  },
  webauthnSetupError: {
    errorMessage: 'We could not set up your passkey.',
  },
  // Add more error codes in future
};

interface SignInErrorProps {
  errorCode: number | string | undefined;
  onErrorHide: (value: boolean) => void;
}

export const SignInError = ({ errorCode, onErrorHide }: SignInErrorProps) => {
  const { errorMessage } = errorTypes[errorCode as keyof typeof errorTypes] || {};
  const [isVisible, setIsVisible] = useState(true);

  const handleIconClick = () => {
    setIsVisible(false); // Hide the error message on icon click
  };

  if (!isVisible || !errorMessage) {
    onErrorHide(false);
    return null;
  }

  return (
    <div
      css={css`
        border: 1px solid ${theme.colors.critical50};
        background: ${theme.colors.critical5};
        border-radius: ${theme.borderRadius.medium};
        margin-top: ${theme.spacing(10)};
        padding: ${theme.spacing(2)};
        display: flex;
      `}
    >
      <Text
        size='medium'
        css={css`
          display: flex;
          gap: ${theme.spacing(1)};
          align-items: center;
        `}
      >
        <ErrorBadgeIcon
          name='error-badge'
          color='error'
          css={css`
            cursor: pointer;
          `}
          onClick={handleIconClick}
        />
        {errorMessage}
      </Text>
    </div>
  );
};
