import { authnClient } from '@frontend/auth';

export async function workforceSignin() {
  authnClient.changeAuthMethod('okta');
  await authnClient.signIn();
}

export async function workforceSigninCallback() {
  authnClient.changeAuthMethod('okta');
  return await authnClient.handleCallback();
}
