import React, { ReactNode, Children, useState, useEffect } from 'react';
import { contextFactory } from '../../../hooks';

export type StepStatus = {
  [key: number]: Status;
};
export interface StepperProviderProps {
  scrollToCard: (step: number) => void;
  initialActiveStep: number;
  children: ReactNode | ReactNode[];
  stepStatus?: StepStatus;
}

export type Status =
  | 'active'
  | 'completed'
  | 'currActive'
  | 'error'
  | 'errorActive'
  | 'finished'
  | 'inactive'
  | 'lastActive';

interface StepperContext {
  nextFunction: (step: number) => void;
  previousFunction: (step: number) => void;
  skipFunction: (skipTo: number, step: number) => void;
  handleStepperCard: (step: number) => void;
  stepStatus: StepStatus;
  children: ReactNode | ReactNode[];
  isStepperCompleted: boolean;
  lastActive: number;
}

export const [StepperContext, useStepperContext] = contextFactory<StepperContext>();

export function StepperProvider({
  scrollToCard,
  initialActiveStep,
  children,
  stepStatus: stepStatusProp,
}: StepperProviderProps) {
  const [stepStatus, setStepStatus] = useState<StepStatus>(stepStatusProp ?? { 1: 'active', 2: 'inactive' });
  const reactChildren = Children.toArray(children) as Array<React.ReactElement>;
  const isStepperCompleted = Object.keys(stepStatus).every((key) => stepStatus[key] === 'completed');
  const currActive =
    Object.keys(stepStatus).find(
      (key) => stepStatus[key] === 'active' || stepStatus[key] === 'currActive' || stepStatus[key] === 'errorActive'
    ) || -1;
  const lastActive = Object.keys(stepStatus).find((key) => stepStatus[key] === 'lastActive') || -1;

  useEffect(() => {
    if (!stepStatusProp) {
      const stepChildren = {};
      if (initialActiveStep) {
        for (let i = 1; i <= reactChildren[0]?.props.children[2].length; i++) {
          if (i === initialActiveStep) {
            stepChildren[i] = 'active';
          } else if (i < initialActiveStep) {
            stepChildren[i] = 'completed';
          } else {
            stepChildren[i] = 'inactive';
          }
        }
        setStepStatus(stepChildren);
      } else {
        for (let i = 2; i <= reactChildren[0]?.props.children[2].length; i++) {
          stepChildren[i] = 'inactive';
        }
        setStepStatus({ ...stepStatus, ...stepChildren });
      }
    }
  }, [reactChildren[0]?.props.children[2].length, stepStatusProp, initialActiveStep]);

  useEffect(() => {
    if (stepStatusProp) setStepStatus(stepStatusProp);
  }, [stepStatusProp]);

  const nextFunction = (step: number) => {
    const next = Object.keys(stepStatus).find((key) => stepStatus[key] === 'inactive') || reactChildren.length;
    const finished = stepStatus[step] === 'active' && stepStatus[step + 1] === undefined;

    if (finished) {
      setStepStatus({ ...stepStatus, [step]: 'completed' });
    } else if (stepStatus[step] !== 'currActive' && !finished && step !== reactChildren[0]?.props.children[2].length) {
      setStepStatus({ ...stepStatus, [step]: 'completed', [next]: 'active' });
      scrollToCard(+next - 1);
    } else {
      setStepStatus({
        ...stepStatus,
        [step]: 'completed',
        [step + 1]: +lastActive === step + 1 ? 'active' : 'currActive',
      });
      scrollToCard(step + 1);
    }
  };

  const previousFunction = (step: number) => {
    scrollToCard(step - 2);
    setStepStatus({ ...stepStatus, [step - 1]: 'currActive', [step]: 'inactive' });
  };

  const skipFunction = (skipTo: number, step: number) => {
    const stepObject: StepStatus = {};
    for (let i = step; i < skipTo; i++) {
      stepObject[i] = 'completed';
    }
    scrollToCard(skipTo - 1);
    setStepStatus({ ...stepStatus, ...stepObject, [skipTo]: 'active' });
  };

  const handleStepperCard = (step: number) => {
    if (stepStatus[step] === 'completed' || stepStatus[step] === 'lastActive') {
      scrollToCard(step);
      return setStepStatus({
        ...stepStatus,
        [step]: stepStatus[step] === 'lastActive' ? 'active' : 'currActive',
        [currActive]:
          stepStatus[currActive] === 'lastActive' || stepStatus[currActive] === 'active' ? 'lastActive' : 'completed',
      });
    }
  };

  return (
    <StepperContext.Provider
      value={{
        stepStatus,
        nextFunction,
        previousFunction,
        skipFunction,
        handleStepperCard,
        children,
        isStepperCompleted,
        lastActive: +lastActive,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
}
