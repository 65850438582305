import { useEffect, useRef, useCallback } from 'react';
import tabbable from 'tabbable';
import { KeyNames } from '../constants';

type UseTabbableProps<T extends HTMLElement> = {
  ref?: React.MutableRefObject<T | null>;
  timeout?: number;
  autofocus?: 'first' | 'last';
  /**
   * The sole purpose of this prop is to act as a trigger to the effect that initializes the tabbable elements.
   */
  reset?: any;
};

const down = 'keydown';

export function useTabbable<T extends HTMLElement>({ ref, timeout = 0, autofocus, reset }: UseTabbableProps<T>) {
  const tabsRef = useRef<HTMLElement[]>([]);
  const containerRef = useRef<T>(null);
  const isSubsequentUpdateRef = useRef(false);

  const trapFocus = useCallback((e: any) => {
    if (e.key === KeyNames.Tab) {
      tabsRef.current = tabbable((ref ?? containerRef).current);

      if (e.target === tabsRef.current[tabsRef.current.length - 1] && !e.shiftKey) {
        e.preventDefault();
        tabsRef.current[0]!.focus();
      }
      if (e.target === tabsRef.current[0] && e.shiftKey) {
        e.preventDefault();
        tabsRef.current[tabsRef.current.length - 1]!.focus();
      }
    }
  }, []);

  const killTab = useCallback((e: any) => {
    if (e.key === KeyNames.Tab) e.preventDefault();
  }, []);

  function setupTabbables() {
    const tabs = tabsRef.current;
    if (tabs.length === 0) return;

    const first = tabs[0];
    const last = tabs[tabs.length - 1];

    if (tabs.length > 1) {
      first!.addEventListener(down, trapFocus);
      last!.addEventListener(down, trapFocus);
    } else if (first) {
      first.addEventListener(down, killTab);
    }
  }

  const resetTabbables = () => {
    const tabs = tabsRef.current;
    if (tabs.length < 1) return;

    const first = tabs[0];
    const last = tabs[tabs.length - 1];

    first!.removeEventListener(down, killTab);
    if (tabs.length > 1) {
      last!.removeEventListener(down, trapFocus);
    }
  };

  useEffect(() => {
    tabsRef.current = tabbable((ref ?? containerRef).current);

    setTimeout(() => {
      setupTabbables();
      if (!!autofocus) {
        const index = autofocus === 'first' ? 0 : tabsRef.current.length - 1;
        tabsRef.current[index] && tabsRef.current[index].focus();
      }
    }, timeout);

    return () => {
      resetTabbables();
    };
  }, [reset]);

  useEffect(() => {
    if (isSubsequentUpdateRef.current) {
      resetTabbables();
      tabsRef.current = tabbable((ref ?? containerRef).current);
      setupTabbables();
    } else {
      isSubsequentUpdateRef.current = true;
    }
  });

  return {
    ref: ref ?? containerRef,
    tabbables: tabsRef.current,
  };
}
