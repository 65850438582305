import { cloneElement, HTMLAttributes, ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { mergeClasses } from '../../../helpers';
import { useThemeValues } from '../../../hooks';
import { animated } from '../../animated';
import { ContentLoader } from '../../loader';
import { useModalContext } from '../provider';

export type ModalBoxProps = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  flex?: boolean;
  maxWidth?: number;
  minWidth?: number;
  asChild?: boolean;
};

const maxWidthIncludingMargins = '100vh - 48px';

export const ModalBox = ({
  children,
  asChild,
  className,
  flex,
  minWidth = 0,
  maxWidth = 0,
  ...rest
}: ModalBoxProps) => {
  const { containerProps, loadingState } = useModalContext();
  const { spacing, colors, borderRadius } = useThemeValues();

  const styles = [
    css`
      width: ${maxWidth ? '100%' : 'unset'};
      min-width: ${minWidth > 0 ? `min(${minWidth}px, ${maxWidthIncludingMargins})` : 0};
      max-width: ${maxWidth ? `min(${maxWidth}px, ${maxWidthIncludingMargins})` : `calc(${maxWidthIncludingMargins})`};
      max-height: calc(100vh - ${spacing(8)});
      background: ${colors.white};
      border-radius: ${borderRadius.medium};
      position: relative;
      padding: ${spacing(3, 0)};

      > {
        // All the scrollbars within the modal should be consistent
        ::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        ::-webkit-scrollbar-thumb {
          background-color: ${theme.colors.neutral20};
          border-radius: ${borderRadius.small};
        }
      }
    `,
    flex
      ? css`
          display: grid;
          > * {
            // These make grid cells work as expected, otherwise grid sets them to be auto
            min-width: 0;
            min-height: 0;
          }

          // Body only
          :has(.wv-modal-flex-content):not(:has(> .wv-modal-header,> .wv-modal-label, > .wv-modal-footer)) ) {
            grid-template-rows: [body-start] minmax(0, 1fr) [body-end];
          }

          // Body and Footer
          :has(.wv-modal-flex-content + .wv-modal-footer):not(:has(> .wv-modal-header, > .wv-modal-label)) {
            grid-template-rows: [body-start] minmax(0, 1fr) [body-end] ${spacing(3)} [footer-start] auto [footer-end];
          }

          // Header and Body
          :has(.wv-modal-header + .wv-modal-flex-content):not(:has(> .wv-modal-label, > .wv-modal-footer)) {
            grid-template-rows: [header-start] auto [header-end] ${spacing(1)} [body-start] minmax(0, 1fr) [body-end];
          }

          // Header, Label, and Body
          :has(.wv-modal-header + .wv-modal-label + .wv-modal-flex-content):not(:has(> .wv-modal-footer)) {
            grid-template-rows:
              [header-start] auto [header-end] 0 [label-start] auto [label-end] ${spacing(1)} [body-start] minmax(
                0,
                1fr
              )
              [body-end];
          }

          // Header, Body, and Footer
          :has(.wv-modal-header + .wv-modal-flex-content + .wv-modal-footer):not(:has(> .wv-modal-label)) {
            grid-template-rows: [header-start] auto [header-end] ${spacing(1)} [body-start] minmax(0, 1fr) [body-end] ${spacing(
                3
              )} [footer-start] auto [footer-end];
          }

          // Header, Label, Body, and Footer
          :has(.wv-modal-header + .wv-modal-label + .wv-modal-flex-content + .wv-modal-footer) {
            grid-template-rows:
              [header-start] auto [header-end] 0 [label-start] auto [label-end] ${spacing(1)} [body-start] minmax(
                0,
                1fr
              )
              [body-end] ${spacing(3)} [footer-start] auto [footer-end];
          }
        `
      : css`
          overflow-y: auto;
        `,
    maxWidth > 0 &&
      css`
        @media only screen and (min-width: ${maxWidth + 48}px) {
          width: auto;
        }
      `,
  ];

  const props = {
    className: mergeClasses('wv-modal-box', className),
    css: [styles],
    onClick: (e) => e.stopPropagation(),
    ...rest,
    ...containerProps,
  };

  if (asChild) {
    const element = cloneElement(children as React.ReactElement, props);

    if (typeof element.type === 'string') {
      const Component = animated[element.type];
      return <Component {...element.props} />;
    }

    return <>{element}</>;
  }

  return (
    <animated.section {...props}>
      {children}
      <ContentLoader
        css={css`
          border-radius: ${borderRadius.medium};
        `}
        {...loadingState}
      />
    </animated.section>
  );
};
