import { useCallback } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INDENT_CONTENT_COMMAND } from 'lexical';
import { IndentIncreaseAction } from '../../molecules/indent-increase-action';

export const Indent = () => {
  const [editor] = useLexicalComposerContext();

  const onIndentSelect = useCallback(() => {
    editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
  }, [editor]);

  return <IndentIncreaseAction onClick={onIndentSelect} aria-label='Format Indent' />;
};

Indent.displayName = 'Indent';
