import React from 'react';
import { Text } from '../../text';
import { useModalContext } from '../provider';

export const ConfirmationText: React.FC<React.PropsWithChildren<{ children: React.ReactNode }>> = ({ children }) => {
  const { descriptionId } = useModalContext();
  return (
    <Text id={descriptionId} textAlign='left'>
      {children}
    </Text>
  );
};
