import { Reducer } from 'react';
import { PickRequired } from '@frontend/types';
import type { CalendarMonthType, CalendarDayType, FocusedDate, SelectByType } from '../../calendar-types';
import { Actions } from './calendar-actions';
import type { ActionType, CalendarBoundaries } from './calendar-actions';
import {
  getCalendarData,
  getNextStartByDiff,
  createDateObject,
  createFocusedDate,
  createFocusedMonth,
  createFocusedYear,
  curriedFindDay,
  firstDayOfMonth,
  lastDayOfMonth,
  firstMatchingDayAtIndex,
  lastMatchingDayAtIndex,
  hasPreviousView,
  hasNextView,
  isYearLeftShiftValid,
  isYearRightShiftValid,
  isMonthLeftShiftValid,
  isMonthRightShiftValid,
} from './calendar-utils';

export type CalendarReducer = Reducer<CalendarState, ActionType>;

export type CalendarState = CalendarBoundaries & {
  focusedDate: FocusedDate;
  selectedDate: string | string[]; // string representing the date format
  months: CalendarMonthType[];
  monthsInView: number;
  selectBy: SelectByType;
};

const isRightShift = (action: Actions) =>
  [
    Actions.dayDown,
    Actions.dayRight,
    Actions.yearDown,
    Actions.yearRight,
    Actions.monthDown,
    Actions.monthRight,
  ].includes(action);
const isLeftShift = (action: Actions) =>
  [Actions.dayUp, Actions.dayLeft, Actions.yearUp, Actions.yearLeft, Actions.monthUp, Actions.monthLeft].includes(
    action
  );

type IsBoundaryEdgeProps = CalendarBoundaries & {
  action: Actions;
  months: CalendarMonthType[];
  year: number;
  month: number;
};

type isDayBoundaryEdgeProps = PickRequired<IsBoundaryEdgeProps, 'action' | 'months'>;
type IsYearBoundaryEdgeProps = PickRequired<IsBoundaryEdgeProps, 'action' | 'year'>;
type isMonthBoundaryEdgeProps = PickRequired<IsBoundaryEdgeProps, 'action' | 'month' | 'year'>;

const isOutOfBounds = ({ action, months, minDate, maxDate }: isDayBoundaryEdgeProps) =>
  (isRightShift(action) && !hasNextView(months, maxDate)) || (isLeftShift(action) && !hasPreviousView(months, minDate));

const isYearOutOfBounds = ({ action, year, minDate, maxDate }: IsYearBoundaryEdgeProps) =>
  (isLeftShift(action) && !isYearLeftShiftValid({ year, action, minDate })) ||
  (isRightShift(action) && !isYearRightShiftValid({ year, action, maxDate }));

const isMonthOutOfBounds = ({ action, month, year, minDate, maxDate }: isMonthBoundaryEdgeProps) =>
  (isLeftShift(action) && !isMonthLeftShiftValid({ month, year, action, minDate })) ||
  (isRightShift(action) && !isMonthRightShiftValid({ month, year, action, maxDate }));

const getDayAcrossBoundary = {
  [Actions.dayUp]: lastMatchingDayAtIndex,
  [Actions.dayDown]: firstMatchingDayAtIndex,
  [Actions.dayLeft]: lastDayOfMonth,
  [Actions.dayRight]: firstDayOfMonth,
};

type NextDayGetter = (months: CalendarMonthType[], currentDay: CalendarDayType) => CalendarDayType | null;

export const getNextDay: { [key: string]: NextDayGetter } = {
  [Actions.dayUp]: (months, currentDay): CalendarDayType | null => {
    const { week, monthsIndex, index } = currentDay;
    const prevWeek = months[monthsIndex]!.weeks[week - 1];
    if (!prevWeek || !prevWeek[index]) {
      if (monthsIndex === 0) return null;
      return lastMatchingDayAtIndex(months[monthsIndex - 1]!, index);
    }
    return prevWeek[index];
  },
  [Actions.dayDown]: (months, currentDay) => {
    const { week, monthsIndex, index } = currentDay;
    const nextWeek = months[monthsIndex]!.weeks[week + 1];
    if (!nextWeek || !nextWeek[index]) {
      if (monthsIndex === months.length - 1) return null;
      return firstMatchingDayAtIndex(months[monthsIndex + 1]!, index);
    }
    return nextWeek[index];
  },
  [Actions.dayLeft]: (months, currentDay) => {
    const { week, monthsIndex, index } = currentDay;
    const weeks = months[monthsIndex]!.weeks;
    const prevDay = weeks[week]![index - 1];
    if (!prevDay) {
      if (week === 0) {
        if (monthsIndex === 0) return null;
        return lastDayOfMonth(months[monthsIndex - 1]!);
      }
      return weeks[week - 1]![6]!;
    } else {
      return prevDay;
    }
  },
  [Actions.dayRight]: (months, currentDay) => {
    const { week, monthsIndex, index } = currentDay;
    const weeks = months[monthsIndex]!.weeks;
    const nextDay = weeks[week]![index + 1];
    if (!nextDay) {
      if (week === weeks.length - 1) {
        if (monthsIndex === months.length - 1) return null;
        return firstDayOfMonth(months[monthsIndex + 1]!);
      }
      return weeks[week + 1]![0]!;
    } else {
      return nextDay;
    }
  },
};

type NextMonthGetter = (focusedDate: FocusedDate) => FocusedDate;

export const getNextMonth: { [key: string]: NextMonthGetter } = {
  [Actions.monthUp]: (focusedDate) => {
    return getNextStartByDiff(focusedDate, -3);
  },
  [Actions.monthDown]: (focusedDate) => {
    return getNextStartByDiff(focusedDate, 3);
  },
  [Actions.monthLeft]: (focusedDate) => {
    return getNextStartByDiff(focusedDate, -1);
  },
  [Actions.monthRight]: (focusedDate) => {
    return getNextStartByDiff(focusedDate, 1);
  },
};

type NextYearGetter = NextMonthGetter;

export const getNextYear: { [key: string]: NextYearGetter } = {
  [Actions.yearUp]: (focusedDate) => {
    return getNextStartByDiff(focusedDate, -36);
  },
  [Actions.yearDown]: (focusedDate) => {
    return getNextStartByDiff(focusedDate, 36);
  },
  [Actions.yearLeft]: (focusedDate) => {
    return getNextStartByDiff(focusedDate, -12);
  },
  [Actions.yearRight]: (focusedDate) => {
    return getNextStartByDiff(focusedDate, 12);
  },
};

const getNewMonthIndex = (monthsInView, action: Actions) => {
  const map = {
    [Actions.dayUp]: monthsInView - 1,
    [Actions.dayDown]: 0,
    [Actions.dayLeft]: monthsInView - 1,
    [Actions.dayRight]: 0,
  };
  return map[action];
};
/**
 * Reduces the state for up, down, left and right
 */
const moveDay = (state: CalendarState, action: ActionType) => {
  const { months, focusedDate, monthsInView, ...boundaries } = state;
  const currentDay = curriedFindDay(months)(focusedDate);

  if (!currentDay) {
    console.error('focused day is not in data set', { focusedDate, months });
    return state;
  }
  // gets the next day in the current view
  let nextDay = getNextDay[action.type]!(months, currentDay);

  if (nextDay) {
    return { ...state, focusedDate: createFocusedDate(nextDay) };
  }
  // no nextDay === moving into new month
  // check if we've hit boundaries
  if (isOutOfBounds({ action: action.type, months, ...boundaries })) {
    return state;
  }
  // get new view data
  const nextMonths = getCalendarData({
    startDate: createDateObject(getNextStartByDiff(currentDay, isLeftShift(action.type) ? -1 * monthsInView : 1)),
    monthsInView,
    ...boundaries,
  });
  // figure out next target
  nextDay = getDayAcrossBoundary[action.type](
    nextMonths[getNewMonthIndex(monthsInView, action.type)],
    currentDay.index
  );
  // this really shouldn't happen, but ts needs the guard + Murphy's law
  if (!nextDay) return state;

  return {
    ...state,
    months: nextMonths,
    focusedDate: createFocusedDate(nextDay),
  };
};

const moveMonth = (state: CalendarState, action: ActionType) => {
  const { focusedDate, minDate, maxDate, ...rest } = state;
  const { month, year } = focusedDate;

  if (isMonthOutOfBounds({ action: action.type, year, month, minDate, maxDate })) {
    return state;
  }

  const nextStart = getNextMonth[action.type]!(focusedDate);

  return {
    ...state,
    months: getCalendarData({ ...rest, startDate: createDateObject(nextStart) }),
    focusedDate: nextStart,
  };
};

const moveYear = (state: CalendarState, action: ActionType) => {
  const { focusedDate, minDate, maxDate, ...rest } = state;

  if (isYearOutOfBounds({ action: action.type, year: focusedDate.year, minDate, maxDate })) {
    return state;
  }

  const nextStart = getNextYear[action.type]!(focusedDate);
  return {
    ...state,
    months: getCalendarData({ ...rest, startDate: createDateObject(nextStart) }),
    focusedDate: nextStart,
  };
};

export const calendarReducer = (state: CalendarState, action: ActionType): CalendarState => {
  switch (action.type) {
    case Actions.prevDecade: {
      const { focusedDate, ...rest } = state;
      const nextStart = getNextStartByDiff(focusedDate, -144);
      return {
        ...state,
        months: getCalendarData({ ...rest, startDate: createDateObject(nextStart) }),
        focusedDate: nextStart,
      };
    }
    case Actions.nextDecade: {
      const { focusedDate, ...rest } = state;
      const nextStart = getNextStartByDiff(focusedDate, 144);
      return {
        ...state,
        months: getCalendarData({ ...rest, startDate: createDateObject(nextStart) }),
        focusedDate: nextStart,
      };
    }
    case Actions.prevYear: {
      const { focusedDate, ...rest } = state;
      const nextStart = getNextStartByDiff(focusedDate, -12);
      return {
        ...state,
        months: getCalendarData({ ...rest, startDate: createDateObject(nextStart) }),
        focusedDate: nextStart,
      };
    }
    case Actions.nextYear: {
      const { focusedDate, ...rest } = state;
      const nextStart = getNextStartByDiff(focusedDate, 12);
      return {
        ...state,
        months: getCalendarData({ ...rest, startDate: createDateObject(nextStart) }),
        focusedDate: nextStart,
      };
    }
    case Actions.prev: {
      const { focusedDate, ...rest } = state;
      const nextStart = getNextStartByDiff(focusedDate, -rest.monthsInView);
      return {
        ...state,
        months: getCalendarData({ ...rest, startDate: createDateObject(nextStart) }),
        focusedDate: nextStart,
      };
    }
    case Actions.next: {
      const { focusedDate, ...rest } = state;
      const nextStart = getNextStartByDiff(focusedDate, rest.monthsInView);
      return {
        ...state,
        months: getCalendarData({ ...rest, startDate: createDateObject(nextStart) }),
        focusedDate: nextStart,
      };
    }
    case Actions.dayUp:
    case Actions.dayDown:
    case Actions.dayLeft:
    case Actions.dayRight:
      return moveDay(state, action);
    case Actions.monthUp:
    case Actions.monthDown:
    case Actions.monthLeft:
    case Actions.monthRight:
      return moveMonth(state, action);
    case Actions.yearUp:
    case Actions.yearDown:
    case Actions.yearLeft:
    case Actions.yearRight: {
      return moveYear(state, action);
    }
    case Actions.selectDate:
      return {
        ...state,
        focusedDate: createFocusedDate(action.payload),
        selectedDate: action.payload,
      };
    case Actions.selectMonth: {
      const { focusedDate, ...rest } = state;
      const nextStart = createFocusedMonth(focusedDate.date, action.payload);
      return {
        ...state,
        focusedDate: nextStart,
        months: getCalendarData({ ...rest, startDate: createDateObject(nextStart) }),
        selectBy: 'day',
      };
    }
    case Actions.selectYear: {
      const { focusedDate, ...rest } = state;
      const nextStart = createFocusedYear(focusedDate.date, action.payload);
      return {
        ...state,
        focusedDate: nextStart,
        months: getCalendarData({ ...rest, startDate: createDateObject(nextStart) }),
        selectBy: 'month',
      };
    }
    case Actions.setRange:
      return {
        ...state,
        focusedDate: createFocusedDate(action.payload.focusDay),
        selectedDate: action.payload.range,
      };
    case Actions.reset: {
      const { ...rest } = state;
      const { selected, focusDay } = action.payload;
      return {
        ...state,
        focusedDate: createFocusedDate(focusDay),
        selectedDate: selected,
        months: getCalendarData({ ...rest, startDate: createDateObject(focusDay) }),
      };
    }
    case Actions.updateBoundaries:
      return {
        ...state,
        ...action.payload,
        months: getCalendarData({
          ...state,
          ...action.payload,
          startDate: createDateObject(state.focusedDate),
        }),
      };
    case Actions.selectBy:
      return {
        ...state,
        selectBy: action.payload,
      };
    default:
      return state;
  }
};
