export type Breakpoints = {
  xsmall: { max: number };
  small: { min: number; max: number };
  medium: { min: number; max: number };
  mobile: { min: number; max: number };
  large: { min: number; max: number };
  xlarge: { min: number };
};

/**
 * Breakpoints and Responsive design
 *
 * Desktop: >= large (1025px)
 * Mobile (ipad or smaller): <= mobile (1024px)
 *
 * @see https://www.figma.com/design/AaWtb8sEyW39Sgzr3bjG1o/Platform?node-id=170-18821&node-type=canvas&t=iXCtzCJJ4fBiILdg-0
 *
 * Mobile <= 1024px
 *  - Nav: show slim mode
 *
 * Medium <= 834px
 *  - TopBar: show logo icon only
 *
 * Small <= 744px
 *  - TopBar: Location picker icon only
 *
 * XSmall <= 530px
 *  - TopBar: Show user conglomerate dropdown
 *  - TopBar: Show search icon only
 *  - TopBar: Show Nav hamburger icon
 *  - TopBar: Add "Toggle action menu" icon
 *  - Nav: end slim mode / start mobile mode
 */

export const breakpoints: Breakpoints = {
  xsmall: {
    max: 530,
  },
  small: {
    min: 531,
    max: 744,
  },
  medium: {
    min: 745,
    max: 834,
  },
  mobile: {
    min: 835,
    max: 1024,
  },
  large: {
    min: 1025,
    max: 1241,
  },
  xlarge: {
    min: 1242,
  },
};

export enum NavSize {
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  mobile = 'mobile',
  large = 'large',
  xlarge = 'xlarge',
}

export type NavSizes = keyof typeof NavSize;
