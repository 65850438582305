import { FormEvent, forwardRef, type ComponentProps } from 'react';
import { css } from '@emotion/react';
import { useThemeValues } from '../../../../hooks';
import { SearchIconSmall } from '../../../../icon';
import { IconButton } from '../../../icon-button';
import type { BaseInputProps } from '../../atoms';
import { Input } from '../../atoms';
import type { BasicFormFieldProps, ExtraFieldProps, FormFieldProps } from '../../layouts';
import { FieldLayout } from '../../layouts';

type SearchFieldProps = Omit<BasicFormFieldProps<'text'>, 'label'> &
  Pick<ExtraFieldProps, 'containerCss' | 'onClear'> &
  Omit<BaseInputProps, 'type'> & {
    label?: string;
    variant?: 'regular' | 'round';
    clearable?: boolean;
    onSearchSubmit?: (event?: FormEvent<HTMLFormElement>) => void;
    searchButtonDisabled?: boolean;
    fieldComponentProps?: FormFieldProps<'text'>['fieldComponentProps'];
  };

const ConditionalFormWrapper = ({ children, includeForm, onSearchSubmit }) =>
  includeForm ? (
    <form
      role='search'
      onSubmit={(e) => {
        e.preventDefault();
        onSearchSubmit(e);
      }}
    >
      {children}
    </form>
  ) : (
    children
  );

// eslint-disable-next-line react/display-name
export const SearchField = forwardRef<HTMLDivElement, SearchFieldProps>(
  (
    {
      label,
      placeholder = 'Search',
      variant = 'regular',
      onSearchSubmit,
      clearable = true,
      searchButtonDisabled = false,
      ...rest
    },
    ref
  ) => {
    const isRound = variant === 'round';
    const isActive = rest.active || !!rest.value;
    const theme = useThemeValues();

    return (
      <ConditionalFormWrapper includeForm={!!onSearchSubmit} onSearchSubmit={onSearchSubmit}>
        <FieldLayout
          {...rest}
          ref={ref}
          clearable={clearable}
          css={[
            isRound &&
              css`
                border-radius: 20px;
                padding-left: ${theme.spacing(4)};
              `,
          ]}
          aria-label={isRound || !label ? 'Search' : undefined}
          label={isRound ? undefined : label}
          placeholder={isRound ? undefined : placeholder}
          startAdornment={
            onSearchSubmit ? undefined : (
              <SearchIconSmall
                css={[
                  isRound &&
                    css`
                      pointer-events: none;
                      position: absolute;
                      top: 50%;
                      left: 16px;
                      transition: color 200ms ease-out, left 250ms ease-out, transform 250ms ease-out;
                      transform: translate(0%, -50%);
                    `,
                  isRound &&
                    !isActive &&
                    css`
                      left: 50%;
                      transform: translate(-50%, -50%);
                    `,
                ]}
              />
            )
          }
          startAdornmentWidth={16}
          endAdornment={
            onSearchSubmit && (
              <IconButton
                label='Search'
                type='submit'
                disabled={searchButtonDisabled}
                css={css`
                  padding: ${theme.spacing(0.5)};
                  width: 24px;
                  min-width: 24px;
                  height: 24px;
                `}
              >
                <SearchIconSmall
                  css={[
                    isRound &&
                      css`
                        pointer-events: none;
                        position: absolute;
                        top: 50%;
                        left: 16px;
                        transition: color 200ms ease-out, left 250ms ease-out, transform 250ms ease-out;
                        transform: translate(0%, -50%);
                      `,
                    isRound &&
                      !isActive &&
                      css`
                        left: 50%;
                        transform: translate(-50%, -50%);
                      `,
                  ]}
                />
              </IconButton>
            )
          }
          onSearchSubmit={onSearchSubmit}
          //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
          field={Input as ComponentProps<typeof FieldLayout>['field']}
        />
      </ConditionalFormWrapper>
    );
  }
);
