import { forwardRef, ReactNode, MutableRefObject, ReactElement, Children } from 'react';
import { FloatingList } from '@floating-ui/react';
import { onlyText } from 'react-children-utilities';
import { useThemeValues } from '../../../../hooks/use-theme-values';
import { TextLink } from '../../../text-link';
import { useFormField } from '../../hooks';
import { SearchField } from '../search-field';

export const Menu = forwardRef<
  HTMLDivElement,
  {
    floatingProps: Record<string, unknown>;
    children: ReactNode;
    refs: { listItemRefs: MutableRefObject<(HTMLLIElement | null)[]>; labelsRef: MutableRefObject<(string | null)[]> };
    clearSelection: () => void;
    selectAll: (filter: (child: ReactElement) => boolean) => void;
    clearSelectionLabel?: string;
    selectAllLabel?: string;
    trackingIdBase?: string;
  }
>(
  (
    {
      floatingProps,
      children,
      refs,
      clearSelection,
      selectAll,
      clearSelectionLabel = 'Clear',
      selectAllLabel = 'Select All',
      trackingIdBase,
    },
    ref
  ) => {
    const theme = useThemeValues();
    const field = useFormField({ type: 'text' });

    const filtered = Children.toArray(children).filter((child) =>
      onlyText(child).toLowerCase().includes(field.value.toLowerCase())
    );

    return (
      <div
        style={{
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        }}
        ref={ref}
        {...floatingProps}
      >
        <div style={{ padding: theme.spacing(2, 2, 0) }}>
          <SearchField
            {...field}
            onKeyDown={(e) => {
              if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter' || e.key === 'Tab') {
                return;
              }
              e.stopPropagation();
            }}
            name='search'
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: theme.spacing(0, 2) }}>
          <TextLink
            weight='bold'
            onKeyDown={(e) => {
              if (e.key === ' ') {
                e.preventDefault();
              }
            }}
            onClick={() => {
              selectAll(
                (child) => field.value === '' || onlyText(child).toLowerCase().includes(field.value.toLowerCase())
              );
            }}
            trackingId={`${trackingIdBase}-select-all`}
          >
            {selectAllLabel}
          </TextLink>
          <TextLink
            weight='bold'
            onKeyDown={(e) => {
              if (e.key === ' ') {
                e.preventDefault();
              }
            }}
            onClick={() => {
              clearSelection();
            }}
            trackingId={`${trackingIdBase}-clear-all`}
          >
            {clearSelectionLabel}
          </TextLink>
        </div>
        <div
          style={{
            overflow: 'auto',
          }}
        >
          <FloatingList elementsRef={refs.listItemRefs} labelsRef={refs.labelsRef}>
            {filtered}
          </FloatingList>
        </div>
      </div>
    );
  }
);

Menu.displayName = 'Menu';
